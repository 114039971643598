import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { FaPen } from "react-icons/fa";

function EditTermConditionsModal({ UpdateFunction, data, id }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setState((prevState) => ({ ...prevState, description: content }));
  };

  async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      UpdateFunction(state,id);
      handleClose();
    } else setValid(true);
  }

  useEffect(() => {
    setState(data);
  }, []);

  return (
    <>
      <Button variant="primary"  className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"  onClick={handleShow}>
        <FaPen /> Edit Record
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2">
            {state && (
              <>
                <div className="mb-3">
                  <label>Title</label>
                  <input
                  value={state.title}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Title</label>
                  <CKEditor initData={state.description} required onChange={handleEditorChange} />
                  <Form.Control.Feedback type="invalid">
                    Please enter the details.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Type</label>
                  <select
                  value={state.type}
                    className="form-control"
                    required
                    name="type"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        type: e.target.value,
                      }))
                    }
                  >
                    <option value=""></option>
                    <option value="general">General Terms</option>
                    <option value="tutor">Tutor Terms</option>
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Please select a type.
                  </Form.Control.Feedback>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditTermConditionsModal;
