import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import { CKEditor } from "ckeditor4-react";
import { FaPen } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import axios from "axios";
function EditTeacherModal({UpdateFunction, data, id}) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleImage = (e) => {
    const file = e.target.files[0];
    var output = document.getElementById('img');
    output.innerHTML="";
    output.innerHTML=`<img src=${URL.createObjectURL(file)} width='120px' height='120px' />`
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    setState((prevState)=>({ ...prevState, image: file }));
  };
  const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setState((prevState) => ({ ...prevState, bio: content }));
  };
async function changephoto()
{
    const formData = new FormData();
    formData.append('image', state.image);
    axios
      .post(`${process.env.REACT_APP_MEDIAURL}/api/single/file/upload`, formData)
      .then((response) => {
        let data=response.data.filepath.filepath;
        // setState((prevState)=>({...prevState,image:data}));
        UpdateFunction({...state,image:data},id);
    })
    .catch((error) => {
        console.error(error);
    });
}
async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
        if (state.image) changephoto();
          else UpdateFunction(state,id);
      handleClose();
    } else setValid(true);
  }

  useEffect(() => {
    if(show) setState(data);
  }, [data,id,show]);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="m-1 w-100 flex items-center">
        {" "}
        <FaPen className="m-1" />
        Edit Record
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2">
            {state && (
              <>
              <Row>
                <Col>
                <div className="mb-3">
                  <label>Full Name</label>
                  <input
                    value={state.full_name}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        full_name: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
                </div>
                </Col>
                <Col>
                <div className="mb-3">
                  <label>Nickname</label>
                  <input
                    value={state.nickname}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        nickname: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
                </div>
                </Col>
              </Row>
              <Row className="align-items-center">
              <Col md={4}>
            <label className="text-white">Profile Picture</label>
            <div className="image-chooser">
            <center>
              <div id="img">
              {state.profile_image===null?
              <>
               <BsFillPersonFill className="icon icon-chooser" />
              <br />
              <span className="text-white">Drag & drop image</span>
              <br />
              <span className="text-white">Or</span>
              </>
               :
              <>
               <img src={process.env.REACT_APP_MEDIAURL+""+state.profile_image} className="mt-1" width={"120px"} height={"120px"}  />
              </>
              }
                </div>
              <br />
              <label for="customFile" class="custom-file-button bg-primary">
                Upload Image
                <input type="file" id="customFile" onChange={handleImage} />
              </label>
            </center>
          </div>
            </Col>
              <Col>
                <div className="mb-3">
                  <label>Email</label>
                  <input
                    value={state.email}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </div>
                </Col>
              </Row>
                <div className="mb-3">
                  <label>Bio</label>
                  <CKEditor
                    initData={state.bio}
                    required
                    onChange={handleEditorChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the Bio.
                  </Form.Control.Feedback>
                </div>
                <Row>
                    <Col>
                    <div className="mb-3">
                  <label>Phone</label>
                  <input
                    value={state.phone}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number.
                  </Form.Control.Feedback>
                </div>
                    </Col>
                    <Col>
                    <div className="mb-3">
                  <label>Whatsapp Phone</label>
                  <input
                    value={state.whatsapp_phone}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        whatsapp_phone: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number.
                  </Form.Control.Feedback>
                </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="mb-3">
                  <label>Country</label>
                  <input
                    value={state.country}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        country: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a country.
                  </Form.Control.Feedback>
                </div>
                    </Col>
                    <Col>
                    <div className="mb-3">
                  <label>Address</label>
                  <input
                    value={state.address}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        address: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number.
                  </Form.Control.Feedback>
                </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="mb-3">
                  <label>Password</label>
                  <input
                    autoComplete="new-password"
                    type="password"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                </div>
                    </Col>
                    <Col>
                    <div className="mb-3">
                  <label>Confirm Password</label>
                  <input
                    autoComplete="new-password"
                    type="password"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        confirm_password: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                </div>
                    </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditTeacherModal;
