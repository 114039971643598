import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

function PriceSet({
  title,
  name,
  initialValue,
  onChange,
  withButtons = true,
}) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(initialValue);
  }, []);

  const increase = () => {
    if(value)
    {
      setValue(parseInt(value) + 1);
      onChange(parseInt(value) + 1); // Pass the updated value to the onChange callback
    }
    else {
      setValue(1);
      onChange(1);
    }
  };

  const decrease = () => {
    if(value)
    {
    setValue(parseInt(value) - 1);
    onChange(parseInt(value) - 1); // Pass the updated value to the onChange callback
    }
    else {
      setValue(0);
      onChange(0);
    }
  };

  return (
    <>
      {withButtons ? (
        <div className="m-3">
          <label className="text-white mb-1">{title} ($)</label>
          <div className="flex justify-center items-center">
            <Button
              variant="default"
              onClick={decrease}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <AiOutlineMinusCircle
                className="text-primary"
                style={{ fontSize: "30px" }}
              />
            </Button>
            <input
              type="number"
              min={1}
              onChange={(e) => {
                setValue(parseInt(e.target.value));
                onChange(e.target.value);
              }}
              className="form-control w-16 text-center border-none"
              name={name}
              value={`${value}`}
            />
            <Button
              variant="default"
              onClick={increase}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <AiOutlinePlusCircle
                className="text-primary"
                style={{ fontSize: "30px" }}
              />
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-white text-center font-bold">{title} ($)</div>
          <div className="flex justify-center items-end mt-[0.7rem]">
            <input
              type="number"
              min={1}
              onChange={(e) => {
                setValue(parseInt(e.target.value));
                onChange(e.target.value);
              }}
              className="form-control w-20  text-center border-none"
              name={name}
              value={`${value}`}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PriceSet;
