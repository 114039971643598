import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

function DurationSet({ title, name, initialValue = 0, onChange }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, []);

  const handleInput = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const increase = () => {
    setValue(parseInt(value) + 1);
    onChange(value + 1); // Pass the updated value to the onChange callback
  };

  const decrease = () => {
    setValue(value - 1);
    onChange(value - 1); // Pass the updated value to the onChange callback
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <span className="text-white text-center font-bold m-1">{title}</span>
      </div>
      <div className="flex justify-center items-center w-full gap-5">
        <Button
          variant="default"
          onClick={decrease}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <AiOutlineMinusCircle
            className="text-primary"
            style={{ fontSize: "30px" }}
          />
        </Button>
        <input
          align="center"
          type="text"
          className="form-control text-center w-16 bg-black  border-none mt-1"
          name={name}
          value={`${value}`}
          onChange={handleInput}
        />
        <Button
          variant="default"
          onClick={increase}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <AiOutlinePlusCircle
            className="text-primary"
            style={{ fontSize: "30px" }}
          />
        </Button>
      </div>
    </div>
  );
}

export default DurationSet;
