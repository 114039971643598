import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsFillTrashFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { MdDangerous } from "react-icons/md";

function EditSubjectModal({UpdateFunction,id,data}) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function updateFunction(e)
  {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
    UpdateFunction(state,id);
      handleClose();
  }
  else {
    setValid(true);
  }
}

const handleInput=(e)=>{
    setState({
        ...state,
        [e.target.name]:e.target.value
    })
}

useEffect(()=>{
    setState(data);
},[]);

  return (
    <>
      <Button variant="primary" className="btn btn-primary flex justify-center items-center p-2 my-1 w-100" onClick={handleShow}>
        <FaPen className="m-1" />
        Edit Record
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg" >
        <Modal.Header className="bg-secondary2">
            <h3 className="text-white">Edit Subject</h3>
        </Modal.Header>
            <Form noValidate onSubmit={updateFunction} validated={valid}>
        <Modal.Body className="bg-secondary2">
      {state&&(
        <div className="mb-3">
            <input
              required
              type="text"
              name="label"
              onChange={handleInput}
              className="form-control"
              placeholder="Label"
              value={state.label}
            />
            <Form.Control.Feedback type="invalid">
              Please enter the label.
            </Form.Control.Feedback>
          </div>
      )}
        </Modal.Body>
        <Modal.Footer className="bg-secondary2">
        <Button type="button" variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button type="submit">Update</Button>
        </Modal.Footer>
        </Form>
        </Modal>
    </>
  );
}

export default EditSubjectModal;
