import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Button, Dropdown, Container } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import { SlOptionsVertical } from "react-icons/sl";
import { FaPen, FaStar } from "react-icons/fa";
import { BsFillTrashFill, BsCurrencyDollar, BsCalendar4 } from "react-icons/bs";
import Slider from "./Slider";
import DeleteRequestModal from "../modals/DeleteRequestModal";
import Axios from "../Utils/Axios";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import PriceSet from "./PriceSet";
import { AiFillStar } from "react-icons/ai";
import { UserContext } from "../providers/User";
import CountDown from "./CountDown";
import { useSelector } from "react-redux";
import { BiUserCircle } from "react-icons/bi";
function CardRequest(props) {
  let location = useLocation();
  const user = useSelector((state) => state.user).user;
  // const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [priceStudentValue, setStudentPriceValue] = useState(0);
  const [priceTutorValue, setTutorPriceValue] = useState(0);

  async function setPrice() {
    await props.setPrice(
      { student_price: priceStudentValue, tutor_price: priceTutorValue },
      props.id
    );
    handleClose();
  }
  async function DeleteFunction(id) {
    await props.deleteFunction(id);
  }
  const handleStudentPriceChange = (newPrice) => {
    setStudentPriceValue(newPrice);
  };
  const handleTutorPriceChange = (newPrice) => {
    setTutorPriceValue(newPrice);
  };
  useEffect(() => {
    props.student_price && setStudentPriceValue(props.student_price);
    props.tutor_price && setTutorPriceValue(props.tutor_price);
  }, []);
  return (
    <Col md={6}>
      {(props.table === "requests" || props.table === "online") && (
        <>
          {props.type === "new" && (
            <Card className="bg-secondary border-primary rounded-xl">
              <Card.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
                <div className="m-1 md:flex md:justify-between">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <span className="text-secondary">Tutor Not Assigned</span>
                    {((JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {(props.deleted_at===null&&props.cancelled===0)&&JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">
                    Description & Course Material s ({props.materials && props.materials.length}
                    )
                  </h6>
                  <Slider
                    items={props.materials}
                    names={props.materials_name}
                  />
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Assistance Type</span>
                          )}
                        </div>
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-[#CACAFF]">
                              {props.exam_type}
                            </span>
                          ) : (
                            [
                              props.tbl === "homework" && (
                                <span className="text-[#CACAFF]">HomeWork</span>
                              ),
                              props.tbl === "project" && (
                                <span className="text-[#CACAFF]">
                                  Project Help
                                </span>
                              ),
                              props.tbl === "thesis" && (
                                <span className="text-[#CACAFF]">
                                  Thesis Writing
                                </span>
                              ),
                              props.tbl === "online" && (
                                <span className="text-[#CACAFF]">
                                  Online Tutoring
                                </span>
                              ),
                            ]
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Country</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between p-3 w-full">
                        <div>
                        <span className="text-white">
                            Language
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between p-3 w-full">
                        <div>
                          <span className="text-white">State</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.state}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(props.tbl === "exam" || props.tbl === "online") && (
                      <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                        <span className="text-white">
                            Start Time
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.start_time}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Duration</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.duration}
                          </span>
                        </div>
                      </div>
                    </div>
                    )}
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="">
                  <div className="md:flex justify-between">
                      <div className="flex">
                        <div>
                      <span className="text-white flex gap-3 my-1">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <span className="text-white my-2">ID: {props.id}</span>
                        </div>
                      </div>

                     {(props.deleted_at===null&&props.cancelled===0)&&<Button
                        variant="primary"
                        className="flex justify-center items-center w-40 max-sm:my-1"
                        // size="lg"
                        onClick={handleShow}
                      >
                        <BsCurrencyDollar /> Set Price
                      </Button>}
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="text-white"
                        centered
                      >
                        <Modal.Body className="bg-secondary2" align="center">
                          <Row>
                            <Col md={6}>
                              <PriceSet
                                name={"student_price"}
                                title={"Student Price"}
                                onChange={handleStudentPriceChange}
                              />
                            </Col>
                            <Col md={6}>
                              <PriceSet
                                name={"tutor_price"}
                                title={"Tutor Price"}
                                onChange={handleTutorPriceChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col align="center">
                              <Button variant="primary" onClick={setPrice}>
                                Done
                              </Button>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          )}
          {props.type === "pending" && (
            <Card className="bg-secondary border-primary rounded-xl">
              <Card.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
                <div>
                <div className="m-1 md:flex md:justify-between">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <span className="text-secondary">Tutor Not Assigned</span>
                    {(JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">
                    Description & Course Material s ({props.materials && props.materials.length}
                    )
                  </h6>
                  <Slider
                    items={props.materials}
                    names={props.materials_name}
                  />
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Assistance Type</span>
                          )}
                        </div>
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-[#CACAFF]">
                              {props.exam_type}
                            </span>
                          ) : (
                            [
                              props.tbl === "homework" && (
                                <span className="text-[#CACAFF]">HomeWork</span>
                              ),
                              props.tbl === "project" && (
                                <span className="text-[#CACAFF]">
                                  Project Help
                                </span>
                              ),
                              props.tbl === "thesis" && (
                                <span className="text-[#CACAFF]">
                                  Thesis Writing
                                </span>
                              ),
                              props.tbl === "online" && (
                                <span className="text-[#CACAFF]">
                                  Online Tutoring
                                </span>
                              ),
                            ]
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Country</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between p-3 w-full">
                        <div>
                        <span className="text-white">
                            Language
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between p-3 w-full">
                        <div>
                          <span className="text-white">State</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.state}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(props.tbl === "exam" || props.tbl === "online") && (
                      <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                        <span className="text-white">
                            Start Time
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.start_time}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Duration</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.duration}
                          </span>
                        </div>
                      </div>
                    </div>
                    )}
                </div>
              </Card.Body>
              <Card.Footer>
              <div className="">
                  <div className="md:flex justify-between">
                      <div className="flex">
                        <div>
                      <span className="text-white flex gap-3 my-1">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <span className="text-white my-2">ID: {props.id}</span>
                        </div>
                      </div>

                      <Button
                        variant="primary"
                        className="flex justify-center items-center w-44 max-sm:my-1"
                        // size="lg"
                        onClick={handleShow}
                      >
                        <BsCurrencyDollar /> Change Price
                      </Button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="text-white"
                        centered
                      >
                        <Modal.Body className="bg-secondary2" align="center">
                          <Row>
                            <Col md={6}>
                              <PriceSet
                                name={"student_price"}
                                title={"Student Price"}
                                initialValue={props.student_price}
                                onChange={handleStudentPriceChange}
                              />
                            </Col>
                            <Col md={6}>
                              <PriceSet
                                name={"tutor_price"}
                                title={"Tutor Price"}
                                initialValue={props.tutor_price}
                                onChange={handleTutorPriceChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col align="center">
                              <Button variant="primary" onClick={setPrice}>
                                Done
                              </Button>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                  </div>
                </div>
                {/* <Container className="">
                  <Row>
                    <Col md={8}>
                      <span className="text-white flex gap-3">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <br />
                      <span className="text-white">ID: {props.id}</span>
                    </Col>
                    <Col md={4}>
                      <Button
                        variant="primary"
                        className="flex justify-center items-center w-100"
                        size="lg"
                        onClick={handleShow}
                      >
                        <BsCurrencyDollar /> Change Price
                      </Button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="text-white"
                        centered
                      >
                        <Modal.Body className="bg-secondary2" align="center">
                          <Row>
                            <Col md={6}>
                              <PriceSet
                                name={"student_price"}
                                title={"Student Price"}
                                initialValue={props.student_price}
                                onChange={handleStudentPriceChange}
                              />
                            </Col>
                            <Col md={6}>
                              <PriceSet
                                name={"tutor_price"}
                                title={"Tutor Price"}
                                initialValue={props.tutor_price}
                                onChange={handleTutorPriceChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col align="center">
                              <Button variant="primary" onClick={setPrice}>
                                Done
                              </Button>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    </Col>
                  </Row>
                </Container> */}
              </Card.Footer>
            </Card>
          )}
          {(props.type === "waiting" || props.type === "ongoing") && (
            <Card className="bg-secondary border-primary rounded-xl">
              <Card.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
                <div>
                   <div className="m-1 md:flex md:justify-between items-center">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <div>
                    <div className="flex justify-start items-center  text-start">
                   {
                    props.tutor && props.tutor.profile_image!=""?
                    <img  src={`${process.env.REACT_APP_MEDIAURL}/${
                             props.tutor && props.tutor.profile_image
                           }`} alt="" className="w-8 h-8 rounded-full" />
                           :
                           <BiUserCircle size={30} />
                   }
                    <span className="text-white mx-1">
                          {props.tutor && props.tutor.full_name}
                        </span>
                    </div>
                    <div align="center" className="text-primary text-start">
                        {props.tutor && props.tutor.email}
                    </div>
                    </div>
                    {(JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                    </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">
                    Description & Course Material s ({props.materials && props.materials.length}
                    )
                  </h6>
                  <Slider
                    items={props.materials}
                    names={props.materials_name}
                  />
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Assistance Type</span>
                          )}
                        </div>
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-[#CACAFF]">
                              {props.exam_type}
                            </span>
                          ) : (
                            [
                              props.tbl === "homework" && (
                                <span className="text-[#CACAFF]">HomeWork</span>
                              ),
                              props.tbl === "project" && (
                                <span className="text-[#CACAFF]">
                                  Project Help
                                </span>
                              ),
                              props.tbl === "thesis" && (
                                <span className="text-[#CACAFF]">
                                  Thesis Writing
                                </span>
                              ),
                              props.tbl === "online" && (
                                <span className="text-[#CACAFF]">
                                  Online Tutoring
                                </span>
                              ),
                            ]
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Country</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between p-3 w-full">
                        <div>
                        <span className="text-white">
                            Language
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between p-3 w-full">
                        <div>
                          <span className="text-white">State</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.state}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(props.tbl === "exam" || props.tbl === "online") && (
                      <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                        <span className="text-white">
                            Start Time
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.start_time}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Duration</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.duration}
                          </span>
                        </div>
                      </div>
                    </div>
                    )}
                </div>
                </div>
              </Card.Body>
              <Card.Footer>
              <div className="">
                  <div className="md:flex justify-between">
                      <div className="flex">
                        <div>
                      <span className="text-white flex gap-3 my-1">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <span className="text-white my-2">ID: {props.id}</span>
                        </div>
                      </div>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          )}
          {props.type === "completed" && (
            <Card className="bg-secondary border-primary rounded-xl">
              <Card.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
                <div className="m-1 md:flex md:justify-between items-center">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <div>
                    <div className="flex justify-start items-center  text-start">
                   {
                    props.tutor && props.tutor.profile_image!=""?
                    <img  src={`${process.env.REACT_APP_MEDIAURL}/${
                             props.tutor && props.tutor.profile_image
                           }`} alt="" className="w-8 h-8 rounded-full" />
                           :
                           <BiUserCircle size={30} />
                   }
                    <span className="text-white mx-1">
                          {props.tutor && props.tutor.full_name}
                        </span>
                    </div>
                    <div align="center" className="text-primary text-start">
                        {props.tutor && props.tutor.email}
                    </div>
                    </div>
                    {(JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <div className="flex m-2 items-center gap-3">
                <span>Rating:</span>
                <div className="flex gap-1">

                    {Array.from({ length: props.ratings }, (_, index) => (
                        <FaStar className="text-yellow-400" />
                        ))}
                        </div>
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="">
                  <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">
                    Description & Course Material s ({props.materials && props.materials.length}
                    )
                  </h6>
                  <Slider
                    items={props.materials}
                    names={props.materials_name}
                  />
                </div>
                {/* <Container className="">
                  <h6 className="text-white font-weight-bold">Details: </h6>
                  <div align="center">
                    <Row>
                      <Col
                        style={{ backgroundColor: "#433c5f" }}
                        className="p-2 mr-1"
                      >
                        <Row>
                          <Col md={6}>
                            {props.tbl === "exam" ? (
                              <span className="text-white">Exam Type</span>
                            ) : (
                              <span className="text-white">
                                Assistance Type
                              </span>
                            )}
                          </Col>
                          <Col md={6}>
                            {props.tbl === "exam" ? (
                              <span className="text-white">
                                {props.exam_type}
                              </span>
                            ) : (
                              [
                                props.tbl === "homework" && (
                                  <span className="text-white">HomeWork</span>
                                ),
                                props.tbl === "project" && (
                                  <span className="text-white">
                                    Project Help
                                  </span>
                                ),
                                props.tbl === "thesis" && (
                                  <span className="text-white">
                                    Thesis Writing
                                  </span>
                                ),
                                props.tbl === "online" && (
                                  <span className="text-white">
                                    Online Tutoring
                                  </span>
                                ),
                              ]
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        style={{ backgroundColor: "#433c5f" }}
                        className="p-2 ml-1"
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Country</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.country}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="p-2 mr-1"
                        style={{ backgroundColor: "#38384a" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Language</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.language}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="p-2 ml-1"
                        style={{ backgroundColor: "#38384a" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">State</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.state}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {(props.tbl === "exam" || props.tbl === "online") && (
                      <Row>
                        <Col
                          className="p-2 mr-1"
                          style={{ backgroundColor: "#433c5f" }}
                        >
                          <Row>
                            <Col md={6}>
                              <span className="text-white">Start Time</span>
                            </Col>
                            <Col md={6}>
                              <span className="text-white">
                                {props.start_time}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className="p-2 ml-1"
                          style={{ backgroundColor: "#433c5f" }}
                        >
                          <Row>
                            <Col md={6}>
                              <span className="text-white">Duration</span>
                            </Col>
                            <Col md={6}>
                              <span className="text-white">
                                {props.duration}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Container> */}
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Assistance Type</span>
                          )}
                        </div>
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-[#CACAFF]">
                              {props.exam_type}
                            </span>
                          ) : (
                            [
                              props.tbl === "homework" && (
                                <span className="text-[#CACAFF]">HomeWork</span>
                              ),
                              props.tbl === "project" && (
                                <span className="text-[#CACAFF]">
                                  Project Help
                                </span>
                              ),
                              props.tbl === "thesis" && (
                                <span className="text-[#CACAFF]">
                                  Thesis Writing
                                </span>
                              ),
                              props.tbl === "online" && (
                                <span className="text-[#CACAFF]">
                                  Online Tutoring
                                </span>
                              ),
                            ]
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Country</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between p-3 w-full">
                        <div>
                        <span className="text-white">
                            Language
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between p-3 w-full">
                        <div>
                          <span className="text-white">State</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.state}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(props.tbl === "exam" || props.tbl === "online") && (
                      <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                        <span className="text-white">
                            Start Time
                          </span>
                        </div>
                        <div>
                        <span className="text-[#CACAFF]">
                            {props.start_time}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Duration</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.duration}
                          </span>
                        </div>
                      </div>
                    </div>
                    )}
                </div>
              </Card.Body>
              <Card.Footer>
              <div className="">
                  <div className="md:flex justify-between">
                      <div className="flex">
                        <div>
                      <span className="text-white flex gap-3 my-1">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <span className="text-white my-2">ID: {props.id}</span>
                        </div>
                      </div>
                  </div>
                </div>
              </Card.Footer>
            </Card>
          )}
        </>
      )}

      {props.table === "sos" && (
        <>
          {props.type === "new" && (
            <Card className="bg-secondary rounded-xl">
              <Card.Body style={{ maxHeight: "500px", overflow: "scroll" }}>
                <div className="m-1 md:flex md:justify-between">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <span className="text-secondary">Tutor Not Assigned</span>
                    {((JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {(props.deleted_at===null&&props.cancelled===0)&&JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                  type: props.table,
                                  assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ))}
                  </div>
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                <div className="m-1">
                {props.description && (
                    <>
                     <h6 className="text-white font-medium">Question</h6>
                     <p className="text-[#CACAFF]">{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Duration</span>
                          )}
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                          {props.timer} min
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Language</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="m-1 pt-2 py-1">
                <span className="my-2 text-[#BCBCC0]">ID: {props.id}</span>
                </div>

                {/* <Container className="">
                  <h6 className="text-white font-weight-bold">Details: </h6>
                  <div align="center">
                    <Row>
                      <Col
                        className="p-2 mr-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Duration</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.timer}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="p-2 ml-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Language</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.language}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container> */}
              </Card.Body>
            </Card>
          )}
          {props.type === "pending" && (
            <Card className="bg-secondary border-primary">
              <Card.Body style={{ maxHeight: "500px", overflow: "scroll" }}>
                <Container className="d-flex justify-content-between p-1">
                  <Row>
                    <Col md={12}>
                      <Container className="">
                        <h6 className="text-white">{props.name}</h6>
                        <h6 className="text-primary">{props.email}</h6>
                      </Container>
                    </Col>
                  </Row>
                  <Row align="right" className="align-items-center">
                    <Col md={10}>
                      <span className="text-secondary">Tutor Not Assigned</span>
                    </Col>
                    <Col md={2}>
                      {(JSON.parse(user.permissions).includes(
                        "/request/edit"
                      ) ||
                        JSON.parse(user.permissions).includes(
                          "/request/delete"
                        )) && (
                        <Dropdown drop="start" className="bg-secondary">
                          <Dropdown.Toggle
                            variant="secondary"
                            className="dropdown-actions-button"
                          >
                            <SlOptionsVertical size={"30px"} className="icon" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-secondary2 p-1">
                            {JSON.parse(user.permissions).includes(
                              "/request/edit"
                            ) && (
                              <Link
                                to={"/request/edit"}
                                state={{
                                  id: props.id,
                                  type: props.table,
                                  assistance_type: props.tbl,
                                }}
                                className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                              >
                                <FaPen className="m-1" />
                                Edit Record
                              </Link>
                            )}

                            <br />
                            {JSON.parse(user.permissions).includes(
                              "/request/delete"
                            ) && (
                              <DeleteRequestModal
                                DeleteFunction={() => {
                                  DeleteFunction(props.id);
                                }}
                                id={props.id}
                              />
                            )}
                            <br />
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                </Container>
                <Container className="">
                  <Row className="justify-content-between">
                    <Col md={6}>
                      <h6 className="text-white">
                        <b>Course Title:</b> {props.course_title}{" "}
                      </h6>
                      <h6>
                        <b>Subject:</b> {props.subject}
                      </h6>
                    </Col>
                    {JSON.parse(user.permissions).includes("/prices") && (
                      <Col md={6} align="right">
                        <h6>
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-weight-bold">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                        <h5></h5>
                      </Col>
                    )}
                  </Row>
                </Container>
                <Container className="">
                  {props.description && (
                    <>
                      <h6 className="text-white">Question</h6>
                      <p>{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </Container>
                <Container className="">
                  <h6 className="text-white font-weight-bold">Details: </h6>
                  <div align="center">
                    <Row>
                      <Col
                        className="p-2 mr-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Duration</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.timer}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="p-2 ml-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Language</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.language}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Card.Body>
              <Card.Footer>
                <Container className="">
                  <Row>
                    <Col md={8}>
                      <span className="text-white flex gap-3">
                        {props.tbl === "exam" ? (
                          <>
                            <BsCalendar4 /> Date of exam : {props.date_finish}
                          </>
                        ) : (
                          <>
                            <BsCalendar4 /> Deadline : {props.date_finish}
                          </>
                        )}
                      </span>
                      <br />
                      <span className="text-white">ID: {props.id}</span>
                    </Col>
                    <Col md={4}>
                      <Button
                        variant="primary"
                        className="flex justify-center items-center w-100"
                        size="lg"
                        onClick={handleShow}
                      >
                        <BsCurrencyDollar /> Change Price
                      </Button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="text-white"
                        centered
                      >
                        <Modal.Body className="bg-secondary2" align="center">
                          <Row>
                            <Col md={6}>
                              <PriceSet
                                name={"student_price"}
                                title={"Student Price"}
                                initialValue={props.student_price}
                                onChange={handleStudentPriceChange}
                              />
                            </Col>
                            <Col md={6}>
                              <PriceSet
                                name={"tutor_price"}
                                title={"Tutor Price"}
                                initialValue={props.tutor_price}
                                onChange={handleTutorPriceChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col align="center">
                              <Button variant="primary" onClick={setPrice}>
                                Done
                              </Button>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </Card>
          )}
          {(props.type === "waiting" || props.type === "ongoing") && (
            <Card className="bg-secondary rounded-xl">
              <Card.Body style={{ maxHeight: "500px", overflow: "scroll" }}>
                {/* <Container className="d-flex justify-content-between p-1">
                  <Row>
                    <Col md={12}>
                      <Container className="p-1 mt-3">
                        <span className="text-white">{props.name}</span>
                        <br />
                        <span className="text-primary">{props.email}</span>
                      </Container>
                    </Col>
                  </Row>
                  <Row align="right" className="align-items-center">
                    <Col md={10} align="right">
                      <Row align="right" className="align-items-center">
                        <Col>
                          <Avatar
                            src={`${process.env.REACT_APP_MEDIAURL}/${
                              props.tutor && props.tutor.profile_image
                            }`}
                          />
                        </Col>
                        <Col align="left">
                          <span className="text-white">
                            {props.tutor && props.tutor.full_name}
                          </span>
                        </Col>
                      </Row>
                      <Row className="align-items-between">
                        <Col></Col>
                        <Col align="center">
                          <span className="text-primary">
                            {props.tutor && props.tutor.email}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      {(JSON.parse(user.permissions).includes(
                        "/request/edit"
                      ) ||
                        JSON.parse(user.permissions).includes(
                          "/request/delete"
                        )) && (
                        <Dropdown drop="start" className="bg-secondary">
                          <Dropdown.Toggle
                            variant="secondary"
                            className="dropdown-actions-button"
                          >
                            <SlOptionsVertical size={"30px"} className="icon" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-secondary2 p-1">
                            {JSON.parse(user.permissions).includes(
                              "/request/edit"
                            ) && (
                              <Link
                                to={"/request/edit"}
                                state={{
                                  id: props.id,
                                  type: props.table,
                                  assistance_type: props.tbl,
                                }}
                                className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                              >
                                <FaPen className="m-1" />
                                Edit Record
                              </Link>
                            )}

                            <br />
                            {JSON.parse(user.permissions).includes(
                              "/request/delete"
                            ) && (
                              <DeleteRequestModal
                                DeleteFunction={() => {
                                  DeleteFunction(props.id);
                                }}
                                id={props.id}
                              />
                            )}
                            <br />
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                </Container> */}

                {/* <Container className="">
                  <Row className="justify-content-between">
                    <Col md={6}>
                      <h6 className="text-white">
                        <b>Course Title:</b> {props.course_title}{" "}
                      </h6>
                      <h6>
                        <b>Subject:</b> {props.subject}
                      </h6>
                    </Col>
                    {JSON.parse(user.permissions).includes("/prices") && (
                      <Col md={6} align="right">
                        <h6 className="font-weight-bold">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-weight-bold">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                        <h5></h5>
                      </Col>
                    )}
                  </Row>
                </Container> */}
                <div className="m-1 md:flex md:justify-between items-center">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <div>
                    <div className="flex justify-start items-center  text-start">
                   {
                    props.tutor && props.tutor.profile_image!=""?
                    <img  src={`${process.env.REACT_APP_MEDIAURL}/${
                             props.tutor && props.tutor.profile_image
                           }`} alt="" className="w-8 h-8 rounded-full" />
                           :
                           <BiUserCircle size={30} />
                   }
                    <span className="text-white mx-1">
                          {props.tutor && props.tutor.full_name}
                        </span>
                    </div>
                    <div align="center" className="text-primary text-start">
                        {props.tutor && props.tutor.email}
                    </div>
                    </div>
                    {(JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                    </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                <div className="m-1">
                {props.description && (
                    <>
                     <h6 className="text-white font-medium">Question</h6>
                     <p className="text-[#CACAFF]">{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Duration</span>
                          )}
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                          {props.timer} min
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Language</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="m-1 pt-2 py-1">
                <span className="my-2 text-[#BCBCC0]">ID: {props.id}</span>
                </div>
                {/* <Container className="">
                  {props.description && (
                    <>
                      <h6 className="text-white">Question</h6>
                      <p>{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </Container>
                <Container className="">
                  <h6 className="text-white font-weight-bold">Details: </h6>
                  <div align="center">
                    <Row>
                      <Col
                        className="p-2 mr-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Duration</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.timer}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="p-2 ml-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Language</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.language}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container> */}
              </Card.Body>
            </Card>
          )}
          {props.type === "completed" && (
            <Card className="bg-secondary rounded-xl">
              <Card.Body style={{ maxHeight: "500px", overflow: "scroll" }}>
                {/* <Container className="d-flex justify-content-between p-1">
                  <Row>
                    <Col md={12}>
                      <Container className="p-1 mt-3">
                        <span className="text-white">{props.name}</span>
                        <br />
                        <span className="text-primary">{props.email}</span>
                        <br />
                        <h5 class="text-white">
                          Ratings: {props.ratings}{" "}
                          <AiFillStar style={{ color: "yellow" }} />
                        </h5>
                      </Container>
                    </Col>
                  </Row>
                  <Row align="right" className="align-items-center">
                    <Col md={10} align="right">
                      <Row align="right" className="align-items-center">
                        <Col>
                          <Avatar
                            src={`${process.env.REACT_APP_MEDIAURL}/${
                              props.tutor && props.tutor.profile_image
                            }`}
                          />
                        </Col>
                        <Col align="left">
                          <span className="text-white">
                            {props.tutor && props.tutor.full_name}
                          </span>
                        </Col>
                      </Row>
                      <Row className="align-items-between">
                        <Col align="right">
                          <span className="text-primary">
                            {props.tutor && props.tutor.email}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      {(JSON.parse(user.permissions).includes(
                        "/request/edit"
                      ) ||
                        JSON.parse(user.permissions).includes(
                          "/request/delete"
                        )) && (
                        <Dropdown drop="start" className="bg-secondary">
                          <Dropdown.Toggle
                            variant="secondary"
                            className="dropdown-actions-button"
                          >
                            <SlOptionsVertical size={"30px"} className="icon" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-secondary2 p-1">
                            {JSON.parse(user.permissions).includes(
                              "/request/edit"
                            ) && (
                              <Link
                                to={"/request/edit"}
                                state={{
                                  id: props.id,
                                  type: props.table,
                                  assistance_type: props.tbl,
                                }}
                                className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                              >
                                <FaPen className="m-1" />
                                Edit Record
                              </Link>
                            )}

                            <br />
                            {JSON.parse(user.permissions).includes(
                              "/request/delete"
                            ) && (
                              <DeleteRequestModal
                                DeleteFunction={() => {
                                  DeleteFunction(props.id);
                                }}
                                id={props.id}
                              />
                            )}
                            <br />
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                </Container>
                <Container className="">
                  <Row className="justify-content-between">
                    <Col md={6}>
                      <h6 className="text-white">
                        <b>Course Title:</b> {props.course_title}{" "}
                      </h6>
                      <h6>
                        <b>Subject:</b> {props.subject}
                      </h6>
                    </Col>
                    {JSON.parse(user.permissions).includes("/prices") && (
                      <Col md={6} align="right">
                        <h6 className="font-weight-bold">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-weight-bold">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                        <h5></h5>
                      </Col>
                    )}
                  </Row>
                </Container>
                <Container className="">
                  {props.description && (
                    <>
                      <h6 className="text-white">Question</h6>
                      <p>{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </Container>
                <Container className="">
                  <h6 className="text-white font-weight-bold">Details: </h6>
                  <div align="center">
                    <Row>
                      <Col
                        className="p-2 mr-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Duration</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.timer}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="p-2 ml-1"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <Row>
                          <Col md={6}>
                            <span className="text-white">Language</span>
                          </Col>
                          <Col md={6}>
                            <span className="text-white">{props.language}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container> */}
                <div className="m-1 md:flex md:justify-between items-center">
                  <div className="m-1">
                    <h6 className="text-white">{props.name}</h6>
                    <h6 className="text-primary">{props.email}</h6>
                  </div>
                  <div className="flex max-sm:justify-between items-center">
                    <div>
                    <div className="flex justify-start items-center  text-start">
                   {
                    props.tutor && props.tutor.profile_image!=""?
                    <img  src={`${process.env.REACT_APP_MEDIAURL}/${
                             props.tutor && props.tutor.profile_image
                           }`} alt="" className="w-8 h-8 rounded-full" />
                           :
                           <BiUserCircle size={30} />
                   }
                    <span className="text-white mx-1">
                          {props.tutor && props.tutor.full_name}
                        </span>
                    </div>
                    <div align="center" className="text-primary text-start">
                        {props.tutor && props.tutor.email}
                    </div>
                    </div>
                    {(JSON.parse(user.permissions).includes("/request/edit") ||
                      JSON.parse(user.permissions).includes(
                        "/request/delete"
                      )) && (
                      <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle
                          variant="secondary"
                          className="dropdown-actions-button"
                        >
                          <SlOptionsVertical size={"30px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary2 p-1">
                          {JSON.parse(user.permissions).includes(
                            "/request/edit"
                          ) && (
                            <Link
                              to={"/request/edit"}
                              state={{
                                id: props.id,
                                type: props.table,
                                assistance_type: props.tbl,
                              }}
                              className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                            >
                              <FaPen className="m-1" />
                              Edit Record
                            </Link>
                          )}

                          {JSON.parse(user.permissions).includes(
                            "/request/delete"
                          ) && (
                            <DeleteRequestModal
                              DeleteFunction={() => {
                                DeleteFunction(props.id);
                              }}
                              id={props.id}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                    </div>
                    <div className="flex m-2 items-center">
                <span>Rating:</span>
                <span className="ml-2">{props.ratings}</span>
                <FaStar className="text-yellow-400 m-1" />
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-1 md:flex justify-between">
                  <h6 className="text-white text-lg font-medium">
                    {props.course_title} | {props.subject}
                  </h6>
                  {JSON.parse(user.permissions).includes("/prices") && (
                    <div>
                        <h6 className="font-medium text-[1.1rem]">
                          Student Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.student_price.toString().includes("$")
                              ? props.student_price
                              : "$" + props.student_price}
                          </span>
                        </h6>
                        <h6 className="font-medium text-[1.1rem]">
                          Tutor Price:
                          <span className="text-primary">
                            {" "}
                            {props.student_price &&
                            props.tutor_price.toString().includes("$")
                              ? props.tutor_price
                              : "$" + props.tutor_price}
                          </span>
                        </h6>
                    </div>
                    )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">Question</h6>
                {props.description && (
                    <>
                     <p className="text-[#CACAFF]">{props.description}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.file_links_student}
                        names={props.file_names_student}
                      />
                    </>
                  )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium">Answer</h6>
                {props.answer_text && (
                    <>
                     <p className="text-[#CACAFF]">{props.answer_text}</p>
                    </>
                  )}
                  {props.file_names_student && (
                    <>
                      <Slider
                        items={props.answer_files}
                        names={props.answer_files}
                      />
                    </>
                  )}
                </div>
                <div className="m-1">
                  <h6 className="text-white font-medium my-1 mb-2">Details</h6>
                    <div className="md:flex justify-between gap-2 w-full">
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          {props.tbl === "exam" ? (
                            <span className="text-white">Exam Type</span>
                          ) : (
                            <span className="text-white">Duration</span>
                          )}
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                          {props.timer} min
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between bg-[#38384a] p-3 w-full">
                        <div>
                          <span className="text-white">Language</span>
                        </div>
                        <div>
                          <span className="text-[#CACAFF]">
                            {props.language}
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="m-1 pt-2 py-1">
                <span className="my-2 text-[#BCBCC0]">ID: {props.id}</span>
                </div>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </Col>
  );
}

export default CardRequest;
