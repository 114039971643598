import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Row,Col, Form} from "react-bootstrap";

import { BsFillPersonFill, BsPlus } from "react-icons/bs";
import Axios from "../Utils/Axios";
function AddUserModal({ AddFunction,roles }) {
  const [show, setShow] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  async function addnew(e) {
    e.preventDefault();
    await Axios.post('/users/add',state).then((response)=>{
        AddFunction();
        handleClose();
    }).catch((error)=>{});
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    var output = document.getElementById('img');
    output.innerHTML="";
    output.innerHTML=`<img src=${URL.createObjectURL(file)} width='120px' height='120px' />`
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    setState((prevState)=>({ ...prevState, image: file }));
  };


  return (
    <>
      <Button variant="primary" className="flex items-center" onClick={handleShow}>
       <BsPlus className="icon display-5" /> Add New User
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Create Dashboard Users</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={addnew}>
        <Modal.Body className="bg-secondary2">
            <div className="m-3">
          <Row>
            <Col md={4}>
            <h5 className="text-white mb-3">Profile Picture</h5>
            <div className="image-chooser">
            <center>
                <div id="img">
              <BsFillPersonFill className="icon icon-chooser" />
              <br />
              <span className="text-white">Drag & drop image</span>
              <br />
              <span className="text-white">Or</span>
                </div>
              <br />
              <label for="customFile" class="custom-file-button bg-primary">
                Upload Image
                <input type="file" id="customFile" required onChange={handleImage} />
              </label>
            </center>
          </div>
            </Col>
            <Col md={8}>
            <label>User Name</label>
            <input type="text" name="" id="" className="form-control" required onChange={(e)=>setState({...state,username:e.target.value})} />
            <br />
            <label>Email</label>
            <input type="text" name="" id="" className="form-control" required onChange={(e)=>setState({...state,email:e.target.value})} />
            </Col>
          </Row>
          <br />
          <Row className="justify-content-around">
            <Col md={4}>
            <label>Password</label>
            <input type="password" name="" required id="" onChange={(e)=>setState({...state,password:e.target.value})} className="form-control" />
            <br />
            </Col>
            <Col md={4}>
            <label>Confirm Password</label>
            <input type="password" name="" required id="" onChange={(e)=>setState({...state,confirm_password:e.target.value})} className="form-control" />
            <br />

            </Col>
          </Row>
            <br />
            <Row className="justify-content-around">
                <Col>
                <label>Role</label>
            <select className="form-control" required  onChange={(e)=>setState({...state,role_id:e.target.value})}>
            <option value=""></option>
            {roles&&roles.map((role)=>{
                return <option value={role.id}>{role.title}</option>
            })}
            </select>
                </Col>
            </Row>
            </div>
        </Modal.Body>
        <Modal.Footer className="bg-secondary2">
          <Button type="submit" variant="primary">
            Create New User
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddUserModal;
