import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Axios from '../../Utils/Axios';
import { Link } from 'react-router-dom';
import { SlOptionsVertical } from 'react-icons/sl';
import { Button, Dropdown } from 'react-bootstrap';
import { FaPen } from 'react-icons/fa';
import DeleteSubjectModal from '../../modals/DeleteSubjectModal';
import { AiOutlinePlus } from 'react-icons/ai';
import EditSubjectModal from '../../modals/EditSubjectModal';
import AddSubjectModal from '../../modals/AddSubjectModal';
function Subjects() {
    const [data,setData]=useState([]);
    async function getData()
    {
        await Axios.get('/subjects').then((response)=>{
            setData(response.data.subjects);
        }).catch((e)=>{});
    }
    async function deleteFunction(id)
    {
        await Axios.delete(`/subjects/${id}`).then((response)=>{
            getData();
        }).catch((e)=>{})
    }
    async function updateFunction(state,id) {
        await Axios.post(`/subjects/${id}`,state).then((response)=>{
            getData();
        }).catch((e)=>{});
    }
    async function addFunction(state)
    {
        await Axios.post('subjects',state).then((response)=>{
            const newData = [...data, response.data.subject];
      setData(newData);
        }).catch((e)=>{});
    }
    useEffect(()=>{
        getData();
    },[]);
  return (
    <>
    <div className='d-flex justify-content-between m-3'>
    <h3 className='text-white'>Subjects List</h3>
    <AddSubjectModal AddFunction={addFunction} />
    </div>
    <br />
    <Table striped responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Label</th>
          <th>Operations</th>
        </tr>
      </thead>
      <tbody>
    {data.map((item)=>{
        return <tr>
            <td>{item.id}</td>
            <td>{item.label}</td>
            <td>
            <Dropdown drop="start" className="bg-secondary2">
                <Dropdown.Toggle
                  variant="secondary"
                  className="dropdown-actions-button"
                >
                  <SlOptionsVertical className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-secondary2 p-1">
                  <EditSubjectModal UpdateFunction={updateFunction} id={item.id} data={item} />
                  <br />
                <DeleteSubjectModal DeleteFunction={deleteFunction} id={item.id} />
                  <br />
                </Dropdown.Menu>
              </Dropdown>
            </td>
        </tr>
    })}
      </tbody>
    </Table>
    </>
  )
}

export default Subjects
