import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Thumbnail from './Thumbnail';

function Slider2({items,names}) {
    const options={
        nav:false,
        dots:false,
        loop:false,
        margin:10,
        stagePadding :10,
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 1,
          },
          768: {
            items: 4,
          },
          992: {
            items: 4,
          },
        },
    }
  return (
    <OwlCarousel className='owl-theme overflow-x-scroll' {...options}>
    {items&&items.map((item,index)=>{
       return <div className='item'>
        <Thumbnail imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`} title={names[index]} />
    </div>
    })}
</OwlCarousel>
  )
}

export default Slider2;
