import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlus } from 'react-icons/ai';

function AddSubjectModal({AddFunction}) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function addFunction(e)
  {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
        AddFunction(state);
        handleClose();
    }
    else
    {
        setValid(true);
    }
  }

const handleInput=(e)=>{
    setState({
        ...state,
        [e.target.name]:e.target.value
    })
}

  return (
    <>
      <Button variant="primary" className='flex items-center m-1' onClick={handleShow}>
      <AiOutlinePlus className="icon" />Add New Record
      </Button>

      <Modal show={show} onHide={handleClose} className='text-white'>
        <Modal.Header className='bg-secondary2'>
          <Modal.Title>Add Subject</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} onSubmit={addFunction}>
        <Modal.Body  className='bg-secondary2'>
        <div className="mb-3">
            <input
              required
              type="text"
              name="label"
              onChange={handleInput}
              className="form-control"
              placeholder="Label"
            />
            <Form.Control.Feedback type="invalid">
              Please enter the label.
            </Form.Control.Feedback>
            </div>
        </Modal.Body>
        <Modal.Footer  className='bg-secondary2'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddSubjectModal;
