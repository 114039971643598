import Axios from "../../Utils/Axios";
import { UserReducer } from "../reducers/UserReducer";
export const LoadUser = () => async (dispatch) => {
  dispatch({
    type: "LoadUserRequest",
  });
  await Axios.get("/user")
    .then((response) => {
        const user={
            id: response.data.user.id,
            name: response.data.user.username,
            email: response.data.user.email,
            role: response.data.user.role && response.data.user.role.title,
            picture: response.data.user.profile_picture,
            permissions: response.data.user.role && response.data.user.role.permissions,
          }
      dispatch({
        type: "LoadUserSuccess",
        payload: user
      });
    })
    .catch((error) => {
      dispatch({
        type: "LoadUserFailed",
        payload: error.response.data.message,
      });
    });
};
