import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";
import PriceSet from "../components/PriceSet";
function RejectOnline({rejectFunction,id}) {
    const [show,setShow]=useState(false);
    async function reject()
    {
        await rejectFunction(id).then(()=>{
            setShow(false);
        });
    }
  return (
    <div>
      <Button
        variant="danger"
        className="w-100"
        size="lg"
        onClick={()=>{setShow(true)}}
      >
        <MdDangerous /> Reject
      </Button>
      <Modal show={show} onHide={()=>setShow(false)} centered className="text-white">
      <Modal.Body className="bg-secondary2">
            <div className="justify-content-center">
            <MdDangerous className="text-danger w-100 display-1"/>
            </div>
        <center>
            <strong><h2 className="text-white font-bold">Are you sure you want reject this class?</h2></strong>
            <br />
            <br />
            <br />
        <Button variant="secondary" className="m-1" onClick={()=>setShow(false)}>
            Cancel
          </Button>
          <Button variant="danger"  className="m-1" onClick={reject}>
            Reject
          </Button>
        </center>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RejectOnline;
