import React, { useEffect, useState } from "react";
import { FiCalendar } from "react-icons/fi";
import {
  Tab,
  Tabs,
  Row,
  Button,
  Pagination,
  Table,
  Dropdown,
  Modal,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import Axios from "../../Utils/Axios";
import { SlOptionsVertical } from "react-icons/sl";
import EditCourseModal from "../../modals/EditCourseModal";
import DeleteRequestModal from "../../modals/DeleteRequestModal";
import AcceptOnline from "../../modals/AcceptOnline";
import RejectOnline from "../../modals/RejectOnline";
import TutorInfoModal from "../../modals/TutorInfoModal";
function Courses() {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "short" });
  const year = today.getFullYear();
  const[courses,setCourses]=useState([]);
  async function getCourses()
  {
    await Axios.get('courses').then((response)=>{
      setCourses(response.data.courses);
    }).catch((error)=>{});
  }
  async function UpdateFunction(state,id)
  {
    await Axios.post(`/course/update/${id}`,state).then((response)=>{
      getCourses();
    }).catch((error)=>{});
  }

  async function acceptFunction(tutorPrice,studentPrice,id)
  {
    await Axios.post(`/course/update/${id}`,{tutor_price:tutorPrice,student_price:studentPrice,status:1}).then((response)=>{
      getCourses();

    }).catch((error)=>{});
  }
  async function rejectFunction(id)
  {
    await Axios.post(`/course/update/${id}`,{status:2}).then((response)=>{
      getCourses();

    }).catch((error)=>{});
  }
  useEffect(()=>{
    getCourses();
  },[]);
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2>Courses</h2>
        <div>
          <Button variant="outline-light" className="m-1">
            <FiCalendar className="icon" />
            {day + " " + month + ", " + year}
          </Button>
        </div>
      </div>
      <br />
    <Tabs
        style={{ width: "fit-content" }}
        defaultActiveKey="pending"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="pending" title={`Pending Courses`}>
          <Row>
          {courses.filter((item)=>item.status===0).map((item)=>{
            return <Col md={6}><Card className="bg-secondary border-primary">
            <Card.Body
              style={{ maxHeight: "400px", overflow: "scroll" }}
            >
              <Container className="d-flex justify-content-between p-1">
                <Row>
                  <Col md={12}>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Title: <span className="text-primary">{item.title}</span>
                      </h6>
                    </Container>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Created by:{" "}
                        <TutorInfoModal data={item.tutor} />

                      </h6>
                    </Container>
                  </Col>
                </Row>
                <Row align="right" className="align-items-center">
                {
                 item.student&&   <Col md={9}>
                      <span className={item.tutor? "text-white" : "text-secondary"}>
                      {item.tutor&&item.tutor.nickname}
                      </span>
                    </Col>
                }
                <Col>
                <Dropdown drop="start" className="bg-secondary">
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-actions-button"
            >
              <SlOptionsVertical size={"30px"} className="icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-secondary2 p-1">
                <EditCourseModal UpdateFunction={UpdateFunction} id={item.id} data={item} />
            </Dropdown.Menu>
          </Dropdown>
                </Col>
                  </Row>

              </Container>
              <Container className="">
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Subject:{" "}
                </h6>
                <p>{item.subject}</p>
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Description:{" "}
                </h6>
                <p>{item.description}</p>
              </Container>

            </Card.Body>
            <Card.Footer>
              <Container className="">
                <Row>
                    <Col md={6}><RejectOnline rejectFunction={rejectFunction} id={item.id} /></Col>
                  <Col md={6}>
                   <AcceptOnline acceptFunction={acceptFunction} id={item.id} />
                  </Col>
                </Row>
              </Container>
            </Card.Footer>
          </Card>
          </Col>
          })}
          </Row>
        </Tab>
        <Tab eventKey="accepted" title={`Accepted Courses`}>
        <Row>
          {courses.filter((item)=>item.status===1).map((item)=>{
            return <Col md={6}><Card className="bg-secondary border-primary">
            <Card.Body
              style={{ maxHeight: "400px", overflow: "scroll" }}
            >
              <Container className="d-flex justify-content-between p-1">
                <Row>
                  <Col md={12}>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Title: <span className="text-primary">{item.title}</span>
                      </h6>
                    </Container>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Created by:{" "}
                        <TutorInfoModal data={item.tutor} />
                      </h6>
                      {/* <h6 className="text-primary">
                        {item.tutor&& item.tutor.nickname}
                      </h6> */}
                    </Container>
                  </Col>
                </Row>
                <Row align="right" className="align-items-center">
                {
                 item.student&&   <Col md={9}>
                      <span className={item.tutor? "text-white" : "text-secondary"}>
                      {item.tutor&&item.tutor.nickname}
                      </span>
                    </Col>
                }
                <Col>
                <Dropdown drop="start" className="bg-secondary">
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-actions-button"
            >
              <SlOptionsVertical size={"30px"} className="icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-secondary2 p-1">
                <EditCourseModal UpdateFunction={UpdateFunction} id={item.id} data={item} />
            </Dropdown.Menu>
          </Dropdown>
                </Col>
                  </Row>

              </Container>
              <Container className="">
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Subject:{" "}
                </h6>
                <p>{item.subject}</p>
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Description:{" "}
                </h6>
                <p>{item.description}</p>
              </Container>

              <Container className="d-flex justify-content-around">
                <h6 className="text-primary font-weight-bold">
                  Tutor price: <span className="text-white">{item.tutor_price} $</span>
                </h6>
                <h6 className="text-primary font-weight-bold">
                  Student price:  <span className="text-white">{item.student_price} $</span>
                </h6>
              </Container>
            </Card.Body>
            {/* <Card.Footer>
              <Container className="">
                <Row>
                    <Col md={6}><RejectOnline rejectFunction={rejectFunction} id={item.id} /></Col>
                  <Col md={6}>
                   <AcceptOnline acceptFunction={acceptFunction} id={item.id} />
                  </Col>
                </Row>
              </Container>
            </Card.Footer> */}
          </Card>
          </Col>
          })}
          </Row>
        </Tab>
        <Tab eventKey="rejected" title={`Rejected Courses`}>
        <Row>
          {courses.filter((item)=>item.status===2).map((item)=>{
            return <Col md={6}><Card className="bg-secondary border-primary">
            <Card.Body
              style={{ maxHeight: "400px", overflow: "scroll" }}
            >
              <Container className="d-flex justify-content-between p-1">
                <Row>
                  <Col md={12}>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Title: <span className="text-primary">{item.title}</span>
                      </h6>
                    </Container>
                    <Container className="">
                      <h6 className="text-white font-weight-bold">
                        Created by:{" "}
                        <TutorInfoModal data={item.tutor} />
                      </h6>
                    </Container>
                  </Col>
                </Row>
                <Row align="right" className="align-items-center">
                {
                 item.student&&   <Col md={9}>
                      <span className={item.tutor? "text-white" : "text-secondary"}>
                      {item.tutor&&item.tutor.nickname}
                      </span>
                    </Col>
                }
                <Col>
                <Dropdown drop="start" className="bg-secondary">
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-actions-button"
            >
              <SlOptionsVertical size={"30px"} className="icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-secondary2 p-1">
                <EditCourseModal UpdateFunction={UpdateFunction} id={item.id} data={item} />
            </Dropdown.Menu>
          </Dropdown>
                </Col>
                  </Row>

              </Container>
              <Container className="">
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Subject:{" "}
                </h6>
                <p>{item.subject}</p>
              </Container>
              <Container className="">
                <h6 className="text-white font-weight-bold">
                  Description:{" "}
                </h6>
                <p>{item.description}</p>
              </Container>

            </Card.Body>
            {/* <Card.Footer>
              <Container className="">
                <Row>
                    <Col md={6}><RejectOnline rejectFunction={rejectFunction} id={item.id} /></Col>
                  <Col md={6}>
                   <AcceptOnline acceptFunction={acceptFunction} id={item.id} />
                  </Col>
                </Row>
              </Container>
            </Card.Footer> */}
          </Card>
          </Col>
          })}
          </Row>
        </Tab>
        </Tabs>
    </div>
  )
}

export default Courses
