import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CKEditor } from 'ckeditor4-react';
function CancelRequestModal({cancelFunction,id}) {
    const [show,setShow]=useState(false);
    const[state,setState]=useState("");
    const handleEditorChange = (event) => {
        const content = event.editor.getData();
        setState((prevState) => ({ ...prevState, reason: content }));
      };
    async function cancel(e)
    {
      e.preventDefault();
        await cancelFunction(state).then(()=>{
            setShow(false);
        });
    }
  return (
    <div>
      <Button
        variant="primary"
        className="w-100"
        onClick={()=>{setShow(true)}}
      >
     Cancel Request
      </Button>
      <Modal show={show} onHide={()=>setShow(false)} centered className="text-white">
      <Modal.Header className='bg-secondary2'>
          <Modal.Title>Cancel Request</Modal.Title>
        </Modal.Header>
        <form onSubmit={cancel}>
      <Modal.Body className="bg-secondary2">
            <label>Reason</label>
            <CKEditor onChange={handleEditorChange} />
        </Modal.Body>
        <Modal.Footer className='bg-secondary2'>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
          <Button type='submit' variant="primary">
            Save
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default CancelRequestModal;
