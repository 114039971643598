import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdOutlineEmail } from "react-icons/md";
import { CKEditor } from "ckeditor4-react";
function StudentSendMailModal({ email, sendMailFunction }) {
  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  async function send() {
    await sendMailFunction({email:email,subject:subject,message:message}).then(() => {
      setShow(false);
    });
  }
  const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setMessage(content);
  };
  return (
    <div>
      <div
        className="flex gap-1 items-center cursor-pointer"
        onClick={() => setShow(true)}
      >
        <MdOutlineEmail size={30} className="ml-3" />
        <span>{email}</span>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <span className="text-lg">Send Mail</span>
        </Modal.Header>
        <Modal.Body className="bg-secondary2">
        <div className="my-2">
            <label>To</label>
            <input
              className="form-control"
              readOnly
              value={email}
            />
          </div>
          <div className="my-1">
            <label>Subject</label>
            <input
              className="form-control"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </div>
          <div className="my-2">
            <label>Message</label>
            <textarea onChange={(e) => setMessage(e.target.value)} rows={5} className="form-control" />
            {/* <CKEditor required onChange={handleEditorChange} /> */}
          </div>
          <div className="flex justify-center items-center">
            <button className="btn btn-primary" onClick={send}>Send</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StudentSendMailModal;
