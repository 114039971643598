import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Axios from "../../Utils/Axios";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import PriceSet from "../../components/PriceSet";
import DurationSet from "../../components/DurationSet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Thumbnail from "../../components/Thumbnail";
import { Avatar } from "@mui/material";
// import { Rating } from "react-simple-star-rating";
import Rating from '@mui/material/Rating';
import { BiUserCircle } from "react-icons/bi";
import { BsFillFileEarmarkFill } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import messaging from "../../Firebase";
import { enqueueSnackbar } from "notistack";
import ReactAudioPlayer from "react-audio-player";
import CancelRequestModal from "../../modals/CancelRequestModal";
import { FaArrowLeft } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
function EditSOS() {
  const [show, setShow] = useState(false);
  const [room, setRoom] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  let id = location.state.id;
  const [sos, setSos] = useState(null);
  const user = useSelector((state) => state.user).user;
  const divRef = useRef(null);
  const options = {
    nav: false,
    dots: false,
    loop: false,
    margin: 10,
    stagePadding: 10,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 4,
      },
      992: {
        items: 4,
      },
    },
  };
  async function getData() {
    await Axios.get(`/sos/${id}`)
      .then((response) => {
        setSos(response.data.request);
      })
      .catch((error) => {});
  }
  async function update() {
    location.sos.type === "sos" &&
      (await Axios.post(`/sos/${location.sos.id}`, sos)
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
  }
  const [msg, setMsg] = useState("");
  const handleMsg = (e) => {
    setMsg(e.target.value);
  };
  async function sendMessage() {
    msg != "" &&
      (await Axios.post(
        "sos/send/message",
        {
          room_id: sos.chat.id,
          sender_id: user.id,
          message: msg,
          student: sos.student,
          tutor: sos.tutor,
          request_id:sos.id
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          getData();
          document.getElementById("msg").value = "";
        })
        .catch((error) => {}));
  }
  async function paid() {
    await Axios.post(`/sos/${sos.id}`, {
        paid: 1,
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {});
  }
  async function cancel(state) {
    location.state.type === "requests" &&
      (await Axios.post(`/cancel/${location.state.id}`, {
        type:"request",
        reason:state.reason
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
    location.state.type === "sos" &&
      (await Axios.post(`/cancel/${location.state.id}`, {
        type:"sos",
        reason:state.reason
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
  }
  useEffect(() => {
    getData();
    onMessage(messaging, (payload) => {
      getData();
    });
    const scrollToMaxHeight = () => {
      if (divRef.current) {
        const maxHeight = divRef.current.scrollHeight;
        divRef.current.scrollTop = maxHeight;
      }
      else setInterval(() => {
        if (divRef.current) {
          const maxHeight = divRef.current.scrollHeight;
          divRef.current.scrollTop = maxHeight;
        }
      }, 500);
    };

    setTimeout(() => {
      scrollToMaxHeight();
    }, 5000);
  }, [divRef.current]);
  return (
    <div className="m-3">
      {sos && (
        <div className="md:flex">
          <div>
          {sos.request_status === "new" && (
             <div className="md:flex gap-10">
             <div>
               <span style={{ fontSize: "30px" }}>S.O.S </span>
               <span className="text-primary">
                 {sos.request_status === "new" && <>New Request</>}
                 {sos.request_status === "waiting_payment" && (
                   <>Waiting Payment</>
                 )}
                 {sos.request_status === "ongoing" && <>Ongoing</>}
                 {sos.request_status === "completed" && <>Completed</>}
               </span>
               <div className="flex justify-between">
               <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
               <CancelRequestModal cancelFunction={cancel} id={sos.id} />
               </div>
               <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                 <div className=" max-sm:py-2">
                   <label htmlFor="student_nickname">Student Nickname</label>
                   <input
                     type="text"
                     name="student.nickname"
                     id="student_nickname"
                     // onChange={handleInput}
                     className="form-control border-none mt-1"
                     value={sos.student.nickname}
                     readOnly
                   />
                 </div>
                 <div className=" max-sm:py-2">
                   <label htmlFor="student_email">Student Email</label>
                   <input
                     type="text"
                     name="student.email"
                     id="student_email"
                     // onChange={handleInput}
                     className="form-control border-none mt-1"
                     value={sos.student.email}
                     readOnly
                   />
                 </div>
                 <div className=" max-sm:py-2">
                   <label htmlFor="course_title">Course Title</label>
                   <input
                     type="text"
                     name="label"
                     id="course_title"
                     className="form-control border-none mt-1"
                     // onChange={handleInput}
                     value={sos.label}
                   />
                 </div>
                 <div className=" max-sm:py-2">
                   <PriceSet
                     withButtons={false}
                     title={"Student Price"}
                     initialValue={sos.student_price}
                     onChange={(value) =>
                       setSos({ ...sos, student_price: value })
                     }
                   />
                 </div>
               </div>
               <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                 <div className="max-sm:py-2">
                   <label htmlFor="subject">Subject</label>
                   <input
                     type="text"
                     name="subject"
                     id="subject"
                     // onChange={handleInput}
                     className="form-control border-none mt-1"
                     value={sos.subject}
                     readOnly
                   />
                 </div>
                 <div className="max-sm:py-2">
                   <label htmlFor="country">Country</label>
                   <input
                     type="text"
                     name="country"
                     id="country"
                     // onChange={handleInput}
                     className="form-control border-none mt-1"
                     value={sos.country}
                     readOnly
                   />
                 </div>
                 <div className="max-sm:py-2">
                   <DurationSet
                     title={"Duration(minutes)"}
                     initialValue={sos.timer}
                     onChange={(value) => setSos({ ...sos, timer: value })}
                   />
                 </div>
                 {/* <div className=" max-sm:py-2 flex justify-center">
                   <div>
                     <label htmlFor="description">ID</label>
                     <input
                       value={sos.id}
                       readOnly
                       className="form-control w-20 border-none mt-1"
                     />
                   </div>
                 </div> */}
                 <div className=" mt-2">
                   <PriceSet
                     withButtons={false}
                     title={"Tutor Price"}
                     initialValue={sos.tutor_price}
                     onChange={(price) =>
                       setState({ ...sos, tutor_price: price })
                     }
                   />
                 </div>
               </div>
               <br />
               <div className="max-sm:py-2 w-full mt-3">
                 <label htmlFor="description">Question Description</label>
                 <textarea
                   style={{ resize: "none" }}
                   readOnly
                   className="form-control border-none mt-1 w-full"
                   rows={5}
                 >
                   {sos.description}
                 </textarea>
               </div>
               {sos.file_links_student && (
                 <div className=" mt-3">
                   <label>Question Upload</label>
                   <OwlCarousel className="owl-theme" {...options}>
                     {sos.file_links_student &&
                       JSON.parse(sos.file_links_student).map((item, index) => {
                         return (
                           <div className="item">
                             <Thumbnail
                               imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`}
                               title={JSON.parse(sos.file_names_student)[index]}
                             />
                           </div>
                         );
                       })}
                   </OwlCarousel>
                 </div>
               )}
             </div>
           <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden" ></div>
           <div>
             <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
             {sos && <div>
              <div class="iphone md:h-[78vh] md:w-72 md:ml-16">
                {room?
                <>
              <div className="absolute z-[999] p-3 w-full bg-secondary">
                <FaArrowLeft onClick={()=>setRoom(null)} />
              </div>
                <div class="screen">
                  <div class="fix bg-secondary">
                  <FaArrowLeft />
                  </div>
                  <div class="screen-content mt-10 overflow-scroll" id="divref" ref={divRef}>
                    {sos.rooms.find((rooms) => rooms.id === room).messages.map((item) => {
                        return (
                          <div
                            className={
                              item.type === "admin"
                                ? `direct-chat-msg right`
                                : `direct-chat-msg`
                            }
                          >
                            <div class="direct-chat-infos clearfix">
                              <span class="direct-chat-name float-left">
                                {item.sender_type === "tutor" &&
                                  (sos.tutor && sos.tutor.nickname) +
                                    "(Tutor)"}
                                {item.sender_type === "student" &&
                                  sos.student &&
                                  sos.student.nickname}
                                {item.sender_type === "admin" &&
                                  "Administrator"}
                              </span>
                            </div>
                            <div>
                              {
                               (item.message_type === "file" && item.message.split(".").pop()==="m4a")&&
                                <div className="custom-audio-player w-full">
                                  <ReactAudioPlayer
                                  src={process.env.REACT_APP_MEDIAURL+item.message}
                                  autoPlay={false}
                                  controls
                                />
                                </div>
                              }
                            </div>
                            {(item.message_type === "file" && item.message.split(".").pop()!="m4a") &&
                            <div
                              className={
                                (item.sender_type === "tutor" &&
                                  `direct-chat-text bg-light`) ||
                                (item.sender_type === "student" &&
                                  `direct-chat-text bg-primary`) ||
                                (item.sender_type === "admin" &&
                                  `direct-chat-text bg-info`)
                              }
                            >
                            </div>
                            }
                            {
                              item.message_type==="text"&&
                              <div
                                className={
                                  (item.sender_type === "tutor" &&
                                    `direct-chat-text bg-light`) ||
                                  (item.sender_type === "student" &&
                                    `direct-chat-text bg-primary`) ||
                                  (item.sender_type === "admin" &&
                                    `direct-chat-text bg-info`)
                                }
                              >
                                {
                                  item.message
                                }
                              </div>
                            }

                            <span className="direct-chat-timestamp float-right">
                              {new Date(item.created_at).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </span>
                          </div>
                        );
                      })}
                  </div>

                  <div class="fix bg-secondary">
                    <div class="form-row align-items-center">
                      <div class="col">
                        <input
                          type="text"
                          id="msg"
                          class="form-control"
                          placeholder="Type your message"
                          onChange={handleMsg}
                        />
                      </div>
                      <div class="col-auto">
                        <button class="btn text-white" onClick={sendMessage}>
                          <AiOutlineSend />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                :
                <div className="h-[100vh] overflow-scroll">
                {sos.rooms.map((room)=>{
                  return <div className="m-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer" onClick={()=>{
                    setTimeout(() => {
                      if (divRef.current) {
                        const maxHeight = divRef.current.scrollHeight;
                        divRef.current.scrollTop = maxHeight;
                      };
                    }, 500);
                    setRoom(room.id)}}>
                    {room.tutor.profile_image?
                    <img className="w-8 h-8 rounded-full" src={process.env.REACT_APP_MEDIAURL+room.tutor.profile_image} />
                    :
                    <MdAccountCircle className="w-8 h-8 rounded-full" />
                    }
                    {room.tutor.nickname}
                    </div>
                })}
                </div>
                }
                </div>
               </div>}
           </div>
             </div>

          )}
          </div>
          {(sos.request_status === "waiting_payment") && (
            <div className="md:flex">
            <div className="w-full">
              <span style={{ fontSize: "30px" }}>S.O.S </span>
              <span className="text-primary">
                {sos.request_status === "new" && <>New Request</>}
                {sos.request_status === "waiting_payment" && (
                  <>Waiting Payment</>
                )}
                {sos.request_status === "ongoing" && <>Ongoing</>}
                {sos.request_status === "completed" && <>Completed</>}
              </span>
              <div className="flex justify-between items-center">
              <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
              <div className="flex gap-10">
              <button className="btn btn-primary" onClick={paid}>
              Mark As Paid
              </button>
              <CancelRequestModal cancelFunction={cancel} id={sos.id} />
              </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className=" max-sm:py-2">
                  <label htmlFor="student_nickname">Student Nickname</label>
                  <input
                    type="text"
                    name="student.nickname"
                    id="student_nickname"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.nickname}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="student_email">Student Email</label>
                  <input
                    type="text"
                    name="student.email"
                    id="student_email"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.email}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="course_title">Course Title</label>
                  <input
                    type="text"
                    name="label"
                    id="course_title"
                    className="form-control border-none mt-1"
                    // onChange={handleInput}
                    value={sos.label}
                  />
                </div>
                <div className=" max-sm:py-2">
                  <PriceSet
                    withButtons={false}
                    title={"Student Price"}
                    initialValue={sos.student_price}
                    onChange={(value) =>
                      setSos({ ...sos, student_price: value })
                    }
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="mt-2">
                  <label className="font-bold">Tutor</label>
                  <div className="d-flex align-items-center">
                    {sos.tutor && sos.tutor.profile_image != "" ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIAURL}/${
                          sos.tutor && sos.tutor.profile_image
                        }`}
                        className="rounded-full w-8 h-8"
                      />
                    ) : (
                      <BiUserCircle size={30} />
                    )}
                    <a className="ml-1" onClick={handleShow}>
                      View Profile
                    </a>
                  </div>
                  <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    className="text-white"
                  >
                    <Modal.Header className="bg-secondary2">
                      <Modal.Title>Tutor Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-secondary2">
                      <Card className="bg-secondary border-primary">
                        <Card.Body
                          style={{
                            maxHeight: "500px",
                            overflow: "scroll",
                          }}
                        >
                          {sos.tutor && (
                            <div>
                              <div className="md:flex justify-between m-2">
                                <div className="mt-2 flex">
                                  <div className="flex justify-start max-sm:justify-between items-center">
                                    <img
                                      src={`${process.env.REACT_APP_MEDIAURL}/${
                                        sos.tutor && sos.tutor.profile_image
                                      }`}
                                      className="rounded-full w-8 h-8 p-1"
                                    />
                                    <div className="text-primary">
                                      {sos.tutor.full_name}
                                    </div>
                                    <div className="text-white ml-3">
                                      ID:{sos.tutor.id}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Email:</span>{" "}
                                <span className="text-primary">
                                  {sos.tutor.email}
                                </span>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Bio Information</span>
                                <div>
                                  <p className="text-[#CACAFF]" dangerouslySetInnerHTML={{ __html:sos.tutor.bio }} />
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div>
                                  <span>Phone Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.phone_country_code +
                                      sos.tutor.phone}
                                  </span>
                                </div>
                                <div>
                                  <span>WhatsApp Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.whatsapp_country_code +
                                      sos.tutor.whatsapp_phone}
                                  </span>
                                </div>
                              </div>
                              <hr className="bg-[#28293D] h-1 my-1 w-full" />
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div>Subjects: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.subjects.map((item) => {
                                      return (
                                        // <Col>
                                        <div className="language_item m-1 py-1">
                                          {item.subject.label}
                                        </div>
                                        // </Col>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div className="py-1">Languages: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.languages.map((item) => {
                                      return (
                                        <div className="language_item py-1 m-1">
                                          {item.language.language_name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <span className="text-white">
                                    Educations:{" "}
                                  </span>
                                  <br />
                                  <div align="center">
                                    <Row>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          University
                                        </span>
                                      </Col>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          Degree
                                        </span>
                                      </Col>
                                    </Row>
                                    {JSON.parse(sos.tutor.university).map(
                                      (university, index) => {
                                        return (
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {university}
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {
                                                  JSON.parse(sos.tutor.degree)[
                                                    index
                                                  ]
                                                }
                                              </span>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Container className="">
                                <br />
                                <div>
                                  <Row>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Tutor ID
                                      </h5>
                                      <Thumbnail
                                        imageUrl={sos.tutor.id_file_link}
                                        title={"Tutor ID"}
                                      />
                                    </Col>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Grade Transcript
                                      </h5>
                                      <Thumbnail
                                        imageUrl={
                                          sos.tutor.certification_file_link
                                        }
                                        title={"Grade Transcript"}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Container>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer className="bg-secondary2">
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Nickname</label>
                  <input
                    value={sos.tutor && sos.tutor.nickname}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Email</label>
                  <input
                    value={sos.tutor && sos.tutor.email}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className=" mt-2">
                  <PriceSet
                    withButtons={false}
                    title={"Tutor Price"}
                    initialValue={sos.tutor_price}
                    onChange={(price) =>
                      setState({ ...sos, tutor_price: price })
                    }
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="max-sm:py-2">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.subject}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.country}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <DurationSet
                    title={"Duration(minutes)"}
                    initialValue={sos.timer}
                    onChange={(value) => setSos({ ...sos, timer: value })}
                  />
                </div>
                <div className=" max-sm:py-2 flex justify-center">
                  <div>
                    <label htmlFor="description">ID</label>
                    <input
                      value={sos.id}
                      readOnly
                      className="form-control w-20 border-none mt-1"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="max-sm:py-2 w-full mt-3">
                <label htmlFor="description">Question Description</label>
                <textarea
                  style={{ resize: "none" }}
                  readOnly
                  className="form-control border-none mt-1 w-full"
                  rows={5}
                >
                  {sos.description}
                </textarea>
              </div>
              {sos.file_links_student && (
                <div className=" mt-3">
                  <label>Question Upload</label>
                  <OwlCarousel className="owl-theme" {...options}>
                    {sos.file_links_student &&
                      JSON.parse(sos.file_links_student).map((item, index) => {
                        return (
                          <div className="item">
                            <Thumbnail
                              imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`}
                              title={JSON.parse(sos.file_names_student)[index]}
                            />
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              )}
            </div>
            <div>
             <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
             {sos && <div>
              <div class="iphone md:h-[80vh] md:w-72 md:ml-16">
                {room?
                <>
              <div className="absolute z-[999] p-3 w-full bg-secondary">
                <FaArrowLeft onClick={()=>setRoom(null)} />
              </div>
                <div class="screen">
                  <div class="fix bg-secondary">
                  <FaArrowLeft />
                  </div>
                  <div class="screen-content mt-10 overflow-scroll" id="divref" ref={divRef}>
                    {sos.rooms.find((rooms) => rooms.id === room).messages.map((item) => {
                        return (
                          <div
                            className={
                              item.type === "admin"
                                ? `direct-chat-msg right`
                                : `direct-chat-msg`
                            }
                          >
                            <div class="direct-chat-infos clearfix">
                              <span class="direct-chat-name float-left">
                                {item.sender_type === "tutor" &&
                                  (sos.tutor && sos.tutor.nickname) +
                                    "(Tutor)"}
                                {item.sender_type === "student" &&
                                  sos.student &&
                                  sos.student.nickname}
                                {item.sender_type === "admin" &&
                                  "Administrator"}
                              </span>
                            </div>
                            <div>
                              {
                               (item.message_type === "file" && item.message.split(".").pop()==="m4a")&&
                                <div className="custom-audio-player w-full">
                                  <ReactAudioPlayer
                                  src={process.env.REACT_APP_MEDIAURL+item.message}
                                  autoPlay={false}
                                  controls
                                />
                                </div>
                              }
                            </div>
                            {(item.message_type === "file" && item.message.split(".").pop()!="m4a") &&
                            <div
                              className={
                                (item.sender_type === "tutor" &&
                                  `direct-chat-text bg-light`) ||
                                (item.sender_type === "student" &&
                                  `direct-chat-text bg-primary`) ||
                                (item.sender_type === "admin" &&
                                  `direct-chat-text bg-info`)
                              }
                            >
                            </div>
                            }
                            {
                              item.message_type==="text"&&
                              <div
                                className={
                                  (item.sender_type === "tutor" &&
                                    `direct-chat-text bg-light`) ||
                                  (item.sender_type === "student" &&
                                    `direct-chat-text bg-primary`) ||
                                  (item.sender_type === "admin" &&
                                    `direct-chat-text bg-info`)
                                }
                              >
                                {
                                  item.message
                                }
                              </div>
                            }

                            <span className="direct-chat-timestamp float-right">
                              {new Date(item.created_at).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </span>
                          </div>
                        );
                      })}
                  </div>

                  <div class="fix bg-secondary">
                    <div class="form-row align-items-center">
                      <div class="col">
                        <input
                          type="text"
                          id="msg"
                          class="form-control"
                          placeholder="Type your message"
                          onChange={handleMsg}
                        />
                      </div>
                      <div class="col-auto">
                        <button class="btn text-white" onClick={sendMessage}>
                          <AiOutlineSend />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                :
                <div className="h-[100vh] overflow-scroll">
                {sos.rooms.map((room)=>{
                  return <div className="m-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer" onClick={()=>{
                    setTimeout(() => {
                      if (divRef.current) {
                        const maxHeight = divRef.current.scrollHeight;
                        divRef.current.scrollTop = maxHeight;
                      };
                    }, 500);
                    setRoom(room.id)}}>
                    {room.tutor.profile_image?
                    <img className="w-8 h-8 rounded-full" src={process.env.REACT_APP_MEDIAURL+room.tutor.profile_image} />
                    :
                    <MdAccountCircle className="w-8 h-8 rounded-full" />
                    }
                    {room.tutor.nickname}
                    </div>
                })}
                </div>
                }
                </div>
               </div>}
           </div>
            </div>
          )}
          {(sos.request_status === "ongoing") && (
            <div className="md:flex gap-10">
            <div>
              <span style={{ fontSize: "30px" }}>S.O.S </span>
              <span className="text-primary">
                {sos.request_status === "new" && <>New Request</>}
                {sos.request_status === "waiting_payment" && (
                  <>Waiting Payment</>
                )}
                {sos.request_status === "ongoing" && <>Ongoing</>}
                {sos.request_status === "completed" && <>Completed</>}
              </span>
              <div className="flex justify-between">
              <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
              <CancelRequestModal cancelFunction={cancel} id={sos.id} />
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className=" max-sm:py-2">
                  <label htmlFor="student_nickname">Student Nickname</label>
                  <input
                    type="text"
                    name="student.nickname"
                    id="student_nickname"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.nickname}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="student_email">Student Email</label>
                  <input
                    type="text"
                    name="student.email"
                    id="student_email"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.email}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="course_title">Course Title</label>
                  <input
                    type="text"
                    name="label"
                    id="course_title"
                    className="form-control border-none mt-1"
                    // onChange={handleInput}
                    value={sos.label}
                  />
                </div>
                <div className=" max-sm:py-2">
                  <PriceSet
                    withButtons={false}
                    title={"Student Price"}
                    initialValue={sos.student_price}
                    onChange={(value) =>
                      setSos({ ...sos, student_price: value })
                    }
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="mt-2">
                  <label className="font-bold">Tutor</label>
                  <div className="d-flex align-items-center">
                    {sos.tutor && sos.tutor.profile_image != "" ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIAURL}/${
                          sos.tutor && sos.tutor.profile_image
                        }`}
                        className="rounded-full w-8 h-8"
                      />
                    ) : (
                      <BiUserCircle size={30} />
                    )}
                    <a className="ml-1" onClick={handleShow}>
                      View Profile
                    </a>
                  </div>
                  <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    className="text-white"
                  >
                    <Modal.Header className="bg-secondary2">
                      <Modal.Title>Tutor Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-secondary2">
                      <Card className="bg-secondary border-primary">
                        <Card.Body
                          style={{
                            maxHeight: "500px",
                            overflow: "scroll",
                          }}
                        >
                          {sos.tutor && (
                            <div>
                              <div className="md:flex justify-between m-2">
                                <div className="mt-2 flex">
                                  <div className="flex justify-start max-sm:justify-between items-center">
                                    <img
                                      src={`${process.env.REACT_APP_MEDIAURL}/${
                                        sos.tutor && sos.tutor.profile_image
                                      }`}
                                      className="rounded-full w-8 h-8 p-1"
                                    />
                                    <div className="text-primary">
                                      {sos.tutor.full_name}
                                    </div>
                                    <div className="text-white ml-3">
                                      ID:{sos.tutor.id}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Email:</span>{" "}
                                <span className="text-primary">
                                  {sos.tutor.email}
                                </span>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Bio Information</span>
                                <div>
                                <p className="text-[#CACAFF]" dangerouslySetInnerHTML={{ __html:sos.tutor.bio }} />
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div>
                                  <span>Phone Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.phone_country_code +
                                      sos.tutor.phone}
                                  </span>
                                </div>
                                <div>
                                  <span>WhatsApp Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.whatsapp_country_code +
                                      sos.tutor.whatsapp_phone}
                                  </span>
                                </div>
                              </div>
                              <hr className="bg-[#28293D] h-1 my-1 w-full" />
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div>Subjects: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.subjects&&sos.tutor.subjects.map((item) => {
                                      return (
                                        // <Col>
                                        <div className="language_item m-1 py-1">
                                          {item.subject.label}
                                        </div>
                                        // </Col>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div className="py-1">Languages: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.languages&&sos.tutor.languages.map((item) => {
                                      return (
                                        <div className="language_item py-1 m-1">
                                          {item.language.language_name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <span className="text-white">
                                    Educations:{" "}
                                  </span>
                                  <br />
                                  <div align="center">
                                    <Row>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          University
                                        </span>
                                      </Col>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          Degree
                                        </span>
                                      </Col>
                                    </Row>
                                    {JSON.parse(sos.tutor.university).map(
                                      (university, index) => {
                                        return (
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {university}
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {
                                                  JSON.parse(sos.tutor.degree)[
                                                    index
                                                  ]
                                                }
                                              </span>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Container className="">
                                <br />
                                <div>
                                  <Row>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Tutor ID
                                      </h5>
                                      <Thumbnail
                                        imageUrl={sos.tutor.id_file_link}
                                        title={"Tutor ID"}
                                      />
                                    </Col>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Grade Transcript
                                      </h5>
                                      <Thumbnail
                                        imageUrl={
                                          sos.tutor.certification_file_link
                                        }
                                        title={"Grade Transcript"}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Container>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer className="bg-secondary2">
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Nickname</label>
                  <input
                    value={sos.tutor && sos.tutor.nickname}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Email</label>
                  <input
                    value={sos.tutor && sos.tutor.email}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className=" mt-2">
                  <PriceSet
                    withButtons={false}
                    title={"Tutor Price"}
                    initialValue={sos.tutor_price}
                    onChange={(price) =>
                      setState({ ...sos, tutor_price: price })
                    }
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="max-sm:py-2">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.subject}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.country}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <DurationSet
                    title={"Duration(minutes)"}
                    initialValue={sos.timer}
                    onChange={(value) => setSos({ ...sos, timer: value })}
                  />
                </div>
                <div className=" max-sm:py-2 flex justify-center">
                  <div>
                    <label htmlFor="description">ID</label>
                    <input
                      value={sos.id}
                      readOnly
                      className="form-control w-20 border-none mt-1"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="max-sm:py-2 w-full mt-3">
                <label htmlFor="description">Question Description</label>
                <textarea
                  style={{ resize: "none" }}
                  readOnly
                  className="form-control border-none mt-1 w-full"
                  rows={5}
                >
                  {sos.description}
                </textarea>
              </div>
              {sos.file_links_student && (
                <div className=" mt-3">
                  <label>Question Upload</label>
                  <OwlCarousel className="owl-theme" {...options}>
                    {sos.file_links_student &&
                      JSON.parse(sos.file_links_student).map((item, index) => {
                        return (
                          <div className="item">
                            <Thumbnail
                              imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`}
                              title={JSON.parse(sos.file_names_student)[index]}
                            />
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              )}
            </div>
          <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden" ></div>
          <div>
            <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
            {sos && <div>
              <div class="iphone md:h-[78vh] md:w-72 md:ml-16">
                  <div class="screen">
                    <div class="screen-content" ref={divRef}>
                      {sos.chat &&
                        sos.chat.messages.map((item) => {
                          return (
                            <div
                              className={
                                item.type === "admin"
                                  ? `direct-chat-msg right`
                                  : `direct-chat-msg`
                              }
                            >
                              <div class="direct-chat-infos clearfix">
                                <span class="direct-chat-name float-left">
                                  {item.sender_type === "tutor" &&
                                    (sos.tutor && sos.tutor.nickname) +
                                      "(Tutor)"}
                                  {item.sender_type === "student" &&
                                    sos.student &&
                                    sos.student.nickname}
                                  {item.sender_type === "admin" &&
                                    "Administrator"}
                                </span>
                              </div>
                              <div>
                                {
                                 (item.message_type === "file" && item.message.split(".").pop()==="m4a")&&
                                  <div className="custom-audio-player w-full">
                                    <ReactAudioPlayer
                                    src={process.env.REACT_APP_MEDIAURL+item.message}
                                    autoPlay={false}
                                    controls
                                  />
                                  </div>
                                }
                              </div>
                              {(item.message.split(".").pop()!="m4a") &&
                              <div
                                className={
                                  (item.sender_type === "tutor" &&
                                    `direct-chat-text bg-light`) ||
                                  (item.sender_type === "student" &&
                                    `direct-chat-text bg-primary`) ||
                                  (item.sender_type === "admin" &&
                                    `direct-chat-text bg-info`)
                                }
                              >
                                {item.message_type === "file" ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_MEDIAURL +
                                      item.message
                                    }
                                    target="_blank"
                                    className="d-flex justify-content-start justify-items-center"
                                  >
                                    <BsFillFileEarmarkFill
                                      className="opacity-60"
                                      color="red"
                                      size={20}
                                    />
                                    {item.message.split("/").pop()}
                                  </a>
                                ) : (
                                  item.message
                                )}
                              </div>
                          }
                              <span className="direct-chat-timestamp float-right">
                                {new Date(item.created_at).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}
                              </span>
                            </div>
                          );
                        })}
                    </div>

                    <div class="fix bg-secondary">
                      <div class="form-row align-items-center">
                        <div class="col">
                          <input
                            type="text"
                            id="msg"
                            class="form-control"
                            placeholder="Type your message"
                            onChange={handleMsg}
                          />
                        </div>
                        <div class="col-auto">
                          <button class="btn text-white" onClick={sendMessage}>
                            <AiOutlineSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>}
          </div>
            </div>
          )}
          {sos.request_status === "completed" && (
            <div className="md:flex gap-10">
            <div>
              <span style={{ fontSize: "30px" }}>S.O.S </span>
              <span className="text-primary">
                {sos.request_status === "new" && <>New Request</>}
                {sos.request_status === "waiting_payment" && (
                  <>Waiting Payment</>
                )}
                {sos.request_status === "ongoing" && <>Ongoing</>}
                {sos.request_status === "completed" && <>Completed</>}
              </span>
              <div className="flex justify-between">
              <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
              <CancelRequestModal cancelFunction={cancel} id={sos.id} />
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className=" max-sm:py-2">
                  <label htmlFor="student_nickname">Student Nickname</label>
                  <input
                    type="text"
                    name="student.nickname"
                    id="student_nickname"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.nickname}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="student_email">Student Email</label>
                  <input
                    type="text"
                    name="student.email"
                    id="student_email"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.student.email}
                    readOnly
                  />
                </div>
                <div className=" max-sm:py-2">
                  <label htmlFor="course_title">Course Title</label>
                  <input
                    type="text"
                    name="label"
                    id="course_title"
                    className="form-control border-none mt-1"
                    // onChange={handleInput}
                    value={sos.label}
                  />
                </div>
                <div className=" max-sm:py-2">
                  <PriceSet
                    withButtons={false}
                    title={"Student Price"}
                    initialValue={sos.student_price}
                    onChange={(value) =>
                      setSos({ ...sos, student_price: value })
                    }
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="mt-2">
                  <label className="font-bold">Tutor</label>
                  <div className="d-flex align-items-center">
                    {sos.tutor && sos.tutor.profile_image != "" ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIAURL}/${
                          sos.tutor && sos.tutor.profile_image
                        }`}
                        className="rounded-full w-8 h-8"
                      />
                    ) : (
                      <BiUserCircle size={30} />
                    )}
                    <a className="ml-1" onClick={handleShow}>
                      View Profile
                    </a>
                  </div>
                  <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    className="text-white"
                  >
                    <Modal.Header className="bg-secondary2">
                      <Modal.Title>Tutor Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-secondary2">
                      <Card className="bg-secondary border-primary">
                        <Card.Body
                          style={{
                            maxHeight: "500px",
                            overflow: "scroll",
                          }}
                        >
                          {sos.tutor && (
                            <div>
                              <div className="md:flex justify-between m-2">
                                <div className="mt-2 flex">
                                  <div className="flex justify-start max-sm:justify-between items-center">
                                    <img
                                      src={`${process.env.REACT_APP_MEDIAURL}/${
                                        sos.tutor && sos.tutor.profile_image
                                      }`}
                                      className="rounded-full w-8 h-8 p-1"
                                    />
                                    <div className="text-primary">
                                      {sos.tutor.full_name}
                                    </div>
                                    <div className="text-white ml-3">
                                      ID:{sos.tutor.id}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Email:</span>{" "}
                                <span className="text-primary">
                                  {sos.tutor.email}
                                </span>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Bio Information</span>
                                <div>
                                <p className="text-[#CACAFF]" dangerouslySetInnerHTML={{ __html:sos.tutor.bio }} />
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div>
                                  <span>Phone Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.phone_country_code +
                                      sos.tutor.phone}
                                  </span>
                                </div>
                                <div>
                                  <span>WhatsApp Number: </span>
                                  <span className="text-primary">
                                    {sos.tutor.whatsapp_country_code +
                                      sos.tutor.whatsapp_phone}
                                  </span>
                                </div>
                              </div>
                              <hr className="bg-[#28293D] h-1 my-1 w-full" />
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div>Subjects: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.subjects&&sos.tutor.subjects.map((item) => {
                                      return (
                                        // <Col>
                                        <div className="language_item m-1 py-1">
                                          {item.subject.label}
                                        </div>
                                        // </Col>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div className="py-1">Languages: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {sos.tutor.languages&&sos.tutor.languages.map((item) => {
                                      return (
                                        <div className="language_item py-1 m-1">
                                          {item.language.language_name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <span className="text-white">
                                    Educations:{" "}
                                  </span>
                                  <br />
                                  <div align="center">
                                    <Row>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          University
                                        </span>
                                      </Col>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          Degree
                                        </span>
                                      </Col>
                                    </Row>
                                    {JSON.parse(sos.tutor.university).map(
                                      (university, index) => {
                                        return (
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {university}
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {
                                                  JSON.parse(sos.tutor.degree)[
                                                    index
                                                  ]
                                                }
                                              </span>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Container className="">
                                <br />
                                <div>
                                  <Row>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Tutor ID
                                      </h5>
                                      <Thumbnail
                                        imageUrl={sos.tutor.id_file_link}
                                        title={"Tutor ID"}
                                      />
                                    </Col>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Grade Transcript
                                      </h5>
                                      <Thumbnail
                                        imageUrl={
                                          sos.tutor.certification_file_link
                                        }
                                        title={"Grade Transcript"}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Container>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer className="bg-secondary2">
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Nickname</label>
                  <input
                    value={sos.tutor && sos.tutor.nickname}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className="mt-2">
                  <label className="font-bold">Tutor Email</label>
                  <input
                    value={sos.tutor && sos.tutor.email}
                    type="text"
                    name=""
                    id=""
                    readOnly
                    className="form-control border-none mt-1"
                  />
                </div>
                <div className=" mt-2">
                  <PriceSet
                    withButtons={false}
                    title={"Tutor Price"}
                    initialValue={sos.tutor_price}
                    onChange={(price) =>
                      setState({ ...sos, tutor_price: price })
                    }
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 gap-6 mt-3">
                <div className="max-sm:py-2">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.subject}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    // onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={sos.country}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <DurationSet
                    title={"Duration(minutes)"}
                    initialValue={sos.timer}
                    onChange={(value) => setSos({ ...sos, timer: value })}
                  />
                </div>
                <div className=" max-sm:py-2 flex justify-center">
                  <div>
                    <label htmlFor="description">ID</label>
                    <input
                      value={sos.id}
                      readOnly
                      className="form-control w-20 border-none mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="max-sm:py-2 w-full mt-3">
                <label htmlFor="description">Question Description</label>
                <textarea
                  style={{ resize: "none" }}
                  readOnly
                  className="form-control border-none mt-1 w-full"
                  rows={5}
                >
                  {sos.description}
                </textarea>
              </div>
              <div className="mt-3">
                <div className="max-sm:py-2">
                  <label htmlFor="label">Rating</label>
                  <div className="grid">
                  <Rating name="size-large" defaultValue={sos.rating} precision={0.5} size="large" readOnly />
                  {/* <Rating readonly initialValue={sos.rating} /> */}
                  </div>
                </div>
              </div>
              <div className="max-sm:py-2 w-full mt-3">
              {sos.file_links_student && (
                <div>
                  <label>Question Upload</label>
                  <OwlCarousel className="owl-theme" {...options}>
                    {sos.file_links_student &&
                      JSON.parse(sos.file_links_student).map((item, index) => {
                        return (
                          <div className="item">
                            <Thumbnail
                              imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`}
                              title={JSON.parse(sos.file_names_student)[index]}
                            />
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              )}
              </div>
              <div className="max-sm:py-2 w-full mt-3">
              <label>Answer</label>
              {sos.answer_files && (
                <div>
                  <OwlCarousel className="owl-theme" {...options}>
                    {sos.file_links_student &&
                      JSON.parse(sos.file_links_student).map((item, index) => {
                        return (
                          <div className="item">
                            <Thumbnail
                              imageUrl={`${process.env.REACT_APP_MEDIAURL}${item}`}
                              title={JSON.parse(sos.file_names_student)[index]}
                            />
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              )}
              {sos.answer_text && (
                <p className="text-[#CACAFF]">{sos.answer_text}</p>
              )}
              </div>
            </div>
          <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden" ></div>
          <div>
            <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
            {sos && <div>
              <div class="iphone md:h-[78vh] md:w-72 md:ml-16">
                  <div class="screen">
                    <div class="screen-content"  ref={divRef}>
                      {sos.chat &&
                        sos.chat.messages.map((item) => {
                          return (
                            <div
                              className={
                                item.type === "admin"
                                  ? `direct-chat-msg right`
                                  : `direct-chat-msg`
                              }
                            >
                              <div class="direct-chat-infos clearfix">
                                <span class="direct-chat-name float-left">
                                  {item.sender_type === "tutor" &&
                                    (sos.tutor && sos.tutor.nickname) +
                                      "(Tutor)"}
                                  {item.sender_type === "student" &&
                                    sos.student &&
                                    sos.student.nickname}
                                  {item.sender_type === "admin" &&
                                    "Administrator"}
                                </span>
                              </div>
                              <div
                                className={
                                  (item.sender_type === "tutor" &&
                                    `direct-chat-text bg-light`) ||
                                  (item.sender_type === "student" &&
                                    `direct-chat-text bg-primary`) ||
                                  (item.sender_type === "admin" &&
                                    `direct-chat-text bg-info`)
                                }
                              >
                                {item.message_type === "file" ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_MEDIAURL +
                                      item.message
                                    }
                                    target="_blank"
                                    className="d-flex justify-content-start justify-items-center"
                                  >
                                    <BsFillFileEarmarkFill
                                      className="opacity-60"
                                      color="red"
                                      size={20}
                                    />
                                    {item.message.split("/").pop()}
                                  </a>
                                ) : (
                                  item.message
                                )}
                              </div>
                              <span className="direct-chat-timestamp float-right">
                                {new Date(item.created_at).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}
                              </span>
                            </div>
                          );
                        })}
                    </div>

                    <div class="fix bg-secondary">
                      <div class="form-row align-items-center">
                        <div class="col">
                          <input
                            type="text"
                            id="msg"
                            class="form-control"
                            placeholder="Type your message"
                            onChange={handleMsg}
                          />
                        </div>
                        <div class="col-auto">
                          <button class="btn text-white" onClick={sendMessage}>
                            <AiOutlineSend />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>}
          </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EditSOS;
