import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/User';

function NotFound({user}) {
    // const {user}=useContext(UserContext);
  return (
    <div className="container1">
         <section className="content">
         <div className="error-page">
           <h2 className="headline text-danger">404</h2>

           <div className="error-content"><br />
             <h2>
               <i className="fas fa-exclamation-triangle text-danger"></i> Oops! Page Not Found.
             </h2>
             {user&&(
             <p>Meanwhile, you may <Link to={`${JSON.parse(user.permissions)[0]}`}>return to index</Link></p>
             )}
           </div>
         </div>
       </section>

    </div>
  );
}

export default NotFound;
