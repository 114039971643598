import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";
import PriceSet from "../components/PriceSet";
function AcceptOnline({acceptFunction,id}) {
    const [show,setShow]=useState(false);
    const [tutorPrice,setTutorPrice]=useState(0);
    const [studentPrice,setStudentPrice]=useState(0);
    async function accept()
    {
        await acceptFunction(tutorPrice,studentPrice,id).then(()=>{
            setShow(false);
        });
    }
  return (
    <div>
      <Button
                    variant="primary"
                    className="flex justify-center items-center w-100"
                    size="lg"
                    onClick={handleShow}
                  >
                    <BsCurrencyDollar /> Set Price
                  </Button>
      <Modal show={show} onHide={()=>setShow(false)} centered className="text-white">
        <Modal.Header className="bg-secondary2">Accept Class</Modal.Header>
        <Modal.Body className="d-flex bg-secondary2">
          <div>
        <PriceSet title={"Tutor Price"} withButtons={false} centered={true} onChange={(value)=>setTutorPrice(value)} />
          </div>
          <div>
        <PriceSet title={"Student Price"} withButtons={false} centered={true} onChange={(value)=>setStudentPrice(value)} />
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={()=>setShow(false)}>Cancel</Button>
            <Button variant="primary" onClick={accept}>Set & Accept</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AcceptOnline;
