import React, { useEffect, useState } from 'react'
import { Dropdown, Tab, Table, Tabs } from 'react-bootstrap'
import AddTermConditionsModal from '../../modals/AddTermConditionsModal'
import Axios from '../../Utils/Axios'
import { SlOptionsVertical } from 'react-icons/sl';
import EditTermConditionsModal from '../../modals/EditTermConditionsModal';
import DeleteTermConditionModal from '../../modals/DeleteTermConditionModal';
import AddNotificationModal from '../../modals/AddNotificationModal';
import EditNotificationModal from '../../modals/EditNotificationModal';

function Notifications() {
    const [notif,setNotif]=useState([]);

    async function getData()
    {
        await Axios.get('notifications').then((response)=>{
            setNotif(response.data.notifications);
        }).catch((error)=>{});
    }
    async function create(state)
    {
        await Axios.post('/notifications/add',state).then((response)=>{
            const newData=[...notif,response.data.notification];
            setNotif(newData);
        }).catch((error)=>{});
    }
    async function update(state,id)
    {
        await Axios.post(`/notifications/update/${id}`,state).then((response)=>{
           getData();
        }).catch((error)=>{});
    }
    async function deletee(id)
    {
        await Axios.delete(`/notifications/${id}`).then((response)=>{
           getData();
        }).catch((error)=>{});
    }
    useEffect(()=>{
        getData();
    },[]);
  return (
    <div className='m-3'>
    <div className='d-flex justify-content-between'>
    <h3>Notifications</h3>
    <div>
        <AddNotificationModal AddFunction={create} />
    </div>
    </div>
    <br />
    <Tabs
      defaultActiveKey="general"
      id="uncontrolled-tab-example"
      className="mb-3"
      style={{ width:"fit-content" }}
    >
      <Tab eventKey="general" title="FireBase Notifications">
        <Table responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Notification</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {notif.filter(item=>item.types==="general").map((item)=>{
                    return <tr>
                        <td>{item.id}</td>
                        <td>{item.title_en}</td>
                        <td><span dangerouslySetInnerHTML={{ __html:item.notification_en }} /></td>
                        <td>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                        <EditNotificationModal UpdateFunction={update} data={item} id={item.id} />
                        <br />
                        <DeleteTermConditionModal DeleteFunction={deletee} id={item.id} />
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
      </Tab>
      <Tab eventKey="local" title=" Local Notifications ">
      <Table responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Details</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {notif.filter((item)=>item.types==="local").map((item)=>{
                    return <tr>
                        <td>{item.id}</td>
                        <td>{item.title_en}</td>
                        <td><span dangerouslySetInnerHTML={{ __html:item.notification_en }} /></td>
                        <td>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                        <EditNotificationModal UpdateFunction={update} data={item} id={item.id} />
                        <br />
                        <DeleteTermConditionModal DeleteFunction={deletee} id={item.id} />
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
      </Tab>
    </Tabs>
    </div>
  )
}

export default Notifications
