import React, { useEffect, useState } from "react";
import { FiCalendar } from "react-icons/fi";
import {
  Tab,
  Tabs,
  Row,
  Button,
  Pagination,
  Table,
  Dropdown,
  Modal,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import Axios from "../../Utils/Axios";
import { BsCalendar4, BsCurrencyDollar } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";
import AcceptOnline from "../../modals/AcceptOnline";
import RejectOnline from "../../modals/RejectOnline";
import ClassRoomStudents from "../../components/ClassRoomStudents";
import { SlOptionsVertical } from "react-icons/sl";
import DeleteRequestModal from "../../modals/DeleteRequestModal";
import EditClassRoom from "../../modals/EditClassRoom";
function Classrooms() {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "short" });
  const year = today.getFullYear();
  const [data, setData] = useState([]);
  const [courses, setCourses] = useState([]);
  async function getClassrooms() {
    await Axios.get("/classrooms")
      .then((response) => {
        setData(response.data.classes);
      })
      .catch((error) => {});
  }
  async function getCourses() {
    await Axios.get("/courses")
      .then((response) => {
        setCourses(response.data.courses);
      })
      .catch((error) => {});
  }
  async function acceptFunction(tutorPrice,studentPrice,id)
  {
    await Axios.post(`/classrooms/accept/${id}`,{tutor_price:tutorPrice,student_price:studentPrice}).then((response)=>{
      getClassrooms();
    }).catch((error)=>{});
  }
  async function rejectFunction(id)
  {
    await Axios.post(`/classrooms/reject/${id}`).then((response)=>{
      getClassrooms();
    }).catch((error)=>{});
  }
  async function updateFunction(state,id)
  {
    await Axios.post(`/class/update/${id}`,state).then((response)=>{
      getClassrooms();
    }).catch((error)=>{})
  }
  async function deleteFunction(id)
  {
    await Axios.delete(`/classrooms/${id}`).then((response)=>{
      getClassrooms();
    }).catch((error)=>{});
  }
  useEffect(() => {
    getClassrooms();
    getCourses();
  }, []);
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2>Classrooms</h2>
        <div>
          <Button variant="outline-light" className="m-1">
            <FiCalendar className="icon" />
            {day + " " + month + ", " + year}
          </Button>
        </div>
      </div>
      <br />
      <Tabs
        style={{ width: "fit-content" }}
        defaultActiveKey="pending"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="pending" title={`Pending Classes`}>
          <div>
            <Row sm={2} lg={2}>
              {data.filter(classe=>classe.status===0).map((classe) => {
                return (
                  <Card className="bg-secondary border-primary">
                    <Card.Body
                      style={{ maxHeight: "400px", overflow: "scroll" }}
                    >
                      <Container className="d-flex justify-content-between p-1">
                        <Row>
                          <Col md={12}>
                            <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Title: <span className="text-primary">{classe.title}</span>
                              </h6>
                            </Container>
                            <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Title: <span className="text-primary">{classe.title}</span>
                              </h6>
                            </Container>
                            <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Created by:{" "}
                              </h6>
                              <h6 className="text-primary">
                                {`${
                                  classe.student && classe.student.nickname
                                } (student)`}
                                {classe.creator_type==="tutor"&& classe.tutor.nickname}
                              </h6>
                            </Container>
                          </Col>
                        </Row>
                        <Row align="right" className="align-items-center">
                        {
                         classe.student&&   <Col md={9}>
                              <span className={classe.tutor? "text-white" : "text-secondary"}>
                              {classe.tutor&&classe.tutor.nickname}
                              </span>
                            </Col>
                        }
                        <Col>
                        <Dropdown drop="start" className="bg-secondary">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical size={"30px"} className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary2 p-1">
                        <EditClassRoom UpdateFunction={updateFunction} id={classe.id} courses={courses} data={classe} />
                        <DeleteRequestModal DeleteFunction={deleteFunction} id={classe.id} />
                    </Dropdown.Menu>
                  </Dropdown>
                        </Col>
                          </Row>

                      </Container>
                      <Container className="">
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Subject:{" "}
                        </h6>
                        <p>{classe.subject}</p>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                        </h6>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Details:{" "}
                        </h6>
                        <div>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Course</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.lecture.course.title}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Lecture</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                <a className="text-white" href={process.env.REACT_APP_MEDIAURL_SECOND+classe.lecture.lecture} target="_blank">{classe.lecture.title}</a>

                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Date & Time</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.date}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Container className="">
                        <Row>
                            <Col md={6}><RejectOnline rejectFunction={rejectFunction} id={classe.id} /></Col>
                          <Col md={6}>
                           <AcceptOnline acceptFunction={acceptFunction} id={classe.id} />
                          </Col>
                        </Row>
                      </Container>
                    </Card.Footer>
                  </Card>
                );
              })}
            </Row>
          </div>
        </Tab>
        <Tab eventKey="accepted" title={`Accepted Classes`}>
        <div>
            <Row sm={2} lg={2}>
              {data.filter(classe=>classe.status===1).map((classe) => {
                return (
                  <Card className="bg-secondary border-primary">
                    <Card.Body
                      style={{ maxHeight: "400px", overflow: "scroll" }}
                    >
                      <Container className="d-flex justify-content-between p-1">
                        <Row>
                          <Col md={12}>
                          <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Title: <span className="text-primary">{classe.title}</span>
                              </h6>
                            </Container>
                            <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Created by:{" "}
                              </h6>
                              <h6 className="text-primary">
                                {`${
                                  classe.student && classe.student.nickname
                                } (student)`}
                                {classe.creator_type==="tutor" && classe.tutor.nickname}
                              </h6>
                            </Container>
                          </Col>
                        </Row>
                            <Row align="right" className="align-items-center">
                        {
                         classe.student&&   <Col md={9}>
                              <span className={classe.tutor? "text-white" : "text-secondary"}>
                              {classe.tutor&&classe.tutor.nickname}
                              </span>
                            </Col>
                        }
                        <Col>
                        <Dropdown drop="start" className="bg-secondary">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical size={"30px"} className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary2 p-1">
                    <EditClassRoom UpdateFunction={updateFunction} id={classe.id} courses={courses} data={classe} />
                        <DeleteRequestModal DeleteFunction={deleteFunction} id={classe.id} />
                    </Dropdown.Menu>
                  </Dropdown>
                        </Col>
                          </Row>
                      </Container>
                      <Container className="">
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Subject:{" "}
                        </h6>
                        <p>{classe.subject}</p>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                        </h6>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Details:{" "}
                        </h6>
                        <div>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Course</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.lecture.course.title}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Lecture</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <a className="text-white" href={process.env.REACT_APP_MEDIAURL_SECOND+classe.lecture.lecture} target="_blank">{classe.lecture.title}</a>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Date & Time</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.date}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Tutor Price</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.tutor_price} $</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Student Price</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.student_price} $</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Container className="">
                      <Row>
                          <Col align="right">
                           <ClassRoomStudents students={classe.students&&classe.students.filter((item)=>item.status==="1")} />
                          </Col>
                        </Row>
                      </Container>
                    </Card.Footer>
                  </Card>
                );
              })}
            </Row>
          </div>
        </Tab>
        <Tab eventKey="rejected" title={`Rejected Classes`}>
        <div>
            <Row sm={2} lg={2}>
              {data.filter(classe=>classe.status===2).map((classe) => {
                return (
                  <Card className="bg-secondary border-primary">
                    <Card.Body
                      style={{ maxHeight: "400px", overflow: "scroll" }}
                    >
                      <Container className="d-flex justify-content-between p-1">
                        <Row>
                          <Col md={12}>
                            <Container className="">
                              <h6 className="text-white font-weight-bold">
                                Created by:{" "}
                              </h6>
                              <h6 className="text-primary">
                                {`${
                                  classe.student && classe.student.nickname
                                } (student)`}
                                {classe.creator_type==="tutor" && classe.tutor.nickname}
                              </h6>
                            </Container>
                          </Col>
                        </Row>
                        <Row align="right" className="align-items-center">
                        {
                         classe.student&&   <Col md={9}>
                              <span className={classe.tutor? "text-white" : "text-secondary"}>
                              {classe.tutor&&classe.tutor.nickname}
                              </span>
                            </Col>
                        }
                        <Col>
                        <Dropdown drop="start" className="bg-secondary">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical size={"30px"} className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary2 p-1">
                    <EditClassRoom UpdateFunction={updateFunction} id={classe.id} courses={courses} data={classe} />
                        <DeleteRequestModal DeleteFunction={deleteFunction} id={classe.id} />
                    </Dropdown.Menu>
                  </Dropdown>
                        </Col>
                          </Row>

                      </Container>
                      <Container className="">
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Subject:{" "}
                        </h6>
                        <p>{classe.subject}</p>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                        </h6>
                      </Container>
                      <Container className="">
                        <h6 className="text-white font-weight-bold">
                          Details:{" "}
                        </h6>
                        <div>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Course</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.lecture.course.title}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Lecture</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#433c5f" }}
                            >
                              <Row>
                                <Col md={6}>
                                <a className="text-white" href={process.env.REACT_APP_MEDIAURL_SECOND+classe.lecture.lecture} target="_blank">{classe.lecture.title}</a>

                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="p-2 mr-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">Date & Time</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="p-2 ml-1"
                              style={{ backgroundColor: "#38384a" }}
                            >
                              <Row>
                                <Col md={6}>
                                  <span className="text-white">{classe.date}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      {/* <Container className="">
                      <Row>
                            <Col md={6}><RejectOnline rejectFunction={rejectFunction} id={classe.id} /></Col>
                          <Col md={6}>
                           <AcceptOnline acceptFunction={acceptFunction} id={classe.id} />
                          </Col>
                        </Row>
                      </Container> */}
                    </Card.Footer>
                  </Card>
                );
              })}
            </Row>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Classrooms;
