import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { UserContext } from "../providers/User";
import { Navigate } from 'react-router-dom'
import Header from "./Header";
import { Outlet } from "react-router-dom";
import {Spinner} from "react-bootstrap";
import NotFound from "../errors/NotFound";
import { useSelector } from "react-redux";
import Store from "../redux/store";
import { LoadUser } from "../redux/actions/UserActions";
function Master() {
  let location = useLocation();
  const user=useSelector((state)=>state.user).user;
  useEffect(()=>{
    Store.dispatch(LoadUser());
    },[]);
   return (
    localStorage.getItem('token') ?
        user ?
        <div className='wrapper'>
        <Header user={user} />
        <div className='content-wrapper w-auto'>
        <section className='content'>
        {user&&!JSON.parse(user.permissions).map((permission) => permission.trim()).includes(location.pathname.trim())?<NotFound user={user} />:<Outlet />}
        </section>
        </div>
        </div>
        :
<div style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      zIndex: 9999
    }}>
      <Spinner animation="border" variant="primary" style={{ width:"5rem",height:"5rem" }} />
    </div>
    :
    <Navigate to="/login" />
  );
}

export default Master;
