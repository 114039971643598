import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
function EditNotificationModal({ UpdateFunction, data, id }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleImage = (e) => {
    const file = e.target.files[0];
    setState((prevState) => ({ ...prevState, image: file }));

    const reader = new FileReader();
    reader.onload = function (event) {
      const output = document.getElementById('img');
      output.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
        UpdateFunction(state,id);
      handleClose();
    } else setValid(true);
  }
  useEffect(() => {
    setState(data);
  }, []);
  return (
    <>
      <Button variant="primary" className="btn btn-primary flex justify-center items-center p-2 my-1 w-100" onClick={handleShow}>
        <FaPen /> Edit Record
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2">
            {state && (
              <>
                <div className="mb-3">
                  <label>Title</label>
                  <input
                  value={state.title_en}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        title_en: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Notification</label>
                  <input
                  value={state.notification_en}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        notification_en: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a text.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Types</label>
                  <select
                  value={state.types}
                  required
                    className="form-control"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        types: e.target.value,
                      }))
                    }
                  >
                    <option value=""></option>
                    <option value="general">Firebase Notification</option>
                    <option value="local">Local Notification</option>
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Please select a type.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Type</label>
                  <select
                  value={state.type}
                  required
                    className="form-control"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        type: e.target.value,
                      }))
                    }
                  >
                    <option value=""></option>
                    <option value="success">Success</option>
                    <option value="error">Error</option>
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Please select a type.
                  </Form.Control.Feedback>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditNotificationModal;
