import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

function DropDownLinks({ title, active, children }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    active && setOpen(true);
    // eslint-disable-next-line
  }, [active]);

  return (
    <div>
      <div
        className={`mb-1 flex align-items-center justify-content-between ${
          open ? "nav-link active text-white" : "nav-link"
        }`}
      >
        <a
          onClick={() => {
            setOpen(!open);
          }}
          className="w-100"
        >
          {title}
        </a>
        <MdKeyboardArrowRight
          color="white"
          id="arrow-icon"
          className={`${open ? "rotate-90" : "rotate-0"}`}
          size={30}
        />
      </div>
      <div className={`text-white ${open ? "block" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
}

export default DropDownLinks;
