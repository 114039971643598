import React, { useEffect, useState } from "react";
import AddUserModal from "../../modals/AddUserModal";
import Axios from "../../Utils/Axios";
import { Button, Dropdown, Table } from "react-bootstrap";
import { SlOptionsVertical } from "react-icons/sl";
import EditUserModal from "../../modals/EditUserModal";
import DeleteOnBoardingTipsModal from "../../modals/DeleteOnBoardingTipsModal";
import { Avatar } from "@mui/material";
function Users() {
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  async function getRoles() {
    await Axios.get("roles")
      .then((response) => {
        setRoles(response.data.roles);
      })
      .catch((error) => {});
  }
  async function getUsers() {
    await Axios.get("users")
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {});
  }
  async function deletee(id) {
    await Axios.delete(`/users/${id}`)
      .then((response) => {
        getUsers();
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getRoles();
    getUsers();
  }, []);
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2>Dashboard Users</h2>
        <AddUserModal AddFunction={getUsers} roles={roles} />
      </div>
      <br />
      <Table striped responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Profile Picture</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <Avatar  sx={{ width: 56, height: 56 }}  src={
                      process.env.REACT_APP_MEDIAURL_SECOND +
                      "" +
                      user.profile_picture
                    }
                    />
                </td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  <button
                    className="w-fit btn text-white"
                    style={{ backgroundColor: user.role.color }}
                  >
                    {user.role.title}
                  </button>
                </td>
                <td>
                  <Dropdown drop="start" className="bg-secondary">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical size={"25px"} className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary p-3">
                      <EditUserModal
                        data={user}
                        id={user.id}
                        roles={roles}
                        UpdateFunction={getUsers}
                      />
                      <br />
                      <DeleteOnBoardingTipsModal
                        id={user.id}
                        DeleteFunction={() => {
                          deletee(user.id);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default Users;
