import axios from "axios";
import { enqueueSnackbar } from 'notistack';
const Axios =axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization":`Bearer ${localStorage.getItem("token")}`
    },
  });

  function handleErrorResponse(error){
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
            enqueueSnackbar(error.response.data.message, { variant: "error",anchorOrigin:{ horizontal: "right", vertical: "top" } });
          break;
        case 401:
        localStorage.removeItem('token');
        window.location.href="/login";
          break;
        default:
          enqueueSnackbar(error.response.data.message, { variant: "error",anchorOrigin:{ horizontal: "right", vertical: "top" } });
          break;
      }
    } else {
      enqueueSnackbar("Internal Server Error", { variant: "error",anchorOrigin:{ horizontal: "right", vertical: "top" } });
    }
    return Promise.reject(error);
  };

  function handleSuccessResponse(response) {
    if (response.status === 201) {
      enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    return response;
  }

  Axios.interceptors.response.use(
    (response) => handleSuccessResponse(response),
    (error) => handleErrorResponse(error)
  );

export default Axios;



