import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsTrashFill } from 'react-icons/bs';
function DeleteTermConditionModal({id,DeleteFunction}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    async function deletefunction()
    {
        DeleteFunction(id);
        handleClose();
    }
  return (
    <>
      <Button variant='danger' className='flex justify-center items-center my-1 p-2  w-100' onClick={handleShow}><BsTrashFill /> Delete Record</Button>

      <Modal show={show} onHide={handleClose} centered className='text-white'>
        <Modal.Header className='bg-secondary2'>
          <Modal.Title>Delete Record</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-secondary2'>
            Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer className='bg-secondary2'>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deletefunction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteTermConditionModal;
