import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
function DeleteMessageModal({ UpdateFunction, data, id }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      UpdateFunction(id);
      handleClose();
    } else setValid(true);
  }

  return (
    <>
      <button
        variant="primary"
        className="text-red-500 flex items-center gap-1"
        onClick={handleShow}
      >
        <FaTrash /> Delete
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title className="text-red-500">Alert!</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2 flex justify-center">
                Are you sure you want to delete this message?
          </Modal.Body>
          <Modal.Footer className="bg-secondary2 ">
            <button type="button" onClick={()=>setShow(false)} className="bg-transparent text-white border border-white rounded-sm py-2 px-3">Cancel</button>
            <button type="button" onClick={update} className="bg-red-500 text-white  rounded-sm py-2 px-3 w-20">Yes</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default DeleteMessageModal;
