import React, { useEffect, useState } from "react";
import AddSocialMediaModal from "../../modals/AddSocialMediaModal";
import Axios from "../../Utils/Axios";
import { Dropdown, Table } from "react-bootstrap";
import { SlOptionsVertical } from "react-icons/sl";
import EditSocialMediaModal from "../../modals/EditSocialMediaModal";
import DeleteSocialMediaModal from "../../modals/DeleteSocialMediaModal";

function SocialMedia() {
  const [social, setSocial] = useState([]);
  async function getData() {
    await Axios.get("social")
      .then((response) => {
        setSocial(response.data.socials);
      })
      .catch((error) => {});
  }
  async function create(data) {
    await Axios.post("/social/add", data)
      .then((response) => {
        const newData = [...social, response.data.social];
        setSocial(newData);
      })
      .catch((error) => {});
  }
  async function update(data,id) {
    await Axios.post(`/social/update/${id}`, data)
      .then((response) => {
        getData();
      })
      .catch((error) => {});
  }
  async function deletee(id)
  {
    await Axios.delete(`/social/${id}`)
    .then((response) => {
      getData();
    })
    .catch((error) => {});
}
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h3>Social Media</h3>
        <div>
          <AddSocialMediaModal AddFunction={create} />
        </div>
      </div>
      <Table responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Icon</th>
            <th>Name</th>
            <th>Link</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {social.map((item) => {
            return (
              <tr>
                <td>{item.id}</td>
                <td>
                  <img
                    src={
                      process.env.REACT_APP_MEDIAURL_SECOND + "" + item.image
                    }
                    width={"100px"}
                    height={"50px"}
                  />
                </td>
                <td>{item.title}</td>
                <td><span className="text-primary">{item.link}</span></td>
                <td>
                <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                            <EditSocialMediaModal UpdateFunction={update} data={item} id={item.id} />
                            <br />
                            <DeleteSocialMediaModal id={item.id} DeleteFunction={deletee} />
                        </Dropdown.Menu>
                        </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default SocialMedia;
