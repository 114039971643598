import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import { CKEditor } from "ckeditor4-react";
import { FaPen } from "react-icons/fa";
import PriceSet from "../components/PriceSet";
function EditCourseModal({ data, id, UpdateFunction }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      UpdateFunction(state, id);
      handleClose();
    } else setValid(true);
  }
  useEffect(() => {
    setState(data);
  }, []);

  return (
    <>
      <Button variant="primary" className="btn btn-primary flex justify-center items-center p-2 my-1 w-100" onClick={handleShow}>
        <FaPen /> Edit Record
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2">
            {state && (
              <>
                <div className="mb-3">
                  <label>Title</label>
                  <input
                    value={state.title}
                    required
                    type="text"
                    name="title"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <textarea
                    value={state.description}
                    rows={10}
                    required
                    type="text"
                    name="description"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the description.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label>Subject</label>
                  <input
                    value={state.subject}
                    required
                    type="text"
                    name="subject"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        subject: e.target.value,
                      }))
                    }
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the subject.
                  </Form.Control.Feedback>
                </div>
                <div className="d-flex">
                  <PriceSet name={"student_price"} title={"Student Price"} initialValue={data.student_price} onChange={(value)=>{setState((prevState) => ({
                        ...prevState,
                        student_price: value,
                      }))}} />
                  <PriceSet name={"tutor_price"} title={"Tutor Price"} initialValue={data.tutor_price} onChange={(value)=>{setState((prevState) => ({
                        ...prevState,
                        tutor_price: value,
                      }))}} />
                  </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditCourseModal;
