import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { UserProvider } from "./providers/User";
import { Provider } from "react-redux";
import Store from "./redux/store";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <SnackbarProvider>
    <Provider store={Store}>
      {/* <UserProvider> */}
    <App />
      {/* </UserProvider> */}
    </Provider>
    </SnackbarProvider>
  // </React.StrictMode>
);
