import React, { useEffect, useState } from 'react'
import { Dropdown, Tab, Table, Tabs } from 'react-bootstrap'
import AddTermConditionsModal from '../../modals/AddTermConditionsModal'
import Axios from '../../Utils/Axios'
import { SlOptionsVertical } from 'react-icons/sl';
import EditTermConditionsModal from '../../modals/EditTermConditionsModal';
import DeleteTermConditionModal from '../../modals/DeleteTermConditionModal';

function TermsConditions() {
    const [terms,setTerms]=useState([]);
    async function getData()
    {
        await Axios.get('terms').then((response)=>{
            setTerms(response.data.terms);
        }).catch((error)=>{});
    }
    async function create(state)
    {
        await Axios.post('/terms/add',state).then((response)=>{
            const newData=[...terms,response.data.term];
            setTerms(newData);
        }).catch((error)=>{});
    }
    async function update(state,id)
    {
        await Axios.post(`/terms/update/${id}`,state).then((response)=>{
           getData();
        }).catch((error)=>{});
    }
    async function deletee(id)
    {
        await Axios.delete(`/terms/${id}`).then((response)=>{
           getData();
        }).catch((error)=>{});
    }
    useEffect(()=>{
        getData();
    },[]);
  return (
    <div className='m-3'>
    <div className='d-flex justify-content-between'>
    <h3>Terms & Conditions</h3>
    <div>
        <AddTermConditionsModal AddFunction={create} />
    </div>
    </div>
    <br />
    <Tabs
      defaultActiveKey="general"
      id="uncontrolled-tab-example"
      className="mb-3"
      style={{ width:"fit-content" }}
    >
      <Tab eventKey="general" title="General Terms">
        <Table responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Details</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {terms.filter((item)=>item.type==="general").map((item)=>{
                    return <tr>
                        <td>{item.id}</td>
                        <td>{item.title}</td>
                        <td><span dangerouslySetInnerHTML={{ __html:item.description }} /></td>
                        <td>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                        <EditTermConditionsModal UpdateFunction={update} data={item} id={item.id} />
                        <br />
                        <DeleteTermConditionModal DeleteFunction={deletee} id={item.id} />
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
      </Tab>
      <Tab eventKey="tutor" title="Tutor Terms">
      <Table responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Details</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {terms.filter((item)=>item.type==="tutor").map((item)=>{
                    return <tr>
                        <td>{item.id}</td>
                        <td>{item.title}</td>
                        <td><span dangerouslySetInnerHTML={{ __html:item.description }} /></td>
                        <td>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                        <EditTermConditionsModal UpdateFunction={update} data={item} id={item.id} />
                        <br />
                        <DeleteTermConditionModal DeleteFunction={deletee} id={item.id} />
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
      </Tab>
    </Tabs>
    </div>
  )
}

export default TermsConditions
