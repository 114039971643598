import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { BsFillImageFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import {
  SiMicrosoftexcel,
  SiMicrosoftpowerpoint,
  SiMicrosoftword,
} from "react-icons/si";
import { VscFilePdf } from "react-icons/vsc";
function Thumbnail({ imageUrl, title }) {
  const extension = imageUrl.split(".").pop().toLowerCase();

  return (
    <div className="rounded-xl">
      <div
        className="embed-responsive embed-responsive-21by9"
        style={{ width: "100%", height: "45px" }}
      >
        {extension === "pdf" ? (
          <iframe type="application/pdf" src={`https://docs.google.com/viewer?url=${imageUrl}`} />
        ) : (
          <></>
        )}
        {extension === "png" || extension === "jpg" || extension === "jpeg" ? (
          <embed src={imageUrl} />
        ) : (
          <></>
        )}
        {extension === "doc" ||
        extension === "docx" ||
        extension === "ppt" ||
        extension === "pptx" ||
        extension === "xls" ||
        extension === "xlsx" ? (
          <embed
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${imageUrl}`}
          />
        ) : (
          <></>
        )}
      </div>
      <Card.Body
        className="bg-secondary2"
        style={{ width: "100%", padding: "3px" }}
      >
        <Card.Text
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              {extension === "ppt" || extension === "pptx" ? (
                <SiMicrosoftpowerpoint style={{ fontSize: "20px" }} />
              ) : null}
              {extension === "doc" || extension === "docx" ? (
                <SiMicrosoftword style={{ fontSize: "20px" }} />
              ) : null}
              {extension === "xls" || extension === "xlsx" ? (
                <SiMicrosoftexcel style={{ fontSize: "20px" }} />
              ) : null}
              {extension === "pdf" ? (
                <VscFilePdf style={{ fontSize: "20px" }} />
              ) : null}
              {extension === "png" ||
              extension === "jpg" ||
              extension === "jpeg" ? (
                <BsFillImageFill style={{ fontSize: "20px" }} />
              ) : null}
            </div>
            <div
              style={{
                maxWidth: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: "13px",
              }}
            >
              <a
                href={
                  extension === "doc" ||
                  extension === "docx" ||
                  extension === "ppt" ||
                  extension === "pptx" ||
                  extension === "xls" ||
                  extension === "xlsx"
                    ? `https://view.officeapps.live.com/op/embed.aspx?src=${imageUrl}`
                    : extension==="pdf"?
                    `https://docs.google.com/viewer?url=${imageUrl}`
                    :
                    imageUrl
                }
                target="_blank"
                className="text-white"
              >
                {title}
              </a>
            </div>
            <a
              className="btn btn-primary btn-sm"
              style={{ width: "fit-content", height: "fit-content" }}
              href={
                extension === "doc" ||
                extension === "docx" ||
                extension === "ppt" ||
                extension === "pptx" ||
                extension === "xls" ||
                extension === "xlsx"
                  ? `https://view.officeapps.live.com/op/embed.aspx?src=${imageUrl}`
                  : imageUrl
              }
            target="_blank">
              <FiDownload />
            </a>
          </div>
        </Card.Text>
      </Card.Body>
    </div>
  );
}

export default Thumbnail;
