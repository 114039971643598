import "./../index.css";

import React, { useState,useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

function TimeComponent({title,initialvalue,onChange}) {
    const[value,setValue]=useState(null);
    useEffect(()=>{
        initialvalue &&(
            setValue(initialvalue)
        )
    },[]);
  const handleInput=()=>{
    var hours=form.hours.value;
    var minutes=form.minutes.value;
    var periods=form.periods.value;
    setValue(`${hours}:${minutes} ${periods}`);
    onChange(`${hours}:${minutes} ${periods}`);
  }

  return (
    <div>
      <div className='text-white m-1 font-bold'>{title}</div>
        <form name="form">
      <div className='flex justify-start items-center'>
      <select
            name='hours'
            className='form-control w-16 m-1 border-none mt-1'
            value={value && value.split(':')[0]}
            onChange={handleInput}
          >
            {Array.from({ length: 12 }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
          <span>:</span>
          <select
            name='minutes'
            className='form-control w-16 m-1 border-none mt-1'
            value={value && value.split(':')[1].split(' ')[0]}
            onChange={handleInput}
          >
            {Array.from({ length: 59 }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
          <div className='radio-toolbar flex justify-center items-center ml-4 border-none mt-1'>
            <input
              type='radio'
              id='radio1'
              name='periods'
              value='AM'
              checked={value && value.toString().includes('AM')}
              onChange={handleInput}
            />
            <label  className='pt-2'  htmlFor='radio1'>AM</label>
            <input
              type='radio'
              id='radio2'
              name='periods'
              value='PM'
              checked={value && value.toString().includes('PM')}
              onChange={handleInput}
            />
            <label className='pt-2' htmlFor='radio2'>PM</label>
          </div>
      </div>
      <div className="flex justify-start gap-8">
        <span className="ml-2">Hours</span>
        <span>Minutes</span>
        <span></span>
      </div>
        </form>
    </div>
  );
}

export default TimeComponent;
