import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap'
import { AiOutlinePlus } from 'react-icons/ai'
import { SlOptionsVertical } from 'react-icons/sl'
import AddOnBoardingTipsModel from '../../modals/AddOnBoardingTipsModel'
import Axios from '../../Utils/Axios'
import EditOnBoardingTipsModal from '../../modals/EditOnBoardingTipsModal'
import DeleteOnBoardingTipsModal from '../../modals/DeleteOnBoardingTipsModal'
import AddNewsUpdatesModal from '../../modals/AddNewsUpdatesModal'
import EditNewsUpdatesModal from '../../modals/EditNewsUpdatesModal'
import DeleteNewsUpdatesModal from '../../modals/DeleteNewsUpdatesModal'

function NewsUpdates() {
    const [news,setNews]=useState([]);
    async function getData()
    {
        await Axios.get('/news').then((response)=>{
            setNews(response.data.news);
        }).catch((error)=>{});
    }
    async function create(data)
    {
        await Axios.post('/news/add',data).then((response)=>{
            const newData = [...news, response.data.new];
            setNews(newData);
        }).catch((error)=>{});
    }
    async function update(state,id)
    {
        await Axios.post(`/news/update/${id}`,state).then((response)=>{
            getData();
        }).catch((error)=>{});
    }
    async function deletee(id)
    {
        await Axios.delete(`/news/${id}`).then((response)=>{
            getData();
        }).catch((error)=>{});
    }
    useEffect(()=>{
        getData();
    },[]);
  return (
    <div className='m-3'>
        <div className='d-flex justify-content-between'>
        <h3>News & Updates</h3>
        <div>
            <AddNewsUpdatesModal AddFunction={create} />
        </div>
        </div>
        <br />
        <Table striped responsive>
            <thead>
            <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Title</th>
                <th>Details</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {news.map((item)=>{
                    return <tr>
                        <td>{item.id}</td>
                        <td><img src={process.env.REACT_APP_MEDIAURL_SECOND+""+item.image} width={"100px"} height={"50px"} /></td>
                        <td>{item.title}</td>
                        <td><div dangerouslySetInnerHTML={{ __html:item.details }} /></td>
                        <td>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                            <EditNewsUpdatesModal data={item} id={item.id} UpdateFunction={update} />
                            <br />
                            <DeleteNewsUpdatesModal id={item.id} DeleteFunction={deletee} />
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                })}
            {/* <tr>
                <td>1</td>
                <td><img width={"200px"} height={"100px"} src="https://via.placeholder.com/150x150" /></td>
                <td>Test</td>
                <td>Test Hello world</td>
                <td>
                <Dropdown drop="start" className="bg-secondary">
                  <Dropdown.Toggle
                    variant="secondary"
                    className="dropdown-actions-button"
                  >
                    <SlOptionsVertical size={"25px"} className="icon" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="bg-secondary p-3">
                    <h4>Test</h4>
                  </Dropdown.Menu>
                </Dropdown>
                </td>
            </tr> */}
            </tbody>
        </Table>
    </div>
  )
}

export default NewsUpdates
