import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import Axios from "../../Utils/Axios";
import TutorRequest from "../../components/TutorRequest";
import axios from "axios";

function Tutors() {
  const [tutors, setTutors] = useState([]);

  async function getTutors() {
    await Axios.get("/tutors")
      .then((response) => {
        setTutors(response.data.tutors);
      })
      .catch((e) => {});
  }

  async function reject(id) {
    await Axios.post(`tutors/reject/${id}`)
      .then((response) => {
        getTutors();
      })
      .catch((e) => {});
  }

  async function accept(id) {
    await Axios.post(`tutors/accept/${id}`)
      .then((response) => {
        getTutors();
      })
      .catch((e) => {});
  }
  async function RestoreAccount(id) {
    await Axios.post(`tutors/restore/${id}`)
      .then((response) => {
        getTutors();
      })
      .catch((e) => {});
  }
  async function update(state,id)
  {
    await Axios.post(`tutors/${id}`,state).then((response)=>{
      getTutors();
    }).catch((error)=>{});
  }
  async function DeleteAccount(id) {
    await Axios.delete(`tutors/${id}`)
      .then((response) => {
        getTutors();
      })
      .catch((e) => {});
  }

  useEffect(() => {
    getTutors();
  }, []);

  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2 className="text-3xl">Tutors List</h2>
      </div>
      <br />
      <Tabs
       style={{ width: "fit-content " }}
       defaultActiveKey="tutors_request"
       id="uncontrolled-tab-example"
       className="mb-3"
      >
        <Tab eventKey="tutors_request" title={`Tutors Request (${tutors.filter(
                (item) => item.verified === 0
              ).length})`}>
        <TutorRequest
        updateFunction={update}
        deleteFunction={DeleteAccount}
        restoreFunction={RestoreAccount}
        rejectFunction={reject}
        acceptFunction={accept}
        data={tutors.filter((item) => item.verified === 0)}
      />
        </Tab>
        <Tab eventKey="active_tutors" title={`Active Tutors (${tutors.filter(
                (item) => item.verified === 1 && item.deleted_at===null).length})`}>
        <TutorRequest
        updateFunction={update}
         deleteFunction={DeleteAccount}
         restoreFunction={RestoreAccount}
        rejectFunction={reject}
        acceptFunction={accept}
        data={tutors.filter((item) => item.verified === 1 && item.deleted_at===null)}
        />
        </Tab>
        <Tab eventKey="deleted_tutors" title={`Deleted Tutors (${tutors.filter(
                (item) => item.verified === 1 && item.deleted_at!=null).length})`}>
        <TutorRequest
        updateFunction={update}
         deleteFunction={DeleteAccount}
         restoreFunction={RestoreAccount}
        rejectFunction={reject}
        acceptFunction={accept}
        data={tutors.filter((item) => item.verified === 1 && item.deleted_at!=null )}
      />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Tutors;
