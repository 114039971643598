import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillEye } from "react-icons/ai";
import { BiShow } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";

function ViewStudentModal({data}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" className="flex justify-center items-center my-1 p-2 w-100" onClick={handleShow}>
        <AiFillEye className="m-1" size={20} />
        View Record
      </Button>

      <Modal show={show} onHide={handleClose} centered >
        <Modal.Body className="bg-secondary2 text-white">
            {data.full_name&&
           <div className="mt-3">
           <label>Full Name:&nbsp;</label>
            <label htmlFor="">{data.full_name}</label>
           </div>
            }
           {data.nickname&&
           <div className="mt-3">
           <label>Nickname:&nbsp;</label>
            <label htmlFor="">{data.nickname}</label>
           </div>
           }
           {data.email&&
           <div className="mt-3">
           <label>Email:&nbsp;</label>
            <label htmlFor="">{data.email}</label>
           </div>
           }
           {data.phone_number!=null&&
           <div className="mt-3">
           <label>Phone number:&nbsp;</label>
            <label htmlFor="">{`${data.country_code} ${data.phone_number}`}</label>
           </div>
           }
           {data.student_level_id&&
           <div className="mt-3">
           <label>Student Level:&nbsp;</label>
            <label htmlFor=""> {data.student_level_id}</label>
           </div>
           }
        </Modal.Body>
           <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>Close</Button>
           </Modal.Footer>
        </Modal>
    </>
  );
}

export default ViewStudentModal;
