import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsFillTrashFill } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";

function DeleteSubjectModal({DeleteFunction,id}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function deleteFunction()
  {
      DeleteFunction(id);
      handleClose();
  }

  return (
    <>
      <Button variant="danger" className="flex justify-center items-center my-1 p-2 bg-red-600 w-100" onClick={handleShow}>
        <BsFillTrashFill className="m-1" />
        Delete Record
      </Button>

      <Modal show={show} onHide={handleClose} centered >
        <Modal.Body className="bg-secondary2">
            <div className="justify-content-center">
            <MdDangerous className="text-danger w-100 display-1"/>
            </div>
        <center>
            <strong><h2 className="text-white font-bold">Are you sure you want delete this record?</h2></strong>
            <br />
            <strong><span className="text-secondary">You will not able to recover this data</span></strong>
            <br />
            <br />
        <Button variant="secondary" className="m-1" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger"  className="m-1" onClick={deleteFunction}>
            Delete
          </Button>
        </center>
        </Modal.Body>
        </Modal>
    </>
  );
}

export default DeleteSubjectModal;
