import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Table } from 'react-bootstrap';
import Axios from '../../Utils/Axios';
import $ from "jquery";
import { BsApple, BsFacebook, BsGoogle } from 'react-icons/bs';
import DeleteFAQModal from '../../modals/DeleteFAQModal';
import { SlOptionsVertical } from 'react-icons/sl';
import ViewStudentModal from '../../modals/ViewStudentModal';

function Students() {
    const [data,setData]=useState([]);
    const tableRef = useRef(null);
    async function getData()
    {
        await Axios.get('/students').then((response)=>{
            setData(response.data.students);
        }).catch((e)=>{});
    }
    async function deletee(id)
    {
        await Axios.delete(`students/${id}`).then((response)=>{
            getData();
        }).catch((error)=>{});
    }
    useEffect(()=>{
        $(document).ready(function() {
            $('.filters input').keyup(function(e) {
                /* Ignore tab key */
                var code = e.keyCode || e.which;
                if (code === '9') return;
                /* Useful DOM data and selectors */
                var $input = $(this),
                    inputContent = $input.val().toLowerCase(),
                    $panel = $input.parents('.filterable'),
                    column = $panel.find('.filters th').index($input.parents('th')),
                    $table = $panel.find('.table'),
                    $rows = $table.find('tbody tr');
                /* Dirtiest filter function ever ;) */
                var $filteredRows = $rows.filter(function() {
                    var value = $(this).find('td').eq(column).text().toLowerCase();
                    return value.indexOf(inputContent) === -1;
                });
                /* Clean previous no-result if exist */
                $table.find('tbody .no-result').remove();
                /* Show all rows, hide filtered ones (never do that outside of a demo ! xD) */
                $rows.show();
                $filteredRows.hide();
                /* Prepend no-result row if all rows are filtered */
                if ($filteredRows.length === $rows.length) {
                    $table.find('tbody').prepend($('<tr class="no-result text-center"><td colspan="' +
                        $table.find('.filters th').length + '">No result found</td></tr>'));
                }
            });
        });
        getData();
    },[]);
  return (
    <>
    <div className='d-flex justify-content-between m-3'>
    <h3 className='text-white'>Students List</h3>
    </div>
    <br />
    <div className="filterable">
    <Table striped responsive id='myTable'  ref={tableRef}>
      <thead>
        <tr>
          <th>ID ({data.length})</th>
          <th>Nickname</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Login Method</th>
          <th></th>
        </tr>
      </thead>
      <tfoot style={{ display:"table-header-group" }} className="filters">
              <th></th>
              <th><input className="form-control" type="search" placeholder="Nickname"/></th>
              <th><input className="form-control" type="search" placeholder="Email" /></th>
              <th><input className="form-control" type="search" placeholder="Phone" /></th>
              {/* <th></th> */}
              <th></th>
              <th></th>
          </tfoot>
      <tbody>
    {data.map((item)=>{
        return <tr>
            <td>{item.id}</td>
            <td>{item.nickname}</td>
            <td>{item.email}</td>
            <td>{`${item.country_code??""} ${item.phone_number??""}`}</td>
            <td>
                <div>
                {item.google_id!==""  && item.google_id &&(
                    <BsGoogle style={{ fontSize:"25px" }} />
                )}
                {item.facebook_id!==""&& item.facebook_id &&(
                    <BsFacebook style={{ fontSize:"25px" }}  />
                )}
                {item.apple_id!==""&& item.apple_id &&(
                    <BsApple style={{ fontSize:"25px" }}  />
                )}
                {(item.google_id===""  || !item.google_id) && (item.facebook_id==="" || !item.facebook_id) && (item.apple_id==="" || !item.apple_id) &&(
                    <img src={require('../../assets/logo-new.jpg')} width={"50px"} />
                )}
                </div>
            </td>
            <td>
            <Dropdown drop="start">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary2 p-1">
                      <ViewStudentModal data={item} />
                      <DeleteFAQModal DeleteFunction={deletee} id={item.id} />
                    </Dropdown.Menu>
                  </Dropdown>
            </td>
        </tr>
    })}
      </tbody>
    </Table>
    </div>
    </>
  )
}

export default Students
