import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlus } from 'react-icons/ai';
import { CKEditor } from 'ckeditor4-react';
import { FaPen } from 'react-icons/fa';
function EditNewsUpdatesModal({data,id,UpdateFunction}) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleImage = (e) => {
    const file = e.target.files[0];
    setState((prevState) => ({ ...prevState, image: file }));

    const reader = new FileReader();
    reader.onload = function (event) {
      const output = document.getElementById('img');
      output.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };




const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setState((prevState) => ({ ...prevState, details: content }));
  };

    async function update(e)
    {
        e.preventDefault();
    if (e.currentTarget.checkValidity()) {
        UpdateFunction(state,id);
        handleClose();
    }
    else setValid(true);
    }
    useEffect(()=>{
        setState(data);
    },[]);

  return (
    <>
      <Button variant='primary' className="btn btn-primary flex justify-center items-center p-2 my-1 w-100" onClick={handleShow}><FaPen /> Edit Record</Button>

      <Modal show={show} onHide={handleClose} centered size='lg' className='text-white'>
        <Modal.Header className='bg-secondary2'>
          <Modal.Title>Edit Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id='frm2' onSubmit={update}>
        <Modal.Body className='bg-secondary2'>
        {state&&(
            <>
            <div className="mb-3">
        <label>Image</label>
        <br />
        <img id="img" src={process.env.REACT_APP_MEDIAURL_SECOND+""+state.image} width={"50%"} />
                <input
                  type="file"
                  name="image"
                  onChange={handleImage}
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose an image.
                </Form.Control.Feedback>
        </div>
        <div className="mb-3">
        <label>Title</label>
                <input
                  value={state.title}
                  required
                  type="text"
                  name="title"
                  onChange={(e)=>setState((prevState) => ({...prevState,title:e.target.value}))}
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a title.
                </Form.Control.Feedback>
        </div>
        <div className="mb-3">
        <label>Details</label>
        <CKEditor initData={state.details} required onChange={handleEditorChange} />
        <Form.Control.Feedback type="invalid">
                  Please enter the details.
                </Form.Control.Feedback>
        </div>
            </>
        )}

        </Modal.Body>
        <Modal.Footer className='bg-secondary2'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type='submit' variant="primary">
            Save
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditNewsUpdatesModal;
