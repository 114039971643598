import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import Switch from '@mui/material/Switch';
function AddRolesModal({ AddFunction }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState({
    title:'',
    permissions:[],
    color:''
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function addNew(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
          AddFunction(state);
          setState({
            title:'',
            permissions:[],
            color:''
          });
      handleClose();
    } else setValid(true);
  }


  const handlePermissionToggle = (value) => {
    setState((prevState) => {
      let updatedPermissions;

      if (prevState.permissions.includes(value.target.value)) {
        updatedPermissions = prevState.permissions.filter(
          (permission) => permission !== value.target.value
        );
      } else {
        updatedPermissions = [...prevState.permissions, value.target.value];
      }

      return {
        ...prevState,
        permissions: updatedPermissions
      };
    });
  };



  return (
    <>
      <Button variant="primary" className="flex items-center" onClick={handleShow}>
        <AiOutlinePlus /> Add Role
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={addNew}>
          <Modal.Body className="bg-secondary2">
            <div className="mb-3">
              <label>Title</label>
              <input
                required
                type="text"
                name="title"
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }))
                }
                className="form-control"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a title.
              </Form.Control.Feedback>
            </div>
            <div className="mb-3">
              <label>Permissions</label>
              <Row>


                <Col>
                <Row className="justify-content-between">
                <Col>Dashboard</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/dashboard"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Exam Help</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/exam-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>H.W Help</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/homework-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Project Help</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/project-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Thesis Writing Help</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/thesiswriting-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Online Tutoring</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/onlinetutoring-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>S.O.S</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/sos-help"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Classrooms</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/classrooms"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Courses</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/courses"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Lectures</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/lectures"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Onboarding Tips</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/onboarding-tips"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>FAQs</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/faqs"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Edit Requests</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/request/edit"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Delete Requests</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/request/delete"} /></Col>
              </Row>
                </Col>



                <Col>
                <Row className="justify-content-between">
                <Col>Social Media</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/social-media"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>News & Update</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/news-updates"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Terms & Conditions</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/terms-conditions"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Notifications</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/notifications"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Subjects List</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/subjects"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Students List</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/students"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Tutors List</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/tutors"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Dashboard Users</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/users"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Roles</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/roles"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Customer Support</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/customer-support"} /></Col>
              </Row>
              <Row className="justify-content-between">
                <Col>Prices</Col>
                <Col align="right"><Switch onChange={handlePermissionToggle} color="secondary" value={"/prices"} /></Col>
              </Row>
                </Col>
              </Row>
<br />


              <Form.Control.Feedback type="invalid">
                Please select permissions.
              </Form.Control.Feedback>
            </div>
            <div className="mb-3">
              <label>Color</label>
              <input type="color" className="form-control" onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    color: e.target.value,
                  }))
                } />
              </div>
          </Modal.Body>
          <Modal.Footer className="bg-secondary2">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddRolesModal;
