import React, { useState, useEffect, useContext } from "react";
import {
  Tab,
  Tabs,
  Row,
  Button,
  Pagination,
  Table,
  Dropdown,
  Col,
  Modal
} from "react-bootstrap";
import { AiFillFileImage, AiFillFilePdf, AiOutlinePlus } from "react-icons/ai";
import { FiCalendar } from "react-icons/fi";
import Axios from "../../Utils/Axios";
import Requests from "../../components/Requests";
import { BsCardText, BsCurrencyDollar, BsTable } from "react-icons/bs";
import PriceSet from "../../components/PriceSet";
import { Link, useLocation } from "react-router-dom";
import DeleteRequestModal from "../../modals/DeleteRequestModal";
import { FaPen } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftpowerpoint, SiMicrosoftword } from "react-icons/si";
import { SlOptionsVertical } from "react-icons/sl";
import { UserContext } from "../../providers/User";
import CountDown from "../../components/CountDown";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import messaging from "../../Firebase";
import { enqueueSnackbar } from "notistack";

function SOSHelp() {
  let location=useLocation();
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "short" });
  const year = today.getFullYear();
  const user=useSelector((state)=>state.user).user;
  // const { user } = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [table, setTable] = useState(false);
  async function getRequests() {
    await Axios.get("/sos")
      .then((response) => {
        setRequests(response.data.requests);
        setDeleted(response.data.deleted_requests);
        setCanceled(response.data.canceled_requests);
        // setRequests(response.data.requests.filter((item)=>item.assistance_type==="project"));
      })
      .catch((e) => {});
  }
  async function setPrice()
  {
    getRequests();
  }
async function deleteFunction(id)
{
 getRequests();
}

  useEffect(() => {
    getRequests();
    onMessage(messaging, (payload) => {
      enqueueSnackbar(payload.notification.title, { variant: "info", anchorOrigin: { horizontal: "right", vertical: "top" } });

      getRequests();
      });
  }, []);
  const [show, setShow] = useState(false);
  const [priceStudentValue, setStudentPriceValue] = useState(0);
  const [priceTutorValue, setTutorPriceValue] = useState(0);
  async function setPricee(id) {
    (location.pathname==="/exam-help" || location.pathname==="/homework-help" || location.pathname==="/project-help" || location.pathname==="/thesiswriting-help")&&
    await Axios.post(`/requests/${id}/update-prices`,{ student_price: priceStudentValue, tutor_price: priceTutorValue }).then((response)=>{
      setShow(false);
      getRequests();
    }).catch((error)=>{});
    location.pathname==="/onlinetutoring-help"&&
    await Axios.post(`/online/${id}/update-prices`,{ student_price: priceStudentValue, tutor_price: priceTutorValue }).then((response)=>{
      setShow(false);
      getRequests();
    }).catch((error)=>{});
}
const handleStudentPriceChange = (newPrice) => {
  setStudentPriceValue(newPrice);
};
const handleTutorPriceChange = (newPrice) => {
  setTutorPriceValue(newPrice);
};
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2 className="text-3xl">S.O.S</h2>
        <div>
          <Button variant="outline-light flex" className="m-1">
            <FiCalendar className="icon" />
            {day + " " + month + ", " + year}
          </Button>
        </div>
      </div>
      <br />
      <div align="right">
      <Button onClick={()=>{setTable(!table);}} variant="primary" className="flex justify-center items-center">{table?<><BsCardText />&nbsp;Card View</>:<><BsTable />&nbsp;Table View</>}</Button>
      </div>
      <Tabs
        style={{ width:"fit-content" }}
        defaultActiveKey="new_request"
        id="uncontrolled-tab-example"
        className="mb-3"
        // fill
        // justify
      >
        <Tab eventKey="new_request" title={`New Requests(${requests.filter(
                (item) => item.request_status==="new"
              ).length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="new"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"sos",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"new"} setpriceFunction={setPrice} deletFunction={deleteFunction} tbl={"sos"} requests={requests.filter(
      (item) => item.request_status==="new"
    )} />
        }
        </Tab>
        {/* <Tab eventKey="pending_accept" title="Pending Acceptance">
          <Requests type={"pending"} setpriceFunction={setPrice} deletFunction={deleteFunction}  requests={requests.filter(
      (item) => item.request_status==="pending"
    )} />
        </Tab> */}
        <Tab eventKey="waiting_payment" title={`Waiting Payment(${requests.filter(
                (item) => item.request_status==="waiting_payment"
              ).length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start In</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="waiting_payment"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><CountDown targetDate={item.time.toString().replace(" ","T")} /></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"sos",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"waiting"} deletFunction={deleteFunction} tbl={"sos"}   requests={requests.filter(
      (item) => item.request_status==="waiting_payment"
    )} />
        }
        </Tab>
        <Tab eventKey="ongoing" title={`Ongoing(${requests.filter(
                (item) => item.request_status==="ongoing"
              ).length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="ongoing"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"sos",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"ongoing"} deletFunction={deleteFunction} tbl={"sos"}   requests={requests.filter(
      (item) => item.request_status==="ongoing"
    )} />}
        </Tab>
        <Tab eventKey="complete" title={`Complete(${requests.filter(
                (item) => item.request_status==="completed"
              ).length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="completed"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"sos",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"completed"} deletFunction={deleteFunction} tbl={"sos"}   requests={requests.filter(
      (item) => item.request_status==="completed"
    )} />
        }
        </Tab>
        <Tab eventKey="deleted" title={`Deleted(${deleted.length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              {/* <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th> */}
            </tr>
          </thead>
          <tbody>
            {
             deleted.map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  {/* <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"sos",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"new"} deletFunction={deleteFunction} tbl={"sos"} requests={deleted} />
        }
        </Tab>
        <Tab eventKey="canceled" title={`Canceled(${canceled.length})`}>
        {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Question</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              {/* <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th> */}
            </tr>
          </thead>
          <tbody>
            {
             canceled.map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.description}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
        <Requests type={"new"} deletFunction={deleteFunction} tbl={"sos"} requests={canceled} />
        }
        </Tab>
      </Tabs>
    </div>
  );
}

export default SOSHelp;
