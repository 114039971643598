import React, { useEffect, useState } from "react";
import AddRolesModal from "../../modals/AddRolesModal";
import Axios from "../../Utils/Axios";
import { Dropdown, Table } from "react-bootstrap";
import { SlOptionsVertical } from "react-icons/sl";
import EditRolesModal from "../../modals/EditRoleModal";
import DeleteFAQModal from "../../modals/DeleteFAQModal";

function Roles() {
  const [role, setRole] = useState([]);
  async function getData() {
    await Axios.get("roles")
      .then((response) => {
        setRole(response.data.roles);
      })
      .catch((error) => {});
  }
  async function create(state) {
    await Axios.post("roles/add", state)
      .then((response) => {
        getData();
      })
      .catch((error) => {});
  }
async function update(state,id) {
  await Axios.post(`roles/update/${id}`, state)
    .then((response) => {
        getData();
    })
    .catch((error) => {});
}
async function deletee(id) {
    await Axios.delete(`roles/${id}`)
      .then((response) => {
          getData();
      })
      .catch((error) => {});
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2>Roles</h2>
        <AddRolesModal AddFunction={create} />
      </div>
      <br />
      <Table striped responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Create At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {role.map((item) => {
            return (
              <tr>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{new Date(item.created_at).toLocaleDateString("en-GB")}</td>
                <td>
                  <Dropdown drop="start">
                    <Dropdown.Toggle
                      variant="secondary"
                      className="dropdown-actions-button"
                    >
                      <SlOptionsVertical className="icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-secondary2 p-1">
                        <EditRolesModal data={item} id={item.id} UpdateFunction={update} />

                      <DeleteFAQModal DeleteFunction={deletee} id={item.id} />
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default Roles;
