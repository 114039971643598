import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsPhoneVibrate } from "react-icons/bs";
import { MdLiveTv } from "react-icons/md";
import { GoBook } from "react-icons/go";
import { FaTasks } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import Axios from "../../Utils/Axios";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker, tr } from "@faker-js/faker";
import { Pie } from "react-chartjs-2";
import Barr from "../screens/bar";
function Index() {
  const [req, setReq] = useState(null);
  const [months, setMonths] = useState([]);
  async function getAll() {
    await Axios.get("/requests/all").then((response) => {
      setReq(response.data);
      setMonths(response.data.months);
    });
  }
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    ArcElement,
    Legend
  );
  const data = {
    labels: ["Tutors", "Students"],
    datasets: [
      {
        // label: '# of Votes',
        data: [req ? req.tutorscount : 0, req ? req.stdcount : 0],
        backgroundColor: ["rgba(0, 115, 255,1)", "rgba(139, 14, 228,1)"],
        borderColor: ["rgba(0, 115, 255,1)", "rgba(139, 14, 228,1)"],
        borderWidth: 1,
      },
    ],
  };

  const data1 = {
    labels: months,
    datasets: [
      {
        cubicInterpolationMode: "monotone",
        tension: 0,
        fill: true,
        label: "Total Income In Dollars ",
        data: req && req.costs,
        borderColor: "rgba(139, 14, 228,1)",
        backgroundColor: "rgba(139, 14, 228,1)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom", //
        labels: {
          usePointStyle: true,
        },
      },
    },
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Total Income In Dollars",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
  };

  useEffect(() => {
    getAll();
  }, []);
  return (
    <div className="m-3">
      <h2 className="text-white text-3xl">Dashboard</h2>
      <h6 className="text-white mt-2 mb-4">Requests of the day</h6>
      <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-1">
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
              <FaTasks className="icons display-5 blue-shadow mr-3" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">Exam Help</span>
                <br></br>
                <span className="text-white">{req && req.exams}</span>
              </div>
            </div>
          </div>
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
            <GoBook className="icons display-5 mr-3 yellow-shadow" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">H.W Help</span>
                <br></br>
                <span className="text-white">{req && req.homeworks}</span>
              </div>
            </div>
          </div>
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
              <FaLaptopCode className="icons display-5 mr-3 purple-shadow" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">Projects</span>
                <br></br>
                <span className="text-white">
                  {req && req.projects}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
              <MdLiveTv className="icons display-5 mr-3 green-shadow" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">Online Tutoring</span>
                <br></br>
                <span className="text-white">{req && req.online}</span>
              </div>
            </div>
          </div>
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
              <AiOutlineFileSearch className="icons mr-3 display-5 orange-shadow" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">Thesis Writing</span>
                <br></br>
                <span className="text-white">{req && req.thesis}</span>
              </div>
            </div>
          </div>
          <div className="bg-secondary m-1 p-3 border-radius-3">
            <div className="flex justify-start items-center">
              <BsPhoneVibrate className="icons mr-3 display-5 red-shadow" />
              <div style={{ fontSize:"12px" }}>
                <span className="text-white">SOS Requests</span>
                <br></br>
                <span className="text-white">{req && req.sos}</span>
              </div>
            </div>
          </div>
      </div>
      <br />
      <Row>
        <Col md={8}>
          <div className="p-1 bg-secondary border-radius-3">
            <Line options={options1} data={data1} />
          </div>
        </Col>
        <Col md={4}>
          <div className="p-1 bg-secondary border-radius-3">
            <div className="p-3">
              <span className="text-white">Total Active Users</span>
              <h2>{req && req.tutorscount + req.stdcount} Users</h2>
            </div>
            <center>
              <div className="pie-chart">
                <Pie data={data} options={options} />
              </div>
            </center>
          </div>
        </Col>
      </Row>
      <br />
      <h2>Tutors Top 5</h2>
      <Row>
        <Col md={6}>
          <div className="bg-white">
            <Table striped className="text-white" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tutors</th>
                  <th>Requests Answered</th>
                  <th>Total Profits</th>
                  <th>Favorite Request Type</th>
                </tr>
              </thead>
              <tbody>
                {req &&
                  req.toptutors.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.tutor.nickname}</td>
                        <td>{item.total}</td>
                        <td>{item.cost}</td>
                        <td>{item.most}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={6}>
          <Barr years={req && req.years} values={req && req.annual} />
        </Col>
      </Row>
    </div>
  );
}

export default Index;
