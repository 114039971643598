import React, { useEffect, useRef, useState } from "react";
import { MdAccountCircle, MdSend } from "react-icons/md";
import Axios from "../../Utils/Axios";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import messaging from "../../Firebase";
import { closeSnackbar, useSnackbar } from "notistack";
import Spinner from "react-bootstrap/Spinner";
import ChatFile from "../../components/ChatFile";
import { Dropdown } from "react-bootstrap";
import { IoIosCall } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import StudentSendMailModal from "../../modals/StudentSendMailModal";
import EditMessageModal from "../../modals/EditMessage";
import DeleteMessageModal from "../../modals/DeleteMessageModal";
function CustomerSupport() {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user).user;
  const messagesContainerRef = useRef(null);
  const messageInputRef = useRef(null);
  const [chat, setChats] = useState([]);
  const [room, setRoom] = useState(null);
  const [loadingsend, setLoadingsend] = useState(false);
  const [loadingmsg, setLoadingmsg] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [student, setStudent] = useState(null);
  async function getChat() {
    await Axios.get("customer-support")
      .then((response) => {
        setChats(response.data.rooms);
      })
      .catch((error) => {});
  }
  async function getMessages(room, withLoading) {
    withLoading && setLoadingmsg(true);
    setRoom(room);
    await Axios.get(`get-messages/${room}`).then((response) => {
      setStudent(response.data.msg.student);
      setMessages(response.data.msg.messages);
      withLoading && setLoadingmsg(false);
      getChat();
    });
  }

  async function sendMessages() {
    if (message === "") return;
    setLoadingsend(true);
    await Axios.post(`send-messages`, {
      sender_id: user.id,
      room_id: room,
      message: message,
    }).then((response) => {
      getChat();
      getMessages(room, false);
      messageInputRef.current.value = "";
      setLoadingsend(false);
    });
  }

  async function editMessages(id,message) {
    if (message === "") return;
    setLoadingsend(true);
    await Axios.post(`edit-messages/${id}`, {
      sender_id: user.id,
      room_id: room,
      message: message,
    }).then((response) => {
      getChat();
      getMessages(room, false);
      messageInputRef.current.value = "";
      setLoadingsend(false);
    });
  }
  async function deleteMessages(id) {
    setLoadingsend(true);
    await Axios.post(`delete-messages/${id}`, {
      room_id: room,
    }).then((response) => {
      getChat();
      getMessages(room, false);
      messageInputRef.current.value = "";
      setLoadingsend(false);
    });
  }
  useEffect(() => {
    getChat();
    onMessage(messaging, (payload) => {
      getChat();
      if (room === parseInt(payload.data.room_id)) getMessages(room, false);
      else
        enqueueSnackbar(
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              getMessages(payload.data.room_id, true);
              closeSnackbar();
            }}
          >
            {payload.notification.title}
            <br />
            {payload.notification.body}
          </div>,
          {
            variant: "info",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          }
        );
    });
    // eslint-disable-next-line
  }, [room]);
  async function sendMail(input) {
    await Axios.post("/send/mail", input);
  }
  useEffect(() => {
    if (messagesContainerRef.current) {
      // Scroll to the bottom of the messages container
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);
  function formatDate(date) {
    const today = new Date();
    const messageDate = new Date(date);
    const options = { hour: "numeric", minute: "2-digit" };

    if (
      today.getDate() === messageDate.getDate() &&
      today.getMonth() === messageDate.getMonth() &&
      today.getFullYear() === messageDate.getFullYear()
    ) {
      // Message was sent today
      return messageDate.toLocaleTimeString("en-US", options);
    } else {
      // Message was sent on a different day
      return messageDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }
  }
  return (
    <div>
      <div className="text-3xl m-3">Customer Support</div>
      <div className="rounded-md border-2 h-[75vh] border-[#8b0ee4] md:flex">
        <div className="rounded-md border-2 border-[#8b0ee4] xl:w-[30%] 2xl:w-3/12 h-[75vh] overflow-scroll">
          {chat.map((item) =>
            item.student ? (
              <div
                key={item.id}
                className={`rounded-sm m-1 border-[#8b0ee4] border-2 p-3 flex justify-between cursor-pointer items-center ${
                  parseInt(room) === parseInt(item.id) && "bg-secondary"
                }`}
                onClick={() => getMessages(item.id, true)}
              >
                <div className="flex items-center gap-2">
                  <MdAccountCircle size={30} />
                  <div className="w-fit">
                    <div className="flex justify-between w-52 2xl:w-52">
                      {item.student.nickname}
                      {item.unread_messages > 0 && (
                        <div className="rounded-full bg-[#8b0ee4] h-5 w-5 flex items-center justify-center text-xs">
                          {item.unread_messages}
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between items-end w-56">
                      <div className="text-sm text-gray-500">
                        {item.latest_message &&
                        item.latest_message.message_type === "text"
                          ? item.latest_message.message &&
                            item.latest_message.message.length > 20
                            ? `${item.latest_message.message.slice(0, 20)}...`
                            : item.latest_message.message
                          : "File"}
                      </div>
                      {item.latest_message && (
                        <span className="text-sm">
                          {formatDate(item.latest_message.created_at)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={item.id}
                className="rounded-sm m-1 border-[#8b0ee4] border-2 p-3 flex gap-2 items-center cursor-pointer"
                onClick={() => getMessages(item.id, true)}
              >
              </div>
            )
          )}
        </div>
        <div className="w-9/12">
          {loadingmsg ? (
            <div className="flex justify-center items-center h-[75vh] w-[60vw]">
              <Spinner />
            </div>
          ) : (
            messages.length > 0 && (
              <div>
                <div className="bg-secondary rounded-tr-md px-3 h-12 z-50 flex justify-between items-center">
                  <div className="flex gap-1 items-center">
                    <MdAccountCircle size={30} className="ml-3" />
                    <span>{student.nickname}</span>
                  </div>
                  <div className="flex gap-1 items-center">
                    <IoIosCall size={30} className="ml-3" />
                    <span>{`${student.country_code??""} ${student.phone_number??""}`}</span>
                  </div>
                  <StudentSendMailModal
                    email={student.email}
                    sendMailFunction={sendMail}
                  />
                </div>
                <div
                  className="lg:h-[60vh] overflow-scroll"
                  ref={messagesContainerRef}
                >
                  {messages.map((message) => {
                    return (
                      <div>
                        <div className="">
                          {message.user_type === "admin" ? (
                            <div className="flex justify-end items-center">
                              <div className="flex justify-between w-32 mr-1">
                              <EditMessageModal UpdateFunction={editMessages} data={message} id={message.id} />
                              <DeleteMessageModal UpdateFunction={deleteMessages} data={message} id={message.id} />
                              </div>
                              <div className="direct-chat-msg right w-fit">
                                <div className="direct-chat-text mr-1 whitespace-pre-line max-w-xs break-words">
                                  {message.message_type === "text" ? (
                                    message.message
                                  ) : (
                                    <ChatFile file={message.message} />
                                  )}
                                </div>
                                <span class="direct-chat-timestamp float-right">
                                  {new Date(message.created_at).toLocaleString(
                                    "En-en",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                      hour: "numeric",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="direct-chat-msg left w-fit">
                                <div className="direct-chat-text ml-1 whitespace-pre-line max-w-xs break-words">
                                  {message.message_type === "text" ? (
                                    message.message
                                  ) : (
                                    <ChatFile file={message.message} />
                                  )}
                                </div>
                                <span class="direct-chat-timestamp float-right">
                                  {new Date(message.created_at).toLocaleString(
                                    "En-en",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                      hour: "numeric",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex items-center justify-center gap-1">
                  <textarea
                    rows={1}
                    style={{ resize: "none" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        sendMessages();
                      }
                    }}
                    ref={messageInputRef}
                    onChange={(e) => setMessage(e.target.value, false)}
                    type="text"
                    autoFocus
                    placeholder="Enter message here..."
                    className="h-[6vh] w-[60vw] m-1 rounded-md p-1 border-none outline-none text-[#8b0ee4]"
                  />
                  {loadingsend ? (
                    <Spinner />
                  ) : (
                    <button onClick={sendMessages}>
                      <MdSend size={30} />
                    </button>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerSupport;
