import { useEffect, useState } from "react";
import Thumbnail from "../components/Thumbnail";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Avatar } from "@mui/material";
function TutorInfoModal({ data }) {
  const [show, setShow] = useState(false);
  const [state, setState] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setState(data);
  }, []);

  return (
    <>
    <span className="text-primary" onClick={handleShow}>{state&&state.nickname}</span>

      <Modal
                            show={show}
                            size="lg"
                            onHide={handleClose}
                            className="text-white"
                          >
                            <Modal.Header className="bg-secondary2">
                              <Modal.Title>Tutor Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-secondary2">
                              <Card className="bg-secondary border-primary">
                                <Card.Body
                                  style={{
                                    maxHeight: "500px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {state && (
                                    <>
                                      <Container className="d-flex justify-content-between p-1">
                                        <Row className="justify-content-start align-items-center">
                                          <Col md={1}>
                                            <Avatar
                                              src={`${
                                                process.env.REACT_APP_MEDIAURL
                                              }/${
                                                state &&
                                                state.profile_image
                                              }`}
                                            />
                                          </Col>
                                          <Col md={3}>
                                            <h5 className="text-white">
                                              {state &&
                                                state.full_name}{" "}
                                            </h5>
                                          </Col>
                                        </Row>
                                      </Container>
                                      <Container className="p-1">
                                        <span className="text-white">
                                          Email:{" "}
                                        </span>
                                        <span className="text-primary">
                                          {state && state.email}
                                        </span>
                                      </Container>
                                      <Container className="p-1">
                                        <span className="text-white font-weight-bold">
                                          Bio Information:{" "}
                                        </span>
                                        <p>{state && state.bio}</p>
                                      </Container>
                                      <Container className="p-1">
                                        <Row>
                                          <Col md={6}>
                                            <span className="text-white">
                                              Phone Number:{" "}
                                            </span>
                                            <span className="text-primary">
                                              {state && state.phone}
                                            </span>
                                          </Col>
                                          <Col md={6}>
                                            <span className="text-white">
                                              WhatsApp Number:{" "}
                                            </span>
                                            <span className="text-primary">
                                              {state &&
                                                state.whatsapp_phone}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Container>
                                      <Container className="p-1 pb-2 pt-2">
                                        <span className="text-white font-weight-bold">
                                          Subjects:{" "}
                                        </span>
                                        <br />
                                        {/* <Row> */}
                                        {state &&
                                          state.subjects &&
                                          state.subjects.map((item) => {
                                            return (
                                              // <Col>
                                              <span className="language_item">
                                                {item.subject.label}
                                              </span>
                                              // </Col>
                                            );
                                          })}
                                        {/* </Row> */}
                                      </Container>
                                      <Container className="p-1 pb-2 pt-2">
                                        <span className="text-white font-weight-bold">
                                          Languages:{" "}
                                        </span>
                                        <br />
                                        <Row>
                                          {state &&
                                            state.languages &&
                                            state.languages.map(
                                              (item) => {
                                                return (
                                                  <Col>
                                                    <span className="language_item">
                                                      {
                                                        item.language
                                                          .language_name
                                                      }
                                                    </span>
                                                  </Col>
                                                );
                                              }
                                            )}
                                        </Row>
                                      </Container>
                                      <Container className="p-1 pb-2 pt-2">
                                        <span className="text-white font-weight-bold">
                                          Educations:{" "}
                                        </span>
                                        <br />
                                        <div align="center">
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#433c5f",
                                              }}
                                            >
                                              <span className="text-white">
                                                University
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#433c5f",
                                              }}
                                            >
                                              <span className="text-white">
                                                Degree
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {state &&
                                                  state.university}
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {state &&
                                                  state.degree}
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Container>
                                      <Container className="p-1 pb-2 pt-2">
                                        <br />
                                        <div>
                                          <Row className="justify-content-around">
                                            <Col className="p-2" md={3}>
                                              <h5 className="text-white font-weight-normal">
                                                Tutor ID
                                              </h5>
                                              <Thumbnail
                                                imageUrl={
                                                  state &&
                                                  state.id_file_link
                                                }
                                                title={"Tutor ID"}
                                              />
                                            </Col>
                                            <Col className="p-2" md={3}>
                                              <h5 className="text-white font-weight-normal">
                                                Grade Transcript
                                              </h5>
                                              <Thumbnail
                                                imageUrl={
                                                  state &&
                                                  state
                                                    .certification_file_link
                                                }
                                                title={"Grade Transcript"}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </Container>
                                    </>
                                  )}
                                </Card.Body>
                              </Card>
                            </Modal.Body>
                            <Modal.Footer className="bg-secondary2">
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
    </>
  );
}

export default TutorInfoModal;
