import React, { startTransition, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaPen } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import Spinner from "react-bootstrap/Spinner";
function EditMessageModal({ UpdateFunction, data, id }) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);
  const messageInputRef1 = useRef(null);
  const [message, setMessage] = useState("");
  const [loadingsend, setLoadingsend] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setState((prevState) => ({ ...prevState, description: content }));
  };

  async function update(e) {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      UpdateFunction(id, message);
      handleClose();
    } else setValid(true);
  }

  useEffect(() => {
    setState(data);
    setMessage(data.message);
  }, [data]);

  return (
    <>
      <button
        variant="primary"
        className="text-orange-500 flex items-center gap-1"
        onClick={handleShow}
      >
        <FaPen /> Edit
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Message</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id="frm2" onSubmit={update}>
          <Modal.Body className="bg-secondary2">
            {state && (
              <>
                <div className="mb-3">
                  <label>Message</label>
                  <div className="flex items-center justify-center gap-1">
                    <textarea
                      rows={1}
                      style={{ resize: "none" }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          UpdateFunction();
                        }
                      }}
                      ref={messageInputRef1}
                      onChange={(e) => setMessage(e.target.value, false)}
                      type="text"
                      value={message}
                      autoFocus
                      placeholder="Enter message here..."
                      className="h-[6vh] w-[60vw] m-1 rounded-md p-1 border-none outline-none text-[#8b0ee4]"
                    />
                    {loadingsend ? (
                      <Spinner />
                    ) : (
                      <button onClick={UpdateFunction}>
                        <MdSend size={30} />
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default EditMessageModal;
