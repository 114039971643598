import { CKEditor } from 'ckeditor4-react';
import React,{useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';

function AddTermConditionsModal({AddFunction}) {
    const [show, setShow] = useState(false);
    const [valid, setValid] = useState(false);
    const [state, setState] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  const handleEditorChange = (event) => {
      const content = event.editor.getData();
      setState((prevState) => ({ ...prevState, description: content }));
    };

      async function addNew(e)
      {
          e.preventDefault();
      if (e.currentTarget.checkValidity()) {
          AddFunction(state);
          handleClose();
      }
      else setValid(true);
      }

    return (
      <>
        <Button variant='primary' onClick={handleShow} className='flex items-center m-1'><AiOutlinePlus /> Add New Record</Button>

        <Modal show={show} onHide={handleClose} centered size='lg' className='text-white'>
          <Modal.Header className='bg-secondary2'>
            <Modal.Title>Add New Record</Modal.Title>
          </Modal.Header>
          <Form noValidate validated={valid} id='frm2' onSubmit={addNew}>
          <Modal.Body className='bg-secondary2'>
          <div className="mb-3">
          <label>Title</label>
                  <input
                    required
                    type="text"
                    name="title"
                    onChange={(e)=>setState((prevState) => ({...prevState,title:e.target.value}))}
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a title.
                  </Form.Control.Feedback>
          </div>
          <div className="mb-3">
          <label>Title</label>
          <CKEditor required onChange={handleEditorChange} />
          <Form.Control.Feedback type="invalid">
                    Please enter the details.
                  </Form.Control.Feedback>
          </div>
          <div className="mb-3">
          <label>Type</label>
          <select className='form-control' required name='type' onChange={(e)=>setState((prevState) => ({...prevState,type:e.target.value}))}>
            <option value=""></option>
            <option value="general">General Terms</option>
            <option value="tutor">Tutor Terms</option>
          </select>
          <Form.Control.Feedback type="invalid">
                    Please select a type.
                  </Form.Control.Feedback>
          </div>
          </Modal.Body>
          <Modal.Footer className='bg-secondary2'>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type='submit' variant="primary">
              Save
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
}

export default AddTermConditionsModal
