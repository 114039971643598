import React, { useState, useEffect } from 'react';

const CountDown = ({ targetDate }) => {
    const difference = new Date(targetDate) - new Date();
  const calculateTimeLeft = () => {
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (value) => {
    return value>0&&value.toString().padStart(2, '0');
  };

  return (
    <div>
      {difference > 0&&timeLeft.days && (
        <span>
          {formatTime(timeLeft.days)} {timeLeft.days === 1 ? 'day' : 'days'}{' '}
        </span>
      )}
      <span>
        {difference > 0&&formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
      </span>
    </div>
  );
};

export default CountDown;
