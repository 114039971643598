import React, { useState, useEffect } from 'react';
import { Pagination, Row } from 'react-bootstrap';
import CardRequest from './CardRequest';
import Axios from '../Utils/Axios';
import { useLocation } from 'react-router-dom';
import "../plugins/Scroll";
function Requests({ requests,type,setpriceFunction,deletFunction,tbl }) {
  const location=useLocation();

  const ITEMS_PER_PAGE = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(requests.length / ITEMS_PER_PAGE);
  function getRequestsForCurrentPage() {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return requests.slice(startIndex, endIndex);
  }
  async function DeleteFunction(id) {
    (location.pathname==="/exam-help" || location.pathname==="/homework-help" || location.pathname==="/project-help" || location.pathname==="/thesiswriting-help")&&
    await Axios.delete(`/requests/${id}`)
    .then((response) => {
      deletFunction();
    })
    .catch((error) => {});
    location.pathname==="/onlinetutoring-help"&&
    await Axios.delete(`/online/${id}`)
    .then((response) => {
      deletFunction();
    })
    .catch((error) => {});
    location.pathname==="/sos-help"&&
    await Axios.delete(`/sos/${id}`)
    .then((response) => {
      deletFunction();
    })
    .catch((error) => {});
  }
    async function setPrice(state,id) {
      (location.pathname==="/exam-help" || location.pathname==="/homework-help" || location.pathname==="/project-help" || location.pathname==="/thesiswriting-help")&&
      await Axios.post(`/requests/${id}/update-prices`,state).then((response)=>{
        setpriceFunction();
      }).catch((error)=>{});
      location.pathname==="/onlinetutoring-help"&&
      await Axios.post(`/online/${id}/update-prices`,state).then((response)=>{
        setpriceFunction();
      }).catch((error)=>{});
  }
  return [
    <Row>
        {requests.length===0 ?
        <div className='justify-content-center'><h3>No requests found!</h3></div>
        :
        getRequestsForCurrentPage().map((item) => {
          const dt=item.date;
            const date = new Date(dt);
            const options = {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            };
            const formattedDate = date.toLocaleDateString('en-GB', options);
            return (
              <CardRequest
                tbl={tbl}
                table={item.table}
                type={type}
                id={item.id}
                key={item.id} // Add a unique key prop to each CardRequest component
                name={item.student&&item.student.nickname}
                email={item.student&&item.student.email}
                course_title={item.label}
                subject={item.subject}
                answer_text={item.answer_text}
                answer_files={item.answer_files}
                description={item.description}
                description_type={item.description_type}
                materials={JSON.parse(item.file_links)}
                materials_name={JSON.parse(item.file_names)}
                exam_type={item.exam_type}
                country={item.country}
                language={item.language}
                state={item.state}
                start_time={item.time}
                duration={item.duration}
                ratings={item.rating}
                date_finish={formattedDate}
                timer={item.timer}
                student_price={item.student_price}
                tutor_price={item.tutor_price}
                tutor={item.tutor}
                setPrice={(state)=>{setPrice(state,item.id)}}
                deleteFunction={()=>{DeleteFunction(item.id)}}
                file_names_student={item.file_names_student}
                file_links_student={item.file_links_student}
                deleted_at={item.deleted_at}
                cancelled={item.cancelled}
              />
            );
          })}
        </Row>,
        <div className="d-flex justify-content-center">
          <Pagination>
            {currentPage > 1 && (
              <Pagination.Item onClick={() => setCurrentPage(currentPage - 1)}>
                &laquo;
              </Pagination.Item>
            )}
            {Array.from({ length: pageCount }).map((_, index) => {
              if (index === 0 || index === pageCount - 1 || (index >= currentPage - 2 && index <= currentPage)) {
                return (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (index === 1 && currentPage > 4) {
                return <Pagination.Ellipsis key={index} disabled />;
              } else if (index === pageCount - 2 && currentPage < pageCount - 3) {
                return <Pagination.Ellipsis key={index} disabled />;
              }
              return null;
            })}
            {currentPage < pageCount && (
              <Pagination.Item onClick={() => setCurrentPage(currentPage + 1)}>
                &raquo;
              </Pagination.Item>
            )}
          </Pagination>
        </div>
  ];
}

export default Requests;
