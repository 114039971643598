import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker = ({ onSelect, value, required,minormax,title }) => {
  const minDate=new Date();
  const maxDate=new Date();
  return [
   <div className="font-bold">{title}</div>,
    <DatePicker
    placeholderText="Please select a date"
      required={required}
      minDate={minormax==="min" ? minDate:""}
      maxDate={minormax==="max" ? maxDate:""}
      yearDropdownItemNumber={100}
      scrollableYearDropdown
      showYearDropdown
      showMonthDropdown
      dateFormat="dd MMM yyyy"
      className="form-control   border-none mt-1"
      selected={value}
      onSelect={onSelect}
    />
  ];
};

export default MyDatePicker;
