import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Row,Col, Form} from "react-bootstrap";

import { BsFillPersonFill, BsPlus } from "react-icons/bs";
import Axios from "../Utils/Axios";
import { FaPen } from "react-icons/fa";
function EditUserModal({ UpdateFunction,roles,data,id }) {
  const [show, setShow] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    setState(data);
  },[data]);

  async function update(e) {
    e.preventDefault();
    await Axios.post(`/users/update/${id}`,state).then((response)=>{
        UpdateFunction();
        handleClose();
    }).catch((error)=>{});
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    var output = document.getElementById('img');
    output.innerHTML="";
    output.innerHTML=`<img src=${URL.createObjectURL(file)} width='120px' height='120px' />`
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    setState((prevState)=>({ ...prevState, image: file }));
  };


  return (
    <>
      <Button variant="primary" className="m-1 w-100 flex" onClick={handleShow}>
       <FaPen className="icon" /> Edit user
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="text-white"
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Edit Dashboard Users</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={update}>
        <Modal.Body className="bg-secondary2">
            {state&&(
                         <div className="m-3">
                         <Row>
                           <Col md={4}>
                           <h5 className="text-white mb-3">Profile Picture</h5>
                           <div className="image-chooser">
                           <center>
                               <div id="img">
                              {state.profile_picture===null?
                              <>
                              <BsFillPersonFill className="icon icon-chooser" />
                              <br />
                              <span className="text-white">Drag & drop image</span>
                              <br />
                              <span className="text-white">Or</span>
                              </>
                              :
                              <img src={process.env.REACT_APP_MEDIAURL_SECOND+""+state.profile_picture} width={"120px"} height={"120px"}  />
                              }
                               </div>
                             <br />
                             <label for="customFile" class="custom-file-button bg-primary">
                               Upload Image
                               <input type="file" id="customFile" required onChange={handleImage} />
                             </label>
                           </center>
                         </div>
                           </Col>
                           <Col md={8}>
                           <div className="mb-3">
                           <label>User Name</label>
                           <input type="text" name="" id="" className="form-control" required value={state.username} onChange={(e)=>setState({...state,username:e.target.value})} />
                           <Form.Control.Feedback type="invalid">
                           Please enter a username.
                           </Form.Control.Feedback>
                           </div>
                           <br />
                           <label>Email</label>
                           <input type="text" name="" id="" className="form-control" required value={state.email} onChange={(e)=>setState({...state,email:e.target.value})} />
                           </Col>
                         </Row>
                         <br />
                         <Row className="justify-content-around">
                           <Col md={4}>
                           <div className="mb-3">
                           <label>Password</label>
                           <input type="password" autoComplete="new-password" name="" id="" onChange={(e)=>setState({...state,password:e.target.value})} className="form-control" />
                           </div>
                           <br />
                           </Col>
                           <Col md={4}>
                           <div className="mb-3">
                           <label>Confirm Password</label>
                           <input type="password" name="" required id="" onChange={(e)=>setState({...state,confirm_password:e.target.value})} className="form-control" />
                           </div>
                          <br />
                           </Col>
                         </Row>
                           <br />
                           <Row className="justify-content-around">
                               <Col>
                               <div className="mb-3">
                               <label>Role</label>
                           <select className="form-control" required value={state.role_id} onChange={(e)=>setState({...state,role_id:e.target.value})}>
                           <option value=""></option>
                           {roles&&roles.map((role)=>{
                               return <option value={role.id}>{role.title}</option>
                           })}
                           </select>
                              <Form.Control.Feedback type="invalid">
                           Please enter select a role.
                           </Form.Control.Feedback>
                           </div>
                               </Col>
                           </Row>
                           </div>
            )}
        </Modal.Body>
        <Modal.Footer className="bg-secondary2">
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditUserModal;
