import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Axios from "../../Utils/Axios";
import Slider from "../../components/Slider";
import Modal from "react-bootstrap/Modal";
import TimeComponent from "../../components/TimeComponent";
import PriceSet from "../../components/PriceSet";
import DurationSet from "../../components/DurationSet";
import MyDatePicker from "../../components/DatePicker";
import { Avatar } from "@mui/material";
import Thumbnail from "../../components/Thumbnail";
import { AiOutlineSend } from "react-icons/ai";
// import { Rating } from "react-simple-star-rating";
import Rating from "@mui/material/Rating";
import Slider2 from "../../components/Slider2";
import { BiUserCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import messaging from "../../Firebase";
import { enqueueSnackbar } from "notistack";
import { MdAccountCircle } from "react-icons/md";
import { BsFillFileEarmarkFill } from "react-icons/bs";
import ReactAudioPlayer from "react-audio-player";
import CancelRequestModal from "../../modals/CancelRequestModal";
import { FaArrowLeft, FaLink, FaTrash } from "react-icons/fa";
import ChatFile from "../../components/ChatFile";
import Preview from "preview-office-docs";
function EditRequest() {
  const location = useLocation();
  const [state, setState] = useState(null);
  const [show, setShow] = useState(false);
  const [room, setRoom] = useState(null);
  const [msg, setMsg] = useState("");
  const user = useSelector((state) => state.user).user;
  const handleMsg = (e) => {
    setMsg(e.target.value);
  };
  async function sendMessage() {
    msg != "" &&
      (await Axios.post(
        "requests/send/message",
        {
          room_id: room ? room : state.chat.id,
          sender_id: user.id,
          message: msg,
          student: state.student,
          tutor: state.tutor,
          request_id: state.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          getData();
          document.getElementById("msg").value = "";
        })
        .catch((error) => {}));
  }
  async function deleteMessage(id, sender_type, message, message_type) {
    await Axios.post(`requests/delete/message/${id}`, {
      room_id: room ? room : state.chat.id,
      sender_type: sender_type,
      message: message,
      message_type: message_type,
    })
      .then((response) => {
        getData();
        document.getElementById("msg").value = "";
      })
      .catch((error) => {});
  }
  const divRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const scrollToBottom = () => {
    if (divRef.current)
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  async function getData() {
    location.state.type === "requests" &&
      (await Axios.get(`/requests/${location.state.id}`)
        .then((response) => {
          setState(response.data.request);
          setTimeout(() => {
            if (divRef.current) {
              const maxHeight = divRef.current.scrollHeight;
              divRef.current.scrollTop = maxHeight;
            }
          }, 500);
        })
        .catch((error) => {}));
    location.state.type === "online" &&
      (await Axios.get(`/online/${location.state.id}`)
        .then((response) => {
          setState(response.data.request);
          setTimeout(() => {
            if (divRef.current) {
              const maxHeight = divRef.current.scrollHeight;
              divRef.current.scrollTop = maxHeight;
            }
          }, 500);
        })
        .catch((error) => {}));
    location.state.type === "sos" &&
      (await Axios.get(`/sos/${location.state.id}`)
        .then((response) => {
          setState(response.data.request);
          setTimeout(() => {
            if (divRef.current) {
              const maxHeight = divRef.current.scrollHeight;
              divRef.current.scrollTop = maxHeight;
            }
          }, 500);
        })
        .catch((error) => {}));
  }
  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name.includes("student.")) {
      const studentField = name.split(".")[1];
      setState({
        ...state,
        student: {
          ...state.student,
          [studentField]: value,
        },
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  async function update() {
    location.state.type === "requests" &&
      (await Axios.post(`/requests/${location.state.id}`, state)
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
    location.state.type === "online" &&
      (await Axios.post(`/requests/${location.state.id}`, state)
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
  }
  async function paid() {
    location.state.type === "requests" &&
      (await Axios.post(`/requests/${location.state.id}`, {
        request_status: "ongoing",
        paid: 1,
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
    location.state.type === "online" &&
      (await Axios.post(`/requests/${location.state.id}`, {
        request_status: "ongoing",
        paid: 1,
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
  }

  async function cancel(state) {
    (location.state.type === "requests" || location.state.type === "online") &&
      (await Axios.post(`/cancel/${location.state.id}`, {
        type: "request",
        reason: state.reason,
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
    location.state.type === "sos" &&
      (await Axios.post(`/cancel/${location.state.id}`, {
        type: "sos",
        reason: state.reason,
      })
        .then((response) => {
          getData();
        })
        .catch((error) => {}));
  }
  function copyToClipboard(id) {
    var textBox = document.getElementById(id);
    textBox.select();
    document.execCommand("copy");
  }
  function scrollToBottomm() {
    if (divRef.current)
      document.getElementById("divref").scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    getData();
    onMessage(messaging, (payload) => {
      getData();
    });
  }, [divRef.current]);
  const changetime = (time) => {
    setState({ ...state, time: time });
  };
  const changeduration = (duration) => {
    setState({ ...state, duration: duration });
  };
  const options = {
    waveColor: "#3498db",
    progressColor: "#2980b9",
    cursorColor: "#2980b9",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 100,
  };
  return (
    <>
      {state && state.request_status === "new" && (
        <div className="m-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {location.state.assistance_type === "exam" && (
                <span style={{ fontSize: "30px" }}>Exam Help </span>
              )}
              {location.state.assistance_type === "homework" && (
                <span style={{ fontSize: "30px" }}>HomeWork Help </span>
              )}
              {location.state.assistance_type === "project" && (
                <span style={{ fontSize: "30px" }}>Project Help </span>
              )}
              {location.state.assistance_type === "thesis" && (
                <span style={{ fontSize: "30px" }}>Thesis Writing Help </span>
              )}
              {location.state.assistance_type === "online" && (
                <span style={{ fontSize: "30px" }}>Online Tutoring Help </span>
              )}
              <span className="text-primary px-3">
                {state.request_status === "new" && "New Request"}
              </span>
            </div>
            <CancelRequestModal cancelFunction={cancel} id={state.id} />
          </div>
          <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
          {state && (
            <div className="md:flex justify-between gap-3">
              <div>
                <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-6">
                  <div className=" max-sm:py-2">
                    <label htmlFor="student_nickname">Student Nickname</label>
                    <input
                      type="text"
                      name="student.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.nickname}
                      readOnly
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <label htmlFor="student_email">Student Email</label>
                    <input
                      type="text"
                      name="student.email"
                      id="student_email"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.email}
                      readOnly
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <label htmlFor="course_title">Course Title</label>
                    <input
                      type="text"
                      name="label"
                      id="course_title"
                      className="form-control border-none mt-1"
                      onChange={handleInput}
                      value={state.label}
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <PriceSet
                      withButtons={false}
                      title={"Student Price"}
                      initialValue={state.student_price}
                      onChange={(price) =>
                        setState({ ...state, student_price: price })
                      }
                    />
                  </div>
                  {state.assistance_type === "exam" && (
                    <div className="max-sm:py-2">
                      <label htmlFor="">Exam Type</label>
                      <div className="flex min-[]: gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="subjective"
                            onChange={handleInput}
                            value={"Subjective"}
                            checked={
                              state.exam_type === "Subjective" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Subjective
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Both"
                            onChange={handleInput}
                            value={"Both"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="Both"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Don't Know"
                            onChange={handleInput}
                            value={"Don't Know"}
                            checked={
                              state.exam_type === "Don't Know" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Don't Know
                          </label>
                        </div>
                        <div className="items-end">
                          <input
                            type="radio"
                            name="exam_type"
                            id="MCQ"
                            onChange={handleInput}
                            value={"MCQ"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="MCQ"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            MCQ
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-6">
                  <div className=" max-sm:py-2">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.subject}
                      readOnly
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.country}
                      readOnly
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <label htmlFor="course_title">State</label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="form-control border-none mt-1"
                      onChange={handleInput}
                      value={state.state}
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <PriceSet
                      withButtons={false}
                      title={"Tutor Price"}
                      initialValue={state.tutor_price}
                      onChange={(price) =>
                        setState({ ...state, tutor_price: price })
                      }
                    />
                  </div>
                  <div className=" max-sm:py-2">
                    <label htmlFor="language">Language</label>
                    <input
                      type="text"
                      name="language"
                      id="language"
                      className="form-control border-none mt-1"
                      onChange={handleInput}
                      value={state.language}
                    />
                  </div>
                </div>
                <br />
                <div className="md:flex gap-3">
                  <div className="w-full">
                    <label htmlFor="description">Additional Notes</label>
                    <textarea
                      style={{ resize: "none" }}
                      readOnly
                      className="form-control  border-none mt-1 w-full"
                      rows={5}
                    >
                      {state.notes}
                    </textarea>
                  </div>
                </div>
                <br />
                <div>
                  <label htmlFor="">Description & Course Material </label>
                  <Slider2
                    items={JSON.parse(state.file_links)}
                    names={JSON.parse(state.file_names)}
                  />
                </div>
                <br />
                <div className="md:flex justify-between">
                  {(location.state.assistance_type === "exam" ||
                    location.state.assistance_type === "online") && [
                    <TimeComponent
                      title={"Start Time"}
                      initialvalue={state.time && state.time}
                      onChange={changetime}
                    />,
                    <div className="m-1">
                      <DurationSet
                        title={"Duration(minutes)"}
                        initialValue={state.duration}
                        onChange={changeduration}
                      />
                    </div>,
                  ]}
                  <div className=" my-3">
                    <label className="text-white text-center font-bold">
                      Date to finish request
                    </label>
                    <MyDatePicker
                      value={state.date && new Date(Date.parse(state.date))}
                      onSelect={(date) => {
                        const dateObj = new Date(date);
                        const formattedDate = dateObj
                          .toISOString()
                          .split("T")[0];
                        setState({
                          ...state,
                          date: formattedDate,
                          deadline: formattedDate,
                        });
                      }}
                    />
                  </div>
                </div>
                <div align="center">
                  <Button variant="primary" onClick={update}>
                    Save Changes
                  </Button>
                </div>
              </div>
              <div className="bg-[#1E1E2E] h-screen w-1 max-sm:hidden"></div>
              <div>
                <div className="text-white text-3xl mt-2 mb-3">
                  Chat History
                </div>
                {state && (
                  <div>
                    <div class="iphone md:h-[78vh] md:w-72 md:ml-16">
                      {room ? (
                        <>
                          <div className="absolute z-[999] p-3 w-full bg-secondary">
                            <FaArrowLeft onClick={() => setRoom(null)} />
                          </div>
                          <div class="screen">
                            <div class="fix bg-secondary">
                              <FaArrowLeft />
                            </div>
                            <div
                              class="screen-content mt-10 overflow-scroll"
                              id="divref"
                              ref={divRef}
                            >
                              {state.rooms
                                .find((rooms) => rooms.id === room)
                                .messages.map((item) => {
                                  return (
                                    <div
                                      className={
                                        item.type === "admin"
                                          ? `direct-chat-msg right`
                                          : `direct-chat-msg`
                                      }
                                    >
                                      <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                          {item.sender_type === "tutor" &&
                                            (state.rooms.find(
                                              (rooms) => rooms.id === room
                                            ) &&
                                              state.rooms.find(
                                                (rooms) => rooms.id === room
                                              ).tutor.nickname) + "(Tutor)"}
                                          {item.sender_type === "student" &&
                                            state.student &&
                                            state.student.nickname}
                                          {item.sender_type === "admin" &&
                                            "Administrator"}
                                        </span>
                                      </div>
                                      <div>
                                        {item.message_type === "file" &&
                                          item.message.split(".").pop() ===
                                            "m4a" && (
                                            <div className="custom-audio-player w-full">
                                              <ReactAudioPlayer
                                                src={
                                                  process.env
                                                    .REACT_APP_MEDIAURL +
                                                  item.message
                                                }
                                                autoPlay={false}
                                                controls
                                              />
                                              <div className="flex justify-end mt-1">
                                                <FaTrash
                                                  onClick={() =>
                                                    deleteMessage(
                                                      item.id,
                                                      item.sender_type,
                                                      item.message,
                                                      item.message_type
                                                    )
                                                  }
                                                  color="red"
                                                  size="15"
                                                />
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      {item.message_type === "text" ? (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {item.message}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {<ChatFile file={item.message} />}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      )}

                                      <span className="direct-chat-timestamp float-right">
                                        {new Date(
                                          item.created_at
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>

                            <div class="fix bg-secondary">
                              <div class="form-row align-items-center">
                                <div class="col">
                                  <input
                                    type="text"
                                    id="msg"
                                    class="form-control"
                                    placeholder="Type your message"
                                    onChange={handleMsg}
                                  />
                                </div>
                                <div class="col-auto">
                                  <button
                                    class="btn text-white"
                                    onClick={sendMessage}
                                  >
                                    <AiOutlineSend />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="h-[100vh] overflow-scroll">
                          {state.rooms.map((room) => {
                            return (
                              <div
                                className="m-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                                onClick={() => {
                                  setTimeout(() => {
                                    if (divRef.current) {
                                      const maxHeight =
                                        divRef.current.scrollHeight;
                                      divRef.current.scrollTop = maxHeight;
                                    }
                                  }, 500);
                                  setRoom(room.id);
                                }}
                              >
                                {room.tutor.profile_image ? (
                                  <img
                                    className="w-8 h-8 rounded-full"
                                    src={
                                      process.env.REACT_APP_MEDIAURL +
                                      room.tutor.profile_image
                                    }
                                  />
                                ) : (
                                  <MdAccountCircle className="w-8 h-8 rounded-full" />
                                )}
                                {room.tutor.nickname}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {state && state.request_status === "pending" && (
        <div className="m-3 md:flex justify-start gap-10">
          <div>
            <div className="md:flex  justify-between items-center">
              <div className="flex items-center">
                {location.state.assistance_type === "exam" && (
                  <span style={{ fontSize: "30px" }}>Exam Help </span>
                )}
                {location.state.assistance_type === "homework" && (
                  <span style={{ fontSize: "30px" }}>HomeWork Help </span>
                )}
                {location.state.assistance_type === "project" && (
                  <span style={{ fontSize: "30px" }}>Project Help </span>
                )}
                {location.state.assistance_type === "thesis" && (
                  <span style={{ fontSize: "30px" }}>Thesis Writing Help </span>
                )}
                {location.state.assistance_type === "online" && (
                  <span style={{ fontSize: "30px" }}>
                    Online Tutoring Help{" "}
                  </span>
                )}
                <span className="text-primary px-3">
                  {state.request_status === "pending" && "Pending"}
                </span>
              </div>
              <CancelRequestModal cancelFunction={cancel} id={state.id} />
            </div>
            <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
            {state && (
              <div className="md:h-[75vh] overflow-scroll">
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Nickname</label>
                    <input
                      type="text"
                      name="student.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.nickname}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Email</label>
                    <input
                      type="text"
                      name="student.email"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.email}
                      readOnly
                    />
                  </div>
                </div>
                {state.assistance_type === "online" && (
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Student Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="student_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.student_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("student_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Tutor Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="tutor_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.tutor_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("tutor_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Student Price"}
                      initialValue={state.student_price}
                      onChange={(price) =>
                        setState({ ...state, student_price: price })
                      }
                    />
                  </div>
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Tutor Price"}
                      initialValue={state.tutor_price}
                      onChange={(price) =>
                        setState({ ...state, tutor_price: price })
                      }
                    />
                  </div>
                  <div className="mt-2 max-sm:py-2 w-16">
                    <label htmlFor="description">ID</label>
                    <input
                      value={state.id}
                      readOnly
                      className="form-control w-16 border-none mt-1"
                      rows={5}
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="label">Course Title</label>
                    <input
                      type="text"
                      name="label"
                      id="label"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.label}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.subject}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.country}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.state}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Language</label>
                    <input
                      type="text"
                      name="language"
                      id="language"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.language}
                      readOnly
                    />
                  </div>
                  {state.assistance_type === "exam" && (
                    <div className="mt-2 max-sm:py-2">
                      <label htmlFor="">Exam Type</label>
                      <div className="flex min-[]: gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="subjective"
                            onChange={handleInput}
                            value={"Subjective"}
                            checked={
                              state.exam_type === "Subjective" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Subjective
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Both"
                            onChange={handleInput}
                            value={"Both"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="Both"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Don't Know"
                            onChange={handleInput}
                            value={"Don't Know"}
                            checked={
                              state.exam_type === "Don't Know" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Don't Know
                          </label>
                        </div>
                        <div className="items-end">
                          <input
                            type="radio"
                            name="exam_type"
                            id="MCQ"
                            onChange={handleInput}
                            value={"MCQ"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="MCQ"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            MCQ
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-2 w-full">
                  <label htmlFor="description">Additional Notes</label>
                  <textarea
                    style={{ resize: "none" }}
                    readOnly
                    className="form-control  border-none mt-1 w-full"
                    rows={5}
                  >
                    {state.notes}
                  </textarea>
                </div>
                <div className="mt-2 md:flex justify-between">
                  {(location.state.assistance_type === "exam" ||
                    location.state.assistance_type === "online") && [
                    <TimeComponent
                      title={"Start Time"}
                      initialvalue={state.time && state.time}
                      onChange={changetime}
                    />,
                    <div className="m-1">
                      <DurationSet
                        title={"Duration(minutes)"}
                        initialValue={state.duration}
                        onChange={changeduration}
                      />
                    </div>,
                  ]}
                </div>
                <div className="mt-2 my-3 w-fit">
                  <label className="text-white text-center font-bold">
                    Date to finish request
                  </label>
                  <MyDatePicker
                    value={state.date && new Date(Date.parse(state.date))}
                    onSelect={(date) => {
                      const dateObj = new Date(date);
                      const formattedDate = dateObj.toISOString().split("T")[0];
                      setState({
                        ...state,
                        date: formattedDate,
                        deadline: formattedDate,
                      });
                    }}
                  />
                </div>
                <div className="mt-2  md:max-w-xl">
                  <label htmlFor="">Description & Course Material </label>
                  <Slider
                    items={JSON.parse(state.file_links)}
                    names={JSON.parse(state.file_names)}
                  />
                </div>
                <div align="center">
                  <Button variant="primary" onClick={update}>
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden"></div>
          <div>
                <div className="text-white text-3xl mt-2 mb-3">
                  Chat History
                </div>
                {state && (
                  <div>
                    <div class="iphone md:h-[78vh] md:w-72 md:ml-16">
                      {room ? (
                        <>
                          <div className="absolute z-[999] p-3 w-full bg-secondary">
                            <FaArrowLeft onClick={() => setRoom(null)} />
                          </div>
                          <div class="screen">
                            <div class="fix bg-secondary">
                              <FaArrowLeft />
                            </div>
                            <div
                              class="screen-content mt-10 overflow-scroll"
                              id="divref"
                              ref={divRef}
                            >
                              {state.rooms
                                .find((rooms) => rooms.id === room)
                                .messages.map((item) => {
                                  return (
                                    <div
                                      className={
                                        item.type === "admin"
                                          ? `direct-chat-msg right`
                                          : `direct-chat-msg`
                                      }
                                    >
                                      <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                          {item.sender_type === "tutor" &&
                                            (state.rooms.find(
                                              (rooms) => rooms.id === room
                                            ) &&
                                              state.rooms.find(
                                                (rooms) => rooms.id === room
                                              ).tutor.nickname) + "(Tutor)"}
                                          {item.sender_type === "student" &&
                                            state.student &&
                                            state.student.nickname}
                                          {item.sender_type === "admin" &&
                                            "Administrator"}
                                        </span>
                                      </div>
                                      <div>
                                        {item.message_type === "file" &&
                                          item.message.split(".").pop() ===
                                            "m4a" && (
                                            <div className="custom-audio-player w-full">
                                              <ReactAudioPlayer
                                                src={
                                                  process.env
                                                    .REACT_APP_MEDIAURL +
                                                  item.message
                                                }
                                                autoPlay={false}
                                                controls
                                              />
                                              <div className="flex justify-end mt-1">
                                                <FaTrash
                                                  onClick={() =>
                                                    deleteMessage(
                                                      item.id,
                                                      item.sender_type,
                                                      item.message,
                                                      item.message_type
                                                    )
                                                  }
                                                  color="red"
                                                  size="15"
                                                />
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      {item.message_type === "text" ? (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {item.message}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {<ChatFile file={item.message} />}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      )}

                                      <span className="direct-chat-timestamp float-right">
                                        {new Date(
                                          item.created_at
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>

                            <div class="fix bg-secondary">
                              <div class="form-row align-items-center">
                                <div class="col">
                                  <input
                                    type="text"
                                    id="msg"
                                    class="form-control"
                                    placeholder="Type your message"
                                    onChange={handleMsg}
                                  />
                                </div>
                                <div class="col-auto">
                                  <button
                                    class="btn text-white"
                                    onClick={sendMessage}
                                  >
                                    <AiOutlineSend />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="h-[100vh] overflow-scroll">
                          {state.rooms.map((room) => {
                            return (
                              <div
                                className="m-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                                onClick={() => {
                                  setTimeout(() => {
                                    if (divRef.current) {
                                      const maxHeight =
                                        divRef.current.scrollHeight;
                                      divRef.current.scrollTop = maxHeight;
                                    }
                                  }, 500);
                                  setRoom(room.id);
                                }}
                              >
                                {room.tutor.profile_image ? (
                                  <img
                                    className="w-8 h-8 rounded-full"
                                    src={
                                      process.env.REACT_APP_MEDIAURL +
                                      room.tutor.profile_image
                                    }
                                  />
                                ) : (
                                  <MdAccountCircle className="w-8 h-8 rounded-full" />
                                )}
                                {room.tutor.nickname}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
        </div>
      )}
      {state && state.request_status === "waiting_payment" && (
        <div className="m-3 md:flex justify-start gap-10">
          <div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                {location.state.assistance_type === "exam" && (
                  <span style={{ fontSize: "30px" }}>Exam Help </span>
                )}
                {location.state.assistance_type === "homework" && (
                  <span style={{ fontSize: "30px" }}>HomeWork Help </span>
                )}
                {location.state.assistance_type === "project" && (
                  <span style={{ fontSize: "30px" }}>Project Help </span>
                )}
                {location.state.assistance_type === "thesis" && (
                  <span style={{ fontSize: "30px" }}>Thesis Writing Help </span>
                )}
                {location.state.assistance_type === "online" && (
                  <span style={{ fontSize: "30px" }}>
                    Online Tutoring Help{" "}
                  </span>
                )}
                <span className="text-primary px-3">
                  {state.request_status === "waiting_payment" && "Waiting Payment"}
                </span>
              </div>
              <Button className="btn btn-primary mr-1" onClick={()=>paid()}>Mark As Paid</Button>
              <CancelRequestModal cancelFunction={cancel} id={state.id} />
            </div>
            <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
            {state && (
              <div className="md:h-[75vh] overflow-scroll">
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Nickname</label>
                    <input
                      type="text"
                      name="student.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.nickname}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Email</label>
                    <input
                      type="text"
                      name="student.email"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Tutor Nickname</label>
                    <input
                      type="text"
                      name="tutor.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.tutor.nickname}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Tutor Email</label>
                    <input
                      type="text"
                      name="tutor.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.tutor.email}
                      readOnly
                    />
                  </div>
                </div>
                {state.assistance_type === "online" && (
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Student Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="student_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.student_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("student_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Tutor Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="tutor_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.tutor_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("tutor_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="mt-2">
                    <label className="font-bold">Tutor</label>
                    <div className="d-flex align-items-center">
                      {/* <Avatar
                        src={`${process.env.REACT_APP_MEDIAURL}/${
                          state.tutor && state.tutor.profile_image
                        }`}
                      /> */}
                      {state.tutor && state.tutor.profile_image != "" ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIAURL}/${
                            state.tutor && state.tutor.profile_image
                          }`}
                          className="rounded-full w-8 h-8"
                        />
                      ) : (
                        <BiUserCircle size={30} />
                      )}
                      <a className="ml-1" onClick={handleShow}>
                        View Profile
                      </a>
                    </div>
                    <Modal
                      show={show}
                      size="lg"
                      onHide={handleClose}
                      className="text-white"
                    >
                      <Modal.Header className="bg-secondary2">
                        <Modal.Title>Tutor Info</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="bg-secondary2">
                        <Card className="bg-secondary border-primary">
                          <Card.Body
                            style={{
                              maxHeight: "500px",
                              overflow: "scroll",
                            }}
                          >
                            {state.tutor && (
                              <div>
                                <div className="md:flex justify-between m-2">
                                  <div className="mt-2 flex">
                                    <div className="flex justify-start max-sm:justify-between items-center">
                                      <img
                                        src={`${
                                          process.env.REACT_APP_MEDIAURL
                                        }/${
                                          state.tutor &&
                                          state.tutor.profile_image
                                        }`}
                                        className="rounded-full w-8 h-8 p-1"
                                      />
                                      <div className="text-primary">
                                        {state.tutor.full_name}
                                      </div>
                                      <div className="text-white ml-3">
                                        ID:{state.tutor.id}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3">
                                  <span>Email:</span>{" "}
                                  <span className="text-primary">
                                    {state.tutor.email}
                                  </span>
                                </div>
                                <div className="m-2 mt-3">
                                  <span>Bio Information</span>
                                  <div>
                                    <p className="text-[#CACAFF]">
                                      {state.tutor.bio}
                                    </p>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div>
                                    <span>Phone Number: </span>
                                    <span className="text-primary">
                                      {state.tutor.phone_country_code +
                                        state.tutor.phone}
                                    </span>
                                  </div>
                                  <div>
                                    <span>WhatsApp Number: </span>
                                    <span className="text-primary">
                                      {state.tutor.whatsapp_country_code +
                                        state.tutor.whatsapp_phone}
                                    </span>
                                  </div>
                                </div>
                                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <div>Subjects: </div>
                                    <div className="md:flex justify-start overflow-scroll">
                                      {state.tutor.subjects.map((item) => {
                                        return (
                                          // <Col>
                                          <div className="language_item m-1 py-1">
                                            {item.subject.label}
                                          </div>
                                          // </Col>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <div className="py-1">Languages: </div>
                                    <div className="md:flex justify-start overflow-scroll">
                                      {state.tutor.languages.map((item) => {
                                        return (
                                          <div className="language_item py-1 m-1">
                                            {item.language.language_name}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <span className="text-white">
                                      Educations:{" "}
                                    </span>
                                    <br />
                                    <div align="center">
                                      <Row>
                                        <Col
                                          className="p-2"
                                          style={{ backgroundColor: "#433c5f" }}
                                        >
                                          <span className="text-white">
                                            University
                                          </span>
                                        </Col>
                                        <Col
                                          className="p-2"
                                          style={{ backgroundColor: "#433c5f" }}
                                        >
                                          <span className="text-white">
                                            Degree
                                          </span>
                                        </Col>
                                      </Row>
                                      {JSON.parse(state.tutor.university).map(
                                        (university, index) => {
                                          return (
                                            <Row>
                                              <Col
                                                className="p-2"
                                                style={{
                                                  backgroundColor: "#38384a",
                                                }}
                                              >
                                                <span className="text-white">
                                                  {university}
                                                </span>
                                              </Col>
                                              <Col
                                                className="p-2"
                                                style={{
                                                  backgroundColor: "#38384a",
                                                }}
                                              >
                                                <span className="text-white">
                                                  {
                                                    JSON.parse(
                                                      state.tutor.degree
                                                    )[index]
                                                  }
                                                </span>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Container className="">
                                  <br />
                                  <div>
                                    <Row>
                                      <Col className="p-2" md={6}>
                                        <h5 className="text-white font-weight-normal">
                                          Tutor ID
                                        </h5>
                                        <Thumbnail
                                          imageUrl={state.tutor.id_file_link}
                                          title={"Tutor ID"}
                                        />
                                      </Col>
                                      <Col className="p-2" md={6}>
                                        <h5 className="text-white font-weight-normal">
                                          Grade Transcript
                                        </h5>
                                        <Thumbnail
                                          imageUrl={
                                            state.tutor.certification_file_link
                                          }
                                          title={"Grade Transcript"}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </Container>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer className="bg-secondary2">
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Student Price"}
                      initialValue={state.student_price}
                      onChange={(price) =>
                        setState({ ...state, student_price: price })
                      }
                    />
                  </div>
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Tutor Price"}
                      initialValue={state.tutor_price}
                      onChange={(price) =>
                        setState({ ...state, tutor_price: price })
                      }
                    />
                  </div>
                  <div className="mt-2 max-sm:py-2 w-16">
                    <label htmlFor="description">ID</label>
                    <input
                      value={state.id}
                      readOnly
                      className="form-control w-16 border-none mt-1"
                      rows={5}
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="label">Course Title</label>
                    <input
                      type="text"
                      name="label"
                      id="label"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.label}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.subject}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.country}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.state}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Language</label>
                    <input
                      type="text"
                      name="language"
                      id="language"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.language}
                      readOnly
                    />
                  </div>
                  {state.assistance_type === "exam" && (
                    <div className="mt-2 max-sm:py-2">
                      <label htmlFor="">Exam Type</label>
                      <div className="flex min-[]: gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="subjective"
                            onChange={handleInput}
                            value={"Subjective"}
                            checked={
                              state.exam_type === "Subjective" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Subjective
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Both"
                            onChange={handleInput}
                            value={"Both"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="Both"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Don't Know"
                            onChange={handleInput}
                            value={"Don't Know"}
                            checked={
                              state.exam_type === "Don't Know" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Don't Know
                          </label>
                        </div>
                        <div className="items-end">
                          <input
                            type="radio"
                            name="exam_type"
                            id="MCQ"
                            onChange={handleInput}
                            value={"MCQ"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="MCQ"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            MCQ
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-2 w-full">
                  <label htmlFor="description">Additional Notes</label>
                  <textarea
                    style={{ resize: "none" }}
                    readOnly
                    className="form-control  border-none mt-1 w-full"
                    rows={5}
                  >
                    {state.notes}
                  </textarea>
                </div>
                <div className="mt-2 md:flex justify-between">
                  {(location.state.assistance_type === "exam" ||
                    location.state.assistance_type === "online") && [
                    <TimeComponent
                      title={"Start Time"}
                      initialvalue={state.time && state.time}
                      onChange={changetime}
                    />,
                    <div className="m-1">
                      <DurationSet
                        title={"Duration(minutes)"}
                        initialValue={state.duration}
                        onChange={changeduration}
                      />
                    </div>,
                  ]}
                </div>
                <div className="mt-2 my-3 w-fit">
                  <label className="text-white text-center font-bold">
                    Date to finish request
                  </label>
                  <MyDatePicker
                    value={state.date && new Date(Date.parse(state.date))}
                    onSelect={(date) => {
                      const dateObj = new Date(date);
                      const formattedDate = dateObj.toISOString().split("T")[0];
                      setState({
                        ...state,
                        date: formattedDate,
                        deadline: formattedDate,
                      });
                    }}
                  />
                </div>
                <div className="mt-2  md:max-w-xl">
                  <label htmlFor="">Description & Course Material </label>
                  <Slider
                    items={JSON.parse(state.file_links)}
                    names={JSON.parse(state.file_names)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden"></div>
          <div>
            <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
            {state && (
              <div>
                <div class="iphone md:h-[80vh] md:w-72 md:ml-16">
                  {room ? (
                    <>
                      <div className="absolute z-[999] p-3 w-full bg-secondary">
                        <FaArrowLeft onClick={() => setRoom(null)} />
                      </div>
                      <div class="screen">
                        <div class="fix bg-secondary">
                          <FaArrowLeft />
                        </div>
                        <div
                          class="screen-content mt-10 overflow-scroll"
                          id="divref"
                          ref={divRef}
                        >
                          {state.rooms.find((roomItem) => roomItem.id === room)
                            ? state.rooms
                                .find((rooms) => rooms.id === room)
                                .messages.map((item) => {
                                  return (
                                    <div
                                      className={
                                        item.type === "admin"
                                          ? `direct-chat-msg right`
                                          : `direct-chat-msg`
                                      }
                                    >
                                      <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                          {item.sender_type === "tutor" &&
                                            (state.rooms.find(
                                              (rooms) => rooms.id === room
                                            ) &&
                                              state.rooms.find(
                                                (rooms) => rooms.id === room
                                              ).tutor.nickname) + "(Tutor)"}
                                          {item.sender_type === "student" &&
                                            state.student &&
                                            state.student.nickname}
                                          {item.sender_type === "admin" &&
                                            "Administrator"}
                                        </span>
                                      </div>
                                      <div>
                                        {item.message_type === "file" &&
                                          item.message.split(".").pop() ===
                                            "m4a" && (
                                            <div className="custom-audio-player w-full">
                                              <ReactAudioPlayer
                                                src={
                                                  process.env
                                                    .REACT_APP_MEDIAURL +
                                                  item.message
                                                }
                                                autoPlay={false}
                                                controls
                                              />
                                            </div>
                                          )}
                                      </div>
                                      {item.message_type === "text" ? (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {item.message}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {<ChatFile file={item.message} />}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      )}

                                      <span className="direct-chat-timestamp float-right">
                                        {new Date(
                                          item.created_at
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </span>
                                    </div>
                                  );
                                })
                            : state.chat.messages.map((item) => {
                                return (
                                  <div
                                    className={
                                      item.type === "admin"
                                        ? `direct-chat-msg right`
                                        : `direct-chat-msg`
                                    }
                                  >
                                    <div class="direct-chat-infos clearfix">
                                      <span class="direct-chat-name float-left">
                                        {item.sender_type === "tutor" &&
                                          state.chat.tutor.nickname + "(Tutor)"}
                                        {item.sender_type === "student" &&
                                          state.student &&
                                          state.student.nickname}
                                        {item.sender_type === "admin" &&
                                          "Administrator"}
                                      </span>
                                    </div>
                                    <div>
                                      {item.message_type === "file" &&
                                        item.message.split(".").pop() ===
                                          "m4a" && (
                                          <div className="custom-audio-player w-full">
                                            <ReactAudioPlayer
                                              src={
                                                process.env.REACT_APP_MEDIAURL +
                                                item.message
                                              }
                                              autoPlay={false}
                                              controls
                                            />
                                            <div className="flex justify-end mt-1">
                                              <FaTrash
                                                onClick={() =>
                                                  deleteMessage(
                                                    item.id,
                                                    item.sender_type,
                                                    item.message,
                                                    item.message_type
                                                  )
                                                }
                                                color="red"
                                                size="15"
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {item.message_type === "text" ? (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {item.message}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {<ChatFile file={item.message} />}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <span className="direct-chat-timestamp float-right">
                                      {new Date(item.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                        </div>

                        <div class="fix bg-secondary">
                          <div class="form-row align-items-center">
                            <div class="col">
                              <input
                                type="text"
                                id="msg"
                                class="form-control"
                                placeholder="Type your message"
                                onChange={handleMsg}
                              />
                            </div>
                            <div class="col-auto">
                              <button
                                class="btn text-white"
                                onClick={sendMessage}
                              >
                                <AiOutlineSend />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="h-[100vh] overflow-scroll">
                      <span className="m-3 mt-3 text-white text-sm font-[600]">
                        Ongoing
                      </span>
                      <div
                        className="mx-3 mb-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                        onClick={() => {
                          setTimeout(() => {
                            if (divRef.current) {
                              const maxHeight = divRef.current.scrollHeight;
                              divRef.current.scrollTop = maxHeight;
                            }
                          }, 500);
                          setRoom(state.chat.id);
                        }}
                      >
                        {state.chat.tutor && state.chat.tutor.profile_image ? (
                          <img
                            className="w-8 h-8 rounded-full"
                            src={
                              process.env.REACT_APP_MEDIAURL +
                              state.chat.tutor.profile_image
                            }
                          />
                        ) : (
                          <MdAccountCircle className="w-8 h-8 rounded-full" />
                        )}
                        {state.chat.tutor.nickname}
                      </div>
                      <span className="m-3 mt-1 text-white text-sm font-[600]">
                        Pending
                      </span>

                      {state.rooms.map((room) => {
                        return (
                          <div
                            className="mx-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                            onClick={() => {
                              setTimeout(() => {
                                if (divRef.current) {
                                  const maxHeight = divRef.current.scrollHeight;
                                  divRef.current.scrollTop = maxHeight;
                                }
                              }, 500);
                              setRoom(room.id);
                            }}
                          >
                            {room.tutor.profile_image ? (
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  process.env.REACT_APP_MEDIAURL +
                                  room.tutor.profile_image
                                }
                              />
                            ) : (
                              <MdAccountCircle className="w-8 h-8 rounded-full" />
                            )}
                            {room.tutor.nickname}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {state && state.request_status === "ongoing" && (
        <div className="m-3 md:flex justify-start gap-10">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {location.state.assistance_type === "exam" && (
                <span style={{ fontSize: "30px" }}>Exam Help </span>
              )}
              {location.state.assistance_type === "homework" && (
                <span style={{ fontSize: "30px" }}>HomeWork Help </span>
              )}
              {location.state.assistance_type === "project" && (
                <span style={{ fontSize: "30px" }}>Project Help </span>
              )}
              {location.state.assistance_type === "thesis" && (
                <span style={{ fontSize: "30px" }}>Thesis Writing Help </span>
              )}
              {location.state.assistance_type === "online" && (
                  <span style={{ fontSize: "30px" }}>
                    Online Tutoring Help{" "}
                  </span>
                )}
              <span className="text-primary px-3">
                {state.request_status === "ongoing" && "Ongoing"}
              </span>
            </div>
            <CancelRequestModal cancelFunction={cancel} id={state.id} />
          </div>
          <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
          {state && (
            <div className="md:h-[75vh] overflow-scroll">
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="max-sm:py-2">
                  <label htmlFor="student_nickname">Student Nickname</label>
                  <input
                    type="text"
                    name="student.nickname"
                    id="student_nickname"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.student.nickname}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="student_nickname">Student Email</label>
                  <input
                    type="text"
                    name="student.email"
                    id="student_nickname"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.student.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="max-sm:py-2">
                  <label htmlFor="student_nickname">Tutor Nickname</label>
                  <input
                    type="text"
                    name="tutor.nickname"
                    id="student_nickname"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.tutor.nickname}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="student_nickname">Tutor Email</label>
                  <input
                    type="text"
                    name="tutor.nickname"
                    id="student_nickname"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.tutor.email}
                    readOnly
                  />
                </div>
              </div>
              {state.assistance_type === "online" && (
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="max-sm:py-2">
                      <label htmlFor="student_nickname">Student Link</label>
                      <div className="flex items-center justify-center relative">
                        <input
                          type="text"
                          name="student.nickname"
                          id="student_meeting_url"
                          onChange={handleInput}
                          className="form-control border-none mt-1"
                          value={state.student_meeting_url}
                          readOnly
                        />
                        <FaLink
                          size={18}
                          className="absolute right-1 bg-secondary"
                          onClick={() => {
                            copyToClipboard("student_meeting_url");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="max-sm:py-2">
                      <label htmlFor="student_nickname">Tutor Link</label>
                      <div className="flex items-center justify-center relative">
                        <input
                          type="text"
                          name="student.nickname"
                          id="tutor_meeting_url"
                          onChange={handleInput}
                          className="form-control border-none mt-1"
                          value={state.tutor_meeting_url}
                          readOnly
                        />
                        <FaLink
                          size={18}
                          className="absolute right-1 bg-secondary"
                          onClick={() => {
                            copyToClipboard("tutor_meeting_url");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="mt-2">
                  <label className="font-bold">Tutor</label>
                  <div className="d-flex align-items-center">
                    {/* <Avatar
                      src={`${process.env.REACT_APP_MEDIAURL}/${
                        state.tutor && state.tutor.profile_image
                      }`}
                    /> */}
                    {state.tutor && state.tutor.profile_image != "" ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIAURL}/${
                          state.tutor && state.tutor.profile_image
                        }`}
                        className="rounded-full w-8 h-8"
                      />
                    ) : (
                      <BiUserCircle size={30} />
                    )}
                    <a className="ml-1" onClick={handleShow}>
                      View Profile
                    </a>
                  </div>
                  <Modal
                    show={show}
                    size="lg"
                    onHide={handleClose}
                    className="text-white"
                  >
                    <Modal.Header className="bg-secondary2">
                      <Modal.Title>Tutor Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-secondary2">
                      <Card className="bg-secondary border-primary">
                        <Card.Body
                          style={{
                            maxHeight: "500px",
                            overflow: "scroll",
                          }}
                        >
                          {state.tutor && (
                            <div>
                              <div className="md:flex justify-between m-2">
                                <div className="mt-2 flex">
                                  <div className="flex justify-start max-sm:justify-between items-center">
                                    <img
                                      src={`${
                                        process.env.REACT_APP_MEDIAURL
                                      }/${
                                        state.tutor &&
                                        state.tutor.profile_image
                                      }`}
                                      className="rounded-full w-8 h-8 p-1"
                                    />
                                    <div className="text-primary">
                                      {state.tutor.full_name}
                                    </div>
                                    <div className="text-white ml-3">
                                      ID:{state.tutor.id}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Email:</span>{" "}
                                <span className="text-primary">
                                  {state.tutor.email}
                                </span>
                              </div>
                              <div className="m-2 mt-3">
                                <span>Bio Information</span>
                                <div>
                                  <p className="text-[#CACAFF]">
                                    {state.tutor.bio}
                                  </p>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div>
                                  <span>Phone Number: </span>
                                  <span className="text-primary">
                                    {state.tutor.phone_country_code +
                                      state.tutor.phone}
                                  </span>
                                </div>
                                <div>
                                  <span>WhatsApp Number: </span>
                                  <span className="text-primary">
                                    {state.tutor.whatsapp_country_code +
                                      state.tutor.whatsapp_phone}
                                  </span>
                                </div>
                              </div>
                              <hr className="bg-[#28293D] h-1 my-1 w-full" />
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div>Subjects: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {state.tutor.subjects.map((item) => {
                                      return (
                                        // <Col>
                                        <div className="language_item m-1 py-1">
                                          {item.subject.label}
                                        </div>
                                        // </Col>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <div className="py-1">Languages: </div>
                                  <div className="md:flex justify-start overflow-scroll">
                                    {state.tutor.languages.map((item) => {
                                      return (
                                        <div className="language_item py-1 m-1">
                                          {item.language.language_name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="m-2 mt-3 md:flex justify-between">
                                <div className="w-full">
                                  <span className="text-white">
                                    Educations:{" "}
                                  </span>
                                  <br />
                                  <div align="center">
                                    <Row>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          University
                                        </span>
                                      </Col>
                                      <Col
                                        className="p-2"
                                        style={{ backgroundColor: "#433c5f" }}
                                      >
                                        <span className="text-white">
                                          Degree
                                        </span>
                                      </Col>
                                    </Row>
                                    {JSON.parse(state.tutor.university).map(
                                      (university, index) => {
                                        return (
                                          <Row>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {university}
                                              </span>
                                            </Col>
                                            <Col
                                              className="p-2"
                                              style={{
                                                backgroundColor: "#38384a",
                                              }}
                                            >
                                              <span className="text-white">
                                                {
                                                  JSON.parse(
                                                    state.tutor.degree
                                                  )[index]
                                                }
                                              </span>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Container className="">
                                <br />
                                <div>
                                  <Row>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Tutor ID
                                      </h5>
                                      <Thumbnail
                                        imageUrl={state.tutor.id_file_link}
                                        title={"Tutor ID"}
                                      />
                                    </Col>
                                    <Col className="p-2" md={6}>
                                      <h5 className="text-white font-weight-normal">
                                        Grade Transcript
                                      </h5>
                                      <Thumbnail
                                        imageUrl={
                                          state.tutor.certification_file_link
                                        }
                                        title={"Grade Transcript"}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Container>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer className="bg-secondary2">
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className=" mt-2">
                  <PriceSet
                    withButtons={false}
                    title={"Student Price"}
                    initialValue={state.student_price}
                    onChange={(price) =>
                      setState({ ...state, student_price: price })
                    }
                  />
                </div>
                <div className=" mt-2">
                  <PriceSet
                    withButtons={false}
                    title={"Tutor Price"}
                    initialValue={state.tutor_price}
                    onChange={(price) =>
                      setState({ ...state, tutor_price: price })
                    }
                  />
                </div>
                <div className="mt-2 max-sm:py-2 w-16">
                  <label htmlFor="description">ID</label>
                  <input
                    value={state.id}
                    readOnly
                    className="form-control w-16 border-none mt-1"
                    rows={5}
                  />
                </div>
              </div>
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="max-sm:py-2">
                  <label htmlFor="label">Course Title</label>
                  <input
                    type="text"
                    name="label"
                    id="label"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.label}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.subject}
                    readOnly
                  />
                </div>
              </div>
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="max-sm:py-2">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.country}
                    readOnly
                  />
                </div>
                <div className="max-sm:py-2">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.state}
                    readOnly
                  />
                </div>
              </div>
              <div className="mt-2 md:flex justify-between gap-10">
                <div className="max-sm:py-2">
                  <label htmlFor="country">Language</label>
                  <input
                    type="text"
                    name="language"
                    id="language"
                    onChange={handleInput}
                    className="form-control border-none mt-1"
                    value={state.language}
                    readOnly
                  />
                </div>
                {state.assistance_type === "exam" && (
                  <div className="mt-2 max-sm:py-2">
                    <label htmlFor="">Exam Type</label>
                    <div className="flex min-[]: gap-3">
                      <div>
                        <input
                          type="radio"
                          name="exam_type"
                          id="subjective"
                          onChange={handleInput}
                          value={"Subjective"}
                          checked={
                            state.exam_type === "Subjective" ? true : false
                          }
                          className="radio"
                        />
                        <label
                          htmlFor="subjective"
                          className="font-normal m-1"
                          style={{ fontWeight: 400 }}
                        >
                          Subjective
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="exam_type"
                          id="Both"
                          onChange={handleInput}
                          value={"Both"}
                          checked={state.exam_type === "Both" ? true : false}
                          className="radio"
                        />
                        <label
                          htmlFor="Both"
                          className="font-normal m-1"
                          style={{ fontWeight: 400 }}
                        >
                          Both
                        </label>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div>
                        <input
                          type="radio"
                          name="exam_type"
                          id="Don't Know"
                          onChange={handleInput}
                          value={"Don't Know"}
                          checked={
                            state.exam_type === "Don't Know" ? true : false
                          }
                          className="radio"
                        />
                        <label
                          htmlFor="subjective"
                          className="font-normal m-1"
                          style={{ fontWeight: 400 }}
                        >
                          Don't Know
                        </label>
                      </div>
                      <div className="items-end">
                        <input
                          type="radio"
                          name="exam_type"
                          id="MCQ"
                          onChange={handleInput}
                          value={"MCQ"}
                          checked={state.exam_type === "Both" ? true : false}
                          className="radio"
                        />
                        <label
                          htmlFor="MCQ"
                          className="font-normal m-1"
                          style={{ fontWeight: 400 }}
                        >
                          MCQ
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-2 w-full">
                <label htmlFor="description">Additional Notes</label>
                <textarea
                  style={{ resize: "none" }}
                  readOnly
                  className="form-control  border-none mt-1 w-full"
                  rows={5}
                >
                  {state.notes}
                </textarea>
              </div>
              <div className="mt-2 md:flex justify-between">
                {(location.state.assistance_type === "exam" ||
                  location.state.assistance_type === "online") && [
                  <TimeComponent
                    title={"Start Time"}
                    initialvalue={state.time && state.time}
                    onChange={changetime}
                  />,
                  <div className="m-1">
                    <DurationSet
                      title={"Duration(minutes)"}
                      initialValue={state.duration}
                      onChange={changeduration}
                    />
                  </div>,
                ]}
              </div>
              <div className="mt-2 my-3 w-fit">
                <label className="text-white text-center font-bold">
                  Date to finish request
                </label>
                <MyDatePicker
                  value={state.date && new Date(Date.parse(state.date))}
                  onSelect={(date) => {
                    const dateObj = new Date(date);
                    const formattedDate = dateObj.toISOString().split("T")[0];
                    setState({
                      ...state,
                      date: formattedDate,
                      deadline: formattedDate,
                    });
                  }}
                />
              </div>
              <div className="mt-2  md:max-w-xl">
                <label htmlFor="">Description & Course Material </label>
                <Slider
                  items={JSON.parse(state.file_links)}
                  names={JSON.parse(state.file_names)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden"></div>
        <div>
            <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
            {state && (
              <div>
                <div class="iphone md:h-[80vh] md:w-72 md:ml-16">
                  {room ? (
                    <>
                      <div className="absolute z-[999] p-3 w-full bg-secondary">
                        <FaArrowLeft onClick={() => setRoom(null)} />
                      </div>
                      <div class="screen">
                        <div class="fix bg-secondary">
                          <FaArrowLeft />
                        </div>
                        <div
                          class="screen-content mt-10 overflow-scroll"
                          id="divref"
                          ref={divRef}
                        >
                          {state.rooms.find((roomItem) => roomItem.id === room)
                            ? state.rooms
                                .find((rooms) => rooms.id === room)
                                .messages.map((item) => {
                                  return (
                                    <div
                                      className={
                                        item.type === "admin"
                                          ? `direct-chat-msg right`
                                          : `direct-chat-msg`
                                      }
                                    >
                                      <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                          {item.sender_type === "tutor" &&
                                            (state.rooms.find(
                                              (rooms) => rooms.id === room
                                            ) &&
                                              state.rooms.find(
                                                (rooms) => rooms.id === room
                                              ).tutor.nickname) + "(Tutor)"}
                                          {item.sender_type === "student" &&
                                            state.student &&
                                            state.student.nickname}
                                          {item.sender_type === "admin" &&
                                            "Administrator"}
                                        </span>
                                      </div>
                                      <div>
                                        {item.message_type === "file" &&
                                          item.message.split(".").pop() ===
                                            "m4a" && (
                                            <div className="custom-audio-player w-full">
                                              <ReactAudioPlayer
                                                src={
                                                  process.env
                                                    .REACT_APP_MEDIAURL +
                                                  item.message
                                                }
                                                autoPlay={false}
                                                controls
                                              />
                                            </div>
                                          )}
                                      </div>
                                      {item.message_type === "text" ? (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {item.message}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {<ChatFile file={item.message} />}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      )}

                                      <span className="direct-chat-timestamp float-right">
                                        {new Date(
                                          item.created_at
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </span>
                                    </div>
                                  );
                                })
                            : state.chat.messages.map((item) => {
                                return (
                                  <div
                                    className={
                                      item.type === "admin"
                                        ? `direct-chat-msg right`
                                        : `direct-chat-msg`
                                    }
                                  >
                                    <div class="direct-chat-infos clearfix">
                                      <span class="direct-chat-name float-left">
                                        {item.sender_type === "tutor" &&
                                          state.chat.tutor.nickname + "(Tutor)"}
                                        {item.sender_type === "student" &&
                                          state.student &&
                                          state.student.nickname}
                                        {item.sender_type === "admin" &&
                                          "Administrator"}
                                      </span>
                                    </div>
                                    <div>
                                      {item.message_type === "file" &&
                                        item.message.split(".").pop() ===
                                          "m4a" && (
                                          <div className="custom-audio-player w-full">
                                            <ReactAudioPlayer
                                              src={
                                                process.env.REACT_APP_MEDIAURL +
                                                item.message
                                              }
                                              autoPlay={false}
                                              controls
                                            />
                                            <div className="flex justify-end mt-1">
                                              <FaTrash
                                                onClick={() =>
                                                  deleteMessage(
                                                    item.id,
                                                    item.sender_type,
                                                    item.message,
                                                    item.message_type
                                                  )
                                                }
                                                color="red"
                                                size="15"
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {item.message_type === "text" ? (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {item.message}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {<ChatFile file={item.message} />}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <span className="direct-chat-timestamp float-right">
                                      {new Date(item.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                        </div>

                        <div class="fix bg-secondary">
                          <div class="form-row align-items-center">
                            <div class="col">
                              <input
                                type="text"
                                id="msg"
                                class="form-control"
                                placeholder="Type your message"
                                onChange={handleMsg}
                              />
                            </div>
                            <div class="col-auto">
                              <button
                                class="btn text-white"
                                onClick={sendMessage}
                              >
                                <AiOutlineSend />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="h-[100vh] overflow-scroll">
                      <span className="m-3 mt-3 text-white text-sm font-[600]">
                        Ongoing
                      </span>
                      <div
                        className="mx-3 mb-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                        onClick={() => {
                          setTimeout(() => {
                            if (divRef.current) {
                              const maxHeight = divRef.current.scrollHeight;
                              divRef.current.scrollTop = maxHeight;
                            }
                          }, 500);
                          setRoom(state.chat.id);
                        }}
                      >
                        {state.chat.tutor && state.chat.tutor.profile_image ? (
                          <img
                            className="w-8 h-8 rounded-full"
                            src={
                              process.env.REACT_APP_MEDIAURL +
                              state.chat.tutor.profile_image
                            }
                          />
                        ) : (
                          <MdAccountCircle className="w-8 h-8 rounded-full" />
                        )}
                        {state.chat.tutor.nickname}
                      </div>
                      <span className="m-3 mt-1 text-white text-sm font-[600]">
                        Pending
                      </span>

                      {state.rooms.map((room) => {
                        return (
                          <div
                            className="mx-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                            onClick={() => {
                              setTimeout(() => {
                                if (divRef.current) {
                                  const maxHeight = divRef.current.scrollHeight;
                                  divRef.current.scrollTop = maxHeight;
                                }
                              }, 500);
                              setRoom(room.id);
                            }}
                          >
                            {room.tutor.profile_image ? (
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  process.env.REACT_APP_MEDIAURL +
                                  room.tutor.profile_image
                                }
                              />
                            ) : (
                              <MdAccountCircle className="w-8 h-8 rounded-full" />
                            )}
                            {room.tutor.nickname}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
      </div>
      )}
      {state && state.request_status === "completed" && (
        <div className="m-3 md:flex justify-start gap-10">
          <div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                {location.state.assistance_type === "exam" && (
                  <span style={{ fontSize: "30px" }}>Exam Help </span>
                )}
                {location.state.assistance_type === "homework" && (
                  <span style={{ fontSize: "30px" }}>HomeWork Help </span>
                )}
                {location.state.assistance_type === "project" && (
                  <span style={{ fontSize: "30px" }}>Project Help </span>
                )}
                {location.state.assistance_type === "thesis" && (
                  <span style={{ fontSize: "30px" }}>Thesis Writing Help </span>
                )}
                <span className="text-primary px-3">
                  {state.request_status === "completed" && "Completed"}
                </span>
              </div>
              <CancelRequestModal cancelFunction={cancel} id={state.id} />
            </div>
            <div className="text-white text-sm mt-2 mb-3">Edit Request</div>
            {state && (
              <div className="md:h-[75vh] overflow-scroll">
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Nickname</label>
                    <input
                      type="text"
                      name="student.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.nickname}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Student Email</label>
                    <input
                      type="text"
                      name="student.email"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.student.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Tutor Nickname</label>
                    <input
                      type="text"
                      name="tutor.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.tutor.nickname}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="student_nickname">Tutor Email</label>
                    <input
                      type="text"
                      name="tutor.nickname"
                      id="student_nickname"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.tutor.email}
                      readOnly
                    />
                  </div>
                </div>
                {state.assistance_type === "online" && (
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Student Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="student_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.student_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("student_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Tutor Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="tutor_meeting_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.tutor_meeting_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("tutor_meeting_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.assistance_type === "online" && (
                  <div className="mt-2 md:flex justify-between gap-10">
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">Record Link</label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="recording_url"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.meeting_record_url}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("recording_url");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 md:flex justify-between gap-10">
                      <div className="max-sm:py-2">
                        <label htmlFor="student_nickname">
                          Meeting Passcode
                        </label>
                        <div className="flex items-center justify-center relative">
                          <input
                            type="text"
                            name="student.nickname"
                            id="meeting_password"
                            onChange={handleInput}
                            className="form-control border-none mt-1"
                            value={state.meeting_password}
                            readOnly
                          />
                          <FaLink
                            size={18}
                            className="absolute right-1 bg-secondary"
                            onClick={() => {
                              copyToClipboard("meeting_password");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-2">
                  <div className="max-sm:py-2">
                    <label htmlFor="label">Rating</label>
                    <div className="grid">
                      <Rating
                        name="size-large"
                        defaultValue={state.rating}
                        precision={0.5}
                        size="large"
                        readOnly
                      />
                      {/* <Rating readonly initialValue={state.rating} /> */}
                    </div>
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="mt-2">
                    <label className="font-bold">Tutor</label>
                    <div className="d-flex align-items-center">
                      {state.tutor && state.tutor.profile_image != "" ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIAURL}/${
                            state.tutor && state.tutor.profile_image
                          }`}
                          className="rounded-full w-8 h-8"
                        />
                      ) : (
                        <BiUserCircle size={30} />
                      )}
                      <a className="ml-1" onClick={handleShow}>
                        View Profile
                      </a>
                    </div>
                    <Modal
                      show={show}
                      size="lg"
                      onHide={handleClose}
                      className="text-white"
                    >
                      <Modal.Header className="bg-secondary2">
                        <Modal.Title>Tutor Info</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="bg-secondary2">
                        <Card className="bg-secondary border-primary">
                          <Card.Body
                            style={{
                              maxHeight: "500px",
                              overflow: "scroll",
                            }}
                          >
                            {state.tutor && (
                              <div>
                                <div className="md:flex justify-between m-2">
                                  <div className="mt-2 flex">
                                    <div className="flex justify-start max-sm:justify-between items-center">
                                      <img
                                        src={`${
                                          process.env.REACT_APP_MEDIAURL
                                        }/${
                                          state.tutor &&
                                          state.tutor.profile_image
                                        }`}
                                        className="rounded-full p-1 h-8 w-8"
                                      />
                                      <div className="text-primary">
                                        {state.tutor.full_name}
                                      </div>
                                      <div className="text-white ml-3">
                                        ID:{state.tutor.id}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3">
                                  <span>Email:</span>{" "}
                                  <span className="text-primary">
                                    {state.tutor.email}
                                  </span>
                                </div>
                                <div className="m-2 mt-3">
                                  <span>Bio Information</span>
                                  <div>
                                    <p className="text-[#CACAFF]">
                                      {state.tutor.bio}
                                    </p>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div>
                                    <span>Phone Number: </span>
                                    <span className="text-primary">
                                      {state.tutor.phone_country_code +
                                        state.tutor.phone}
                                    </span>
                                  </div>
                                  <div>
                                    <span>WhatsApp Number: </span>
                                    <span className="text-primary">
                                      {state.tutor.whatsapp_country_code +
                                        state.tutor.whatsapp_phone}
                                    </span>
                                  </div>
                                </div>
                                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <div>Subjects: </div>
                                    <div className="md:flex justify-start overflow-scroll">
                                      {state.tutor.subjects.map((item) => {
                                        return (
                                          // <Col>
                                          <div className="language_item m-1 py-1">
                                            {item.subject.label}
                                          </div>
                                          // </Col>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <div className="py-1">Languages: </div>
                                    <div className="md:flex justify-start overflow-scroll">
                                      {state.tutor.languages.map((item) => {
                                        return (
                                          <div className="language_item py-1 m-1">
                                            {item.language.language_name}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="m-2 mt-3 md:flex justify-between">
                                  <div className="w-full">
                                    <span className="text-white">
                                      Educations:{" "}
                                    </span>
                                    <br />
                                    <div align="center">
                                      <Row>
                                        <Col
                                          className="p-2"
                                          style={{ backgroundColor: "#433c5f" }}
                                        >
                                          <span className="text-white">
                                            University
                                          </span>
                                        </Col>
                                        <Col
                                          className="p-2"
                                          style={{ backgroundColor: "#433c5f" }}
                                        >
                                          <span className="text-white">
                                            Degree
                                          </span>
                                        </Col>
                                      </Row>
                                      {JSON.parse(state.tutor.university).map(
                                        (university, index) => {
                                          return (
                                            <Row>
                                              <Col
                                                className="p-2"
                                                style={{
                                                  backgroundColor: "#38384a",
                                                }}
                                              >
                                                <span className="text-white">
                                                  {university}
                                                </span>
                                              </Col>
                                              <Col
                                                className="p-2"
                                                style={{
                                                  backgroundColor: "#38384a",
                                                }}
                                              >
                                                <span className="text-white">
                                                  {
                                                    JSON.parse(
                                                      state.tutor.degree
                                                    )[index]
                                                  }
                                                </span>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Container className="">
                                  <br />
                                  <div>
                                    <Row>
                                      <Col className="p-2" md={6}>
                                        <h5 className="text-white font-weight-normal">
                                          Tutor ID
                                        </h5>
                                        <Thumbnail
                                          imageUrl={state.tutor.id_file_link}
                                          title={"Tutor ID"}
                                        />
                                      </Col>
                                      <Col className="p-2" md={6}>
                                        <h5 className="text-white font-weight-normal">
                                          Grade Transcript
                                        </h5>
                                        <Thumbnail
                                          imageUrl={
                                            state.tutor.certification_file_link
                                          }
                                          title={"Grade Transcript"}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </Container>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer className="bg-secondary2">
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Student Price"}
                      initialValue={state.student_price}
                      onChange={(price) =>
                        setState({ ...state, student_price: price })
                      }
                    />
                  </div>
                  <div className=" mt-2">
                    <PriceSet
                      withButtons={false}
                      title={"Tutor Price"}
                      initialValue={state.tutor_price}
                      onChange={(price) =>
                        setState({ ...state, tutor_price: price })
                      }
                    />
                  </div>
                  <div className="mt-2 max-sm:py-2 w-16">
                    <label htmlFor="description">ID</label>
                    <input
                      value={state.id}
                      readOnly
                      className="form-control w-16 border-none mt-1"
                      rows={5}
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="label">Course Title</label>
                    <input
                      type="text"
                      name="label"
                      id="label"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.label}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.subject}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.country}
                      readOnly
                    />
                  </div>
                  <div className="max-sm:py-2">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.state}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-2 md:flex justify-between gap-10">
                  <div className="max-sm:py-2">
                    <label htmlFor="country">Language</label>
                    <input
                      type="text"
                      name="language"
                      id="language"
                      onChange={handleInput}
                      className="form-control border-none mt-1"
                      value={state.language}
                      readOnly
                    />
                  </div>
                  {state.assistance_type === "exam" && (
                    <div className="mt-2 max-sm:py-2">
                      <label htmlFor="">Exam Type</label>
                      <div className="flex min-[]: gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="subjective"
                            onChange={handleInput}
                            value={"Subjective"}
                            checked={
                              state.exam_type === "Subjective" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Subjective
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Both"
                            onChange={handleInput}
                            value={"Both"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="Both"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div>
                          <input
                            type="radio"
                            name="exam_type"
                            id="Don't Know"
                            onChange={handleInput}
                            value={"Don't Know"}
                            checked={
                              state.exam_type === "Don't Know" ? true : false
                            }
                            className="radio"
                          />
                          <label
                            htmlFor="subjective"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            Don't Know
                          </label>
                        </div>
                        <div className="items-end">
                          <input
                            type="radio"
                            name="exam_type"
                            id="MCQ"
                            onChange={handleInput}
                            value={"MCQ"}
                            checked={state.exam_type === "Both" ? true : false}
                            className="radio"
                          />
                          <label
                            htmlFor="MCQ"
                            className="font-normal m-1"
                            style={{ fontWeight: 400 }}
                          >
                            MCQ
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-2 w-full">
                  <label htmlFor="description">Additional Notes</label>
                  <textarea
                    style={{ resize: "none" }}
                    readOnly
                    className="form-control  border-none mt-1 w-full"
                    rows={5}
                  >
                    {state.notes}
                  </textarea>
                </div>
                <div className="mt-2 md:flex justify-between">
                  {(location.state.assistance_type === "exam" ||
                    location.state.assistance_type === "online") && [
                    <TimeComponent
                      title={"Start Time"}
                      initialvalue={state.time && state.time}
                      onChange={changetime}
                    />,
                    <div className="m-1">
                      <DurationSet
                        title={"Duration(minutes)"}
                        initialValue={state.duration}
                        onChange={changeduration}
                      />
                    </div>,
                  ]}
                </div>
                <div className="mt-2 my-3 w-fit">
                  <label className="text-white text-center font-bold">
                    Date to finish request
                  </label>
                  <MyDatePicker
                    value={state.date && new Date(Date.parse(state.date))}
                    onSelect={(date) => {
                      const dateObj = new Date(date);
                      const formattedDate = dateObj.toISOString().split("T")[0];
                      setState({
                        ...state,
                        date: formattedDate,
                        deadline: formattedDate,
                      });
                    }}
                  />
                </div>
                <div className="mt-2  md:max-w-xl">
                  <label htmlFor="">Description & Course Material </label>
                  <Slider2
                    items={JSON.parse(state.file_links)}
                    names={JSON.parse(state.file_names)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="bg-[#1E1E2E] h-[80vh] w-1 max-sm:hidden"></div>
          <div>
            <div className="text-white text-3xl mt-2 mb-3">Chat History</div>
            {state && (
              <div>
                <div class="iphone md:h-[80vh] md:w-72 md:ml-16">
                  {room ? (
                    <>
                      <div className="absolute z-[999] p-3 w-full bg-secondary">
                        <FaArrowLeft onClick={() => setRoom(null)} />
                      </div>
                      <div class="screen">
                        <div class="fix bg-secondary">
                          <FaArrowLeft />
                        </div>
                        <div
                          class="screen-content mt-10 overflow-scroll"
                          id="divref"
                          ref={divRef}
                        >
                          {state.rooms.find((roomItem) => roomItem.id === room)
                            ? state.rooms
                                .find((rooms) => rooms.id === room)
                                .messages.map((item) => {
                                  return (
                                    <div
                                      className={
                                        item.type === "admin"
                                          ? `direct-chat-msg right`
                                          : `direct-chat-msg`
                                      }
                                    >
                                      <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                          {item.sender_type === "tutor" &&
                                            (state.rooms.find(
                                              (rooms) => rooms.id === room
                                            ) &&
                                              state.rooms.find(
                                                (rooms) => rooms.id === room
                                              ).tutor.nickname) + "(Tutor)"}
                                          {item.sender_type === "student" &&
                                            state.student &&
                                            state.student.nickname}
                                          {item.sender_type === "admin" &&
                                            "Administrator"}
                                        </span>
                                      </div>
                                      <div>
                                        {item.message_type === "file" &&
                                          item.message.split(".").pop() ===
                                            "m4a" && (
                                            <div className="custom-audio-player w-full">
                                              <ReactAudioPlayer
                                                src={
                                                  process.env
                                                    .REACT_APP_MEDIAURL +
                                                  item.message
                                                }
                                                autoPlay={false}
                                                controls
                                              />
                                            </div>
                                          )}
                                      </div>
                                      {item.message_type === "text" ? (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {item.message}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            (item.sender_type === "tutor" &&
                                              `direct-chat-text bg-light`) ||
                                            (item.sender_type === "student" &&
                                              `direct-chat-text bg-primary`) ||
                                            (item.sender_type === "admin" &&
                                              `direct-chat-text bg-info`)
                                          }
                                        >
                                          {<ChatFile file={item.message} />}
                                          <div className="flex justify-end mt-1">
                                            <FaTrash
                                              onClick={() =>
                                                deleteMessage(
                                                  item.id,
                                                  item.sender_type,
                                                  item.message,
                                                  item.message_type
                                                )
                                              }
                                              color="red"
                                              size="15"
                                            />
                                          </div>
                                        </div>
                                      )}

                                      <span className="direct-chat-timestamp float-right">
                                        {new Date(
                                          item.created_at
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </span>
                                    </div>
                                  );
                                })
                            : state.chat.messages.map((item) => {
                                return (
                                  <div
                                    className={
                                      item.type === "admin"
                                        ? `direct-chat-msg right`
                                        : `direct-chat-msg`
                                    }
                                  >
                                    <div class="direct-chat-infos clearfix">
                                      <span class="direct-chat-name float-left">
                                        {item.sender_type === "tutor" &&
                                          state.chat.tutor.nickname + "(Tutor)"}
                                        {item.sender_type === "student" &&
                                          state.student &&
                                          state.student.nickname}
                                        {item.sender_type === "admin" &&
                                          "Administrator"}
                                      </span>
                                    </div>
                                    <div>
                                      {item.message_type === "file" &&
                                        item.message.split(".").pop() ===
                                          "m4a" && (
                                          <div className="custom-audio-player w-full">
                                            <ReactAudioPlayer
                                              src={
                                                process.env.REACT_APP_MEDIAURL +
                                                item.message
                                              }
                                              autoPlay={false}
                                              controls
                                            />
                                            <div className="flex justify-end mt-1">
                                              <FaTrash
                                                onClick={() =>
                                                  deleteMessage(
                                                    item.id,
                                                    item.sender_type,
                                                    item.message,
                                                    item.message_type
                                                  )
                                                }
                                                color="red"
                                                size="15"
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {item.message_type === "text" ? (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {item.message}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          (item.sender_type === "tutor" &&
                                            `direct-chat-text bg-light`) ||
                                          (item.sender_type === "student" &&
                                            `direct-chat-text bg-primary`) ||
                                          (item.sender_type === "admin" &&
                                            `direct-chat-text bg-info`)
                                        }
                                      >
                                        {<ChatFile file={item.message} />}
                                        <div className="flex justify-end mt-1">
                                          <FaTrash
                                            onClick={() =>
                                              deleteMessage(
                                                item.id,
                                                item.sender_type,
                                                item.message,
                                                item.message_type
                                              )
                                            }
                                            color="red"
                                            size="15"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <span className="direct-chat-timestamp float-right">
                                      {new Date(item.created_at).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                        </div>

                        <div class="fix bg-secondary">
                          <div class="form-row align-items-center">
                            <div class="col">
                              <input
                                type="text"
                                id="msg"
                                class="form-control"
                                placeholder="Type your message"
                                onChange={handleMsg}
                              />
                            </div>
                            <div class="col-auto">
                              <button
                                class="btn text-white"
                                onClick={sendMessage}
                              >
                                <AiOutlineSend />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="h-[100vh] overflow-scroll">
                      <span className="m-3 mt-3 text-white text-sm font-[600]">
                        Ongoing
                      </span>
                      <div
                        className="mx-3 mb-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                        onClick={() => {
                          setTimeout(() => {
                            if (divRef.current) {
                              const maxHeight = divRef.current.scrollHeight;
                              divRef.current.scrollTop = maxHeight;
                            }
                          }, 500);
                          setRoom(state.chat.id);
                        }}
                      >
                        {state.chat.tutor && state.chat.tutor.profile_image ? (
                          <img
                            className="w-8 h-8 rounded-full"
                            src={
                              process.env.REACT_APP_MEDIAURL +
                              state.chat.tutor.profile_image
                            }
                          />
                        ) : (
                          <MdAccountCircle className="w-8 h-8 rounded-full" />
                        )}
                        {state.chat.tutor.nickname}
                      </div>
                      <span className="m-3 mt-1 text-white text-sm font-[600]">
                        Pending
                      </span>

                      {state.rooms.map((room) => {
                        return (
                          <div
                            className="mx-3 flex items-center gap-1 border-b-2 py-2 cursor-pointer"
                            onClick={() => {
                              setTimeout(() => {
                                if (divRef.current) {
                                  const maxHeight = divRef.current.scrollHeight;
                                  divRef.current.scrollTop = maxHeight;
                                }
                              }, 500);
                              setRoom(room.id);
                            }}
                          >
                            {room.tutor.profile_image ? (
                              <img
                                className="w-8 h-8 rounded-full"
                                src={
                                  process.env.REACT_APP_MEDIAURL +
                                  room.tutor.profile_image
                                }
                              />
                            ) : (
                              <MdAccountCircle className="w-8 h-8 rounded-full" />
                            )}
                            {room.tutor.nickname}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default EditRequest;
