import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Barr({ years, values }) {
  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = years;

  const data = {
    labels,
    datasets: [
      {
        label: 'Exam Help',
        data: values ? values.map((item) => item.exam_help) : [],
        backgroundColor: 'rgb(0, 115, 255)',
        stack: 'Stack 0',
      },
      {
        label: 'H.W Help',
        data: values ? values.map((item) => item.homework) : [],
        backgroundColor: 'rgb(255, 230, 0)',
        stack: 'Stack 1',
      },
      {
        label: 'Project Help',
        data: values ? values.map((item) => item.project) : [],
        backgroundColor: 'rgb(139, 14, 228)',
        stack: 'Stack 2',
      },
      {
        label: 'Online Tutoring',
        data: values ? values.map((item) => item.online) : [],
        backgroundColor: 'rgb(1, 141, 1)',
        stack: 'Stack 3',
      },
      {
        label: 'Thesis Writing',
        data: values ? values.map((item) => item.thesis) : [],
        backgroundColor: 'rgb(248, 163, 4)',
        stack: 'Stack 4',
      },
      {
        label: 'SOS',
        data: values ? values.map((item) => item.sos) : [],
        backgroundColor: 'rgb(237, 30, 71)',
        stack: 'Stack 5',
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
