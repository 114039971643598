import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Pagination,
  Row,
  Spinner,
} from "react-bootstrap";
import { Avatar } from "@mui/material";
import { SlOptionsVertical } from "react-icons/sl";
import Thumbnail from "./Thumbnail";
import EditTeacherModal from "../modals/EditTeacherModal";
function TutorRequest({ data, rejectFunction, acceptFunction, restoreFunction,deleteFunction,updateFunction }) {
  const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(tutors.length / ITEMS_PER_PAGE);
  function getRequestsForCurrentPage() {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return tutors.slice(startIndex, endIndex);
  }
  async function AcceptFunction(id) {
    setLoading(true);
    acceptFunction(id).then(() => {
      setLoading(false);
    });
  }
  async function RejectFunction(id) {
    setLoading(true);
    rejectFunction(id).then(() => {
      setLoading(false);
    });
  }
  async function RestoreFunction(id) {
    setLoading(true);
    restoreFunction(id).then(() => {
      setLoading(false);
    });
  }
  async function updatefunction(state,id)
  {
    updateFunction(state,id).then(()=>{

    });
  }
  async function DeleteFunction(id) {
    setLoading(true);
    deleteFunction(id).then(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    setTutors(data);
  }, [data]);
  return [
    <Row>
      {getRequestsForCurrentPage().map((item) => {
        return (
          <Col md={6}>
            <Card className="bg-secondary rounded-xl">
              <Card.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
                <div>
                <div className="md:flex justify-between m-2">
                <div className="mt-2 flex">
                      <div className="flex justify-start max-sm:justify-between items-center">
                        <img
                          src={`${process.env.REACT_APP_MEDIAURL}/${
                            item && item.profile_image
                          }`}
                          className="rounded-full w-12 p-1"
                        />
                      <div className="text-primary">{item.full_name}</div>
                      <div className="text-white ml-3">ID:{item.id}</div>
                      </div>
                </div>
                <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                         <EditTeacherModal UpdateFunction={updateFunction} data={item} id={item.id} />
                        </Dropdown.Menu>
                        </Dropdown>
                </div>
                <div className="m-2 mt-3">
                  <span>Email:</span> <span className="text-primary">{item.email}</span>
                </div>
                <div className="m-2 mt-3">
                  <span>Bio Information</span>
                  <div>
                  <p className="text-[#CACAFF]" dangerouslySetInnerHTML={{ __html:item.bio }} />
                  </div>
                </div>
                <div className="m-2 mt-3 md:flex justify-between">
                <div>
                  <span>Phone Number: </span>
                  <span className="text-primary">{item.phone_country_code+item.phone}</span>
                </div>
                <div>
                  <span>WhatsApp Number: </span>
                  <span className="text-primary">{item.whatsapp_country_code+item.whatsapp_phone}</span>
                </div>
                </div>
                <hr className="bg-[#28293D] h-1 my-1 w-full" />
                <div className="m-2 mt-3 md:flex justify-between">
                <div className="w-full">
                  <div>Subjects: </div>
                  <div className="md:flex justify-start overflow-scroll">
                  {item.subjects.map((item) => {
                      return (
                        // <Col>
                          <div className="language_item m-1 py-1">
                            {item.subject.label}
                          </div>
                        // </Col>
                      );
                    })}
                  </div>
                </div>
                </div>
                <div className="m-2 mt-3 md:flex justify-between">
                <div className="w-full">
                  <div className="py-1">Languages: </div>
                  <div className="md:flex justify-start overflow-scroll">
                  {item.languages.map((item) => {
                      return (
                          <div className="language_item py-1 m-1">
                            {item.language.language_name}
                          </div>
                      );
                    })}
                  </div>
                </div>
                </div>
                <div className="m-2 mt-3 md:flex justify-between">
                  <div className="w-full">
                  <span className="text-white">
                    Educations:{" "}
                  </span>
                  <br />
                  <div align="center">
                    <Row>
                      <Col
                        className="p-2"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <span className="text-white">University</span>
                      </Col>
                      <Col
                        className="p-2"
                        style={{ backgroundColor: "#433c5f" }}
                      >
                        <span className="text-white">Degree</span>
                      </Col>
                    </Row>
                    {
                      JSON.parse(item.university).map((university,index)=>{
                        return <Row>
                        <Col
                          className="p-2"
                          style={{ backgroundColor: "#38384a" }}
                        >
                          <span className="text-white">{university}</span>
                        </Col>
                        <Col
                          className="p-2"
                          style={{ backgroundColor: "#38384a" }}
                        >
                          <span className="text-white">{JSON.parse(item.degree)[index]}</span>
                        </Col>
                      </Row>
                      })
                    }

                  </div>
                  </div>
                </div>
                <Container className="">
                  <br />
                  <div>
                    <Row>
                      <Col className="p-2" md={6}>
                        <h5 className="text-white font-weight-normal">
                          Tutor ID
                        </h5>
                        <Thumbnail
                          imageUrl={item.id_file_link}
                          title={"Tutor ID"}
                        />
                      </Col>
                      <Col className="p-2" md={6}>
                        <h5 className="text-white font-weight-normal">
                          Grade Transcript
                        </h5>
                        <Thumbnail
                          imageUrl={item.certification_file_link}
                          title={"Grade Transcript"}
                        />
                      </Col>
                    </Row>
                  </div>
                </Container>
                </div>
              </Card.Body>
              <Card.Footer className="m-0 p-0">
              {
                    item.verified===0 ?
                    <Card.Footer className="">
                  <div align="center">
                    <Row>
                      <Col className="" md={6}>
                      {loading ? (
                          <Button variant="danger" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button
                          className="bg-red-500"
                            variant="danger"
                            onClick={() => {
                              RejectFunction(item.id);
                            }}
                          >
                            Reject Request
                          </Button>
                        )}
                      </Col>
                      <Col className="" md={6}>
                        {loading ? (
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            onClick={() => {
                              AcceptFunction(item.id);
                            }}
                          >
                            Accept Request
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                    </Card.Footer>
                    :
                    <></>
                }
                {
                    item.verified===1 && item.deleted_at===null ?
                    // <Container className="w-100">
                    <Card.Footer className="">
                  <div align="center">
                    <Row>
                      <Col className="" md={12}>
                        {loading ? (
                          <Button variant="danger" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            className="bg-red-500"
                            variant="danger"
                            onClick={() => {
                              DeleteFunction(item.id);
                            }}
                          >
                            Delete Tutor
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                  </Card.Footer>
                    // </Container>
                    :
                    <></>
                }
                {
                    item.deleted_at!=null ?
                    <Card.Footer className="">
                  <div align="center">
                    <Row>
                      <Col className="" md={12}>
                        {loading ? (
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            onClick={() => {
                              RestoreFunction(item.id);
                            }}
                          >
                            Restore Tutor
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                    </Card.Footer>
                    :
                    <></>
                }
              </Card.Footer>
            </Card>
          </Col>
        );
      })}
    </Row>,
    <Container className="d-flex justify-content-center mt-3">
    <Pagination>
      {currentPage > 1 && (
        <Pagination.Item onClick={() => setCurrentPage(currentPage - 1)}>
          &laquo;
        </Pagination.Item>
      )}
      {Array.from({ length: pageCount }).map((_, index) => {
        if (
          index === 0 ||
          index === pageCount - 1 ||
          (index >= currentPage - 2 && index <= currentPage)
        ) {
          return (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          );
        } else if (index === 1 && currentPage > 4) {
          return <Pagination.Ellipsis key={index} disabled />;
        } else if (index === pageCount - 2 && currentPage < pageCount - 3) {
          return <Pagination.Ellipsis key={index} disabled />;
        }
        return null;
      })}
      {currentPage < pageCount && (
        <Pagination.Item onClick={() => setCurrentPage(currentPage + 1)}>
          &raquo;
        </Pagination.Item>
      )}
    </Pagination>
    </Container>,
  ];
}

export default TutorRequest;
