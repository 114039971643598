import React,{useState,useEffect,useContext} from 'react'
import { Form } from 'react-bootstrap'
import Axios from '../../Utils/Axios';
import Logo from "../../assets/logo-new.jpg";
import { requestPermission } from '../../Firebase';
import Spinner from 'react-bootstrap/Spinner';

function Login() {
    const [email,setEmail]=useState('');
    const [loading,setLoading]=useState(false);
    const [password,setPassword]=useState('');
    const [token,setToken]=useState('');
    const [validated,setValidated]=useState(false);
      async function getToken()
  {
    setToken(await requestPermission());
  }
  useEffect(()=>{
    getToken();
  },[]);
    async function handleLogin(event)
    {
        setLoading(true);
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === true) {
         return await Axios.post('/auth/login',{email:email,password:password,token:token}).then((response)=>{

          localStorage.setItem("token",response.data.token);
            window.location.href="/dashboard";
          }).catch((error)=>{
            setLoading(false)
          }).finally(()=>{
            setLoading(false)
          });
        }
        setValidated(true);
}
  return (
    <div className="login">
    <img src={Logo} alt="" />
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <Form noValidate validated={validated} onSubmit={handleLogin}>
              <div className="input-group mb-3">
                <input
                  required
                  type="email"
                  value={email}
                  name="email"
                  onChange={(e)=>{setEmail(e.target.value)}}
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  Please enter email address.
                </Form.Control.Feedback>
              </div>

              <div className="input-group mb-3">
                <input
                  required
                  value={password}
                  type="password"
                  name="password"
                  onChange={(e)=>{setPassword(e.target.value);}}
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  Please enter your password.
                </Form.Control.Feedback>
              </div>
              <div className="row">
                <div className="col-12">
                  {
                    loading?
                    <div className='flex justify-center items-center'>
                    <Spinner className='text-white' />
                    </div>
                    :
                  <button
                    type="submit"
                    // onClick={signin}
                    className="btn btn-primary btn-block"
                  >
                    Sign In
                  </button>

                  }
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

    </div>
    </div>
  )
}

export default Login
