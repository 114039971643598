import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  user: null,
};
export const UserReducer = createReducer(initialState, {
  LoadUserRequest: (state) => {
    state.loading = true;
  },
  LoadUserSuccess: (state, action) => {
    state.isAuth = true;
    state.loading = false;
    state.user = action.payload;
  },
  LoadUserFailed: (state, action) => {
    state.loading = false;
    (state.error = action.payload), (state.isAuth = false);
  },
  clearErrors: (state) => {
    state.error = null;
  },
});
