import React, { useState, useEffect, useContext } from "react";
import {
  Tab,
  Tabs,
  Row,
  Button,
  Pagination,
  Dropdown,
  Table,
  Modal,
  Col
} from "react-bootstrap";
import { AiFillFileImage, AiFillFilePdf, AiFillStar, AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { FiCalendar } from "react-icons/fi";
import Axios from "../../Utils/Axios";
import Requests from "../../components/Requests";
import { UserContext } from "../../providers/User";
import { SlOptionsVertical } from "react-icons/sl";
import { FaPen } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import DeleteRequestModal from "../../modals/DeleteRequestModal";
import { BsCardText, BsCurrencyDollar, BsTable } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { SiMicrosoftexcel, SiMicrosoftpowerpoint, SiMicrosoftword } from "react-icons/si";
import PriceSet from "../../components/PriceSet";
import { useSelector } from "react-redux";
import messaging from "../../Firebase";
import { onMessage } from "firebase/messaging";
import { enqueueSnackbar } from "notistack";

function HomeWorkHelp() {
  const location=useLocation();
  const [table, setTable] = useState(false);
  const user=useSelector((state)=>state.user).user;
  // const { user } = useContext(UserContext);
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "short" });
  const year = today.getFullYear();
  const [requests, setRequests] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [canceled, setCanceled] = useState([]);
  async function getRequests() {
    await Axios.get("/requests")
      .then((response) => {
        // setRequests(response.data.requests);
        setRequests(response.data.requests.filter((item)=>(item.assistance_type==="homework" || item.assistance_type==="Homework")));
        setDeleted(response.data.deleted_requests.filter((item)=>(item.assistance_type==="homework" || item.assistance_type==="Homework")));
        setCanceled(response.data.canceled_requests.filter((item)=>item.assistance_type==="homework"&&item.deleted_at===null&&item.cancelled===1));

      })
      .catch((e) => {});
  }
  async function setPrice()
  {
    getRequests();
  }
async function deleteFunction()
{
 getRequests();
}

  useEffect(() => {
    getRequests();
    onMessage(messaging, (payload) => {
      enqueueSnackbar(payload.notification.title, { variant: "info", anchorOrigin: { horizontal: "right", vertical: "top" } });

      getRequests();
      });
  }, []);
    const [show, setShow] = useState(false);
  const [priceStudentValue, setStudentPriceValue] = useState(0);
  const [priceTutorValue, setTutorPriceValue] = useState(0);
  async function setPricee(id) {
    (location.pathname==="/exam-help" || location.pathname==="/homework-help" || location.pathname==="/project-help" || location.pathname==="/thesiswriting-help")&&
    await Axios.post(`/requests/${id}/update-prices`,{ student_price: priceStudentValue, tutor_price: priceTutorValue }).then((response)=>{
      setShow(false);
      getRequests();
    }).catch((error)=>{});
    location.pathname==="/onlinetutoring-help"&&
    await Axios.post(`/online/${id}/update-prices`,{ student_price: priceStudentValue, tutor_price: priceTutorValue }).then((response)=>{
      setShow(false);
      getRequests();
    }).catch((error)=>{});
}
  const handleStudentPriceChange = (newPrice) => {
    setStudentPriceValue(newPrice);
  };
  const handleTutorPriceChange = (newPrice) => {
    setTutorPriceValue(newPrice);
  };
  async function DeleteFunction(id) {
    (location.pathname==="/exam-help" || location.pathname==="/homework-help" || location.pathname==="/project-help" || location.pathname==="/thesiswriting-help")&&
    await Axios.delete(`/requests/${id}`)
    .then((response) => {
      deleteFunction();
    })
    .catch((error) => {});
  }
  return (
    <div className="m-3">
      <div className="d-flex justify-content-between">
        <h2 className="text-3xl">Home Work Help</h2>
        <div>
          <Button variant="outline-light flex" className="m-1">
            <FiCalendar className="icon" />
            {day + " " + month + ", " + year}
          </Button>
        </div>
      </div>
      <br />
      <div align="right">
      <Button onClick={()=>{setTable(!table);}} variant="primary" className="flex justify-center items-center">{table?<><BsCardText />&nbsp;Card View</>:<><BsTable />&nbsp;Table View</>}</Button>
      </div>
      <Tabs
        style={{ width:"fit-content" }}
        defaultActiveKey="new_request"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="new_request" title={`New Requests (${requests.filter(
                (item) => item.request_status==="new"
              ).length})`}>
          {table?
            <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="new"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Button
                    variant="primary"
                    className="flex justify-center items-center w-100"
                    onClick={()=>{setShow(true);}}
                  >
                    <BsCurrencyDollar /> Change Price
                  </Button>
                  <Modal
                    show={show}
                    onHide={()=>{setShow(false);}}
                    className="text-white"
                    centered
                  >
                    <Modal.Body className="bg-secondary2" align="center">
                      <Row>
                        <Col md={6}>
                          <PriceSet
                            name={"student_price"}
                            title={"Student Price"}
                            onChange={handleStudentPriceChange}
                          />
                        </Col>
                        <Col md={6}>
                          <PriceSet
                            name={"tutor_price"}
                            title={"Tutor Price"}
                            onChange={handleTutorPriceChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col align="center">
                          <Button variant="primary" onClick={()=>{setPricee(item.id)}}>
                            Done
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
            :
            <Requests type={"new"} setpriceFunction={setPrice} tbl={"homework"}   deletFunction={deleteFunction} requests={requests.filter(
          (item) => item.request_status==="new"
        )} />
        }

        </Tab>
        <Tab eventKey="pending_accept" title={`Pending Acceptance (${requests.filter(
                (item) => item.request_status==="pending"
              ).length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="pending"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Button
                    variant="primary"
                    className="flex justify-center items-center w-100"
                    onClick={()=>{setShow(true);}}
                  >
                    <BsCurrencyDollar /> Change Price
                  </Button>
                  <Modal
                    show={show}
                    onHide={()=>{setShow(false);}}
                    className="text-white"
                    centered
                  >
                    <Modal.Body className="bg-secondary2" align="center">
                      <Row>
                        <Col md={6}>
                          <PriceSet
                            name={"student_price"}
                            title={"Student Price"}
                            onChange={handleStudentPriceChange}
                            initialValue={item.student_price}
                          />
                        </Col>
                        <Col md={6}>
                          <PriceSet
                            name={"tutor_price"}
                            title={"Tutor Price"}
                            onChange={handleTutorPriceChange}
                            initialValue={item.tutor_price}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col align="center">
                          <Button variant="primary" onClick={()=>{setPricee(item.id)}}>
                            Done
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
        :
          <Requests type={"pending"} setpriceFunction={setPrice} tbl={"homework"} deletFunction={deleteFunction}  requests={requests.filter(
      (item) => item.request_status==="pending"
    )} />
          }
        </Tab>
        <Tab eventKey="waiting_payment" title={`Waiting Payment (${requests.filter(
                (item) => item.request_status==="waiting_payment"
              ).length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Payment State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="waiting_payment"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.paid===1?<div className="text-white" style={{ color:"white" }}><AiOutlineCheck  className="icon" /> Paid</div>:<div  style={{ color:"white" }} className="text-white"><VscChromeClose  className="icon" /> Not Paid</div>}</td>                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <div className="d-flex justify-content-around">
                  <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
        :
        <Requests type={"waiting"} deletFunction={deleteFunction} tbl={"homework"} requests={requests.filter(
      (item) => item.request_status==="waiting_payment"
    )} />}
        </Tab>
        <Tab eventKey="ongoing" title={`Ongoing (${requests.filter(
                (item) => item.request_status==="ongoing"
              ).length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Payment State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="ongoing"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.paid===1?<div className="text-white" style={{ color:"white" }}><AiOutlineCheck  className="icon" /> Paid</div>:<div  style={{ color:"white" }} className="text-white"><VscChromeClose  className="icon" /> Not Paid</div>}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <Dropdown drop="start" className="bg-secondary">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
        :
        <Requests type={"ongoing"} deletFunction={deleteFunction} tbl={"homework"} requests={requests.filter(
      (item) => item.request_status==="ongoing"
    )} />}
        </Tab>
        <Tab eventKey="complete" title={`Complete (${requests.filter(
                (item) => item.request_status==="completed"
              ).length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Ratings</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>

              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              requests.filter(
                (item) => item.request_status==="completed"
              ).map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.rating} <AiFillStar className="icon" style={{ color:"yellow" }} /></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>

                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <Dropdown drop="start" className="bg-secondary">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              })
            }
          </tbody>
        </Table>
        :
        <Requests type={"completed"} deletFunction={deleteFunction} tbl={"homework"} requests={requests.filter(
      (item) => item.request_status==="completed"
    )} />}
        </Tab>
        <Tab eventKey="deleted" title={`Deleted (${deleted.length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Ratings</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Exam Type</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              {/* <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operations</th> */}
            </tr>
          </thead>
          <tbody>
            {
              deleted.map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.rating} <AiFillStar className="icon" style={{ color:"yellow" }} /></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.exam_type}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{ whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                  {/* <td style={{ width:"1px",whiteSpace:"nowrap" }}>
                  <Dropdown drop="start" className="bg-secondary">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="dropdown-actions-button"
                      >
                        <SlOptionsVertical size={"30px"} className="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-secondary2 p-1">
                      {JSON.parse(user.permissions).includes("/request/edit")&&(
                      <Link
                        to={"/request/edit"}
                        state={{ id: item.id,type:"requests",assistance_type:item.assistance_type }}
                        className="btn btn-primary flex justify-center items-center p-2 my-1 w-100"
                      >
                        <FaPen className="m-1" />
                        Edit Record
                      </Link>
                        )}

                        <br />
                        {JSON.parse(user.permissions).includes("/request/delete")&&(
                        <DeleteRequestModal
                          DeleteFunction={() => {
                            DeleteFunction(item.id);
                          }}
                          id={item.id}
                        />)}
                        <br />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td> */}
                </tr>
              })
            }
          </tbody>
        </Table>
        :
        <Requests type={"new"} deletFunction={deleteFunction} tbl={"homework"} requests={deleted} />}
        </Tab>
        <Tab eventKey="canceled" title={`Canceled (${canceled.length})`}>
        {table?
        <Table responsive striped className="full-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Tutor Price</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Student Nickname</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Ratings</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Title</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Subject</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Exam Type</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Language</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Country</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>State</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Date</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Start Time</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Duration</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Expected Grade</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Description</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Course Material</th>
              <th style={{ width:"1px",whiteSpace:"nowrap" }}>Additional Notes</th>
              {/* <th style={{ width:"1px",whiteSpace:"nowrap" }}>Operations</th> */}
            </tr>
          </thead>
          <tbody>
            {
              canceled.map((item)=>{
                return <tr>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.id}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student_price?item.student_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.tutor_price?item.tutor_price:"Not Set"}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.student&&item.student.nickname}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.rating} <AiFillStar className="icon" style={{ color:"yellow" }} /></td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.label}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.subject}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.exam_type}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.language}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.country}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.state}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.date}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.time}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.duration}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}>{item.grade}</td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.description}</div></td>
                  <td style={{ whiteSpace:"nowrap",overflow:"scroll" }} className="d-flex">
                    {
                      item.file_links&&JSON.parse(item.file_links).map((file)=>{
                        const ext=file.split('.').pop();
                        return (
                          <div align="center"  className="m-1">
                            {(ext==="png"||ext==="jpg"||ext==="jpeg")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFileImage className="icon2" /></a>}
                            {(ext==="pdf")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><AiFillFilePdf className="icon2" /></a>}
                            {(ext==="doc"||ext==="docx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftword className="icon2" /></a>}
                            {(ext==="xls"||ext==="xlsx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftexcel className="icon2" /></a>}
                            {(ext==="ppt"||ext==="pptx")&&<a target="_blank" href={`${process.env.REACT_APP_MEDIAURL}${file}`} className="text-white"><SiMicrosoftpowerpoint className="icon2" /></a>}
                          </div>
                        )
                      })
                    }
                  </td>
                  <td style={{ width:"1px",whiteSpace:"nowrap" }}><div style={{ maxWidth: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.notes}</div></td>
                </tr>
              })
            }
          </tbody>
        </Table>
        :
        <Requests type={"new"} deletFunction={deleteFunction} tbl={"homework"} requests={canceled} />}
        </Tab>
      </Tabs>
    </div>
  );
}

export default HomeWorkHelp;
