import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB-KcRHNWX7HHWJ9QonxhGZY7ksXHMlJQU",
  authDomain: "our-easy-game.firebaseapp.com",
  projectId: "our-easy-game",
  storageBucket: "our-easy-game.appspot.com",
  messagingSenderId: "952406139252",
  appId: "1:952406139252:web:42772b165f60a0a5cc4c21",
  measurementId: "G-03FLL6722R"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const currentToken = await getToken(messaging, { vapidKey: "BMPLtanv6LJHnfSPJm3_Vf7Q7E3sHnolOdWX043jhJJy2w5znMomOrWWz_yjOjl-w7ac4601eAK6Ts_3lCNQ0Bs" });
      if (currentToken) {
        console.log("Token:", currentToken);
        return currentToken;
      } else {
        console.error('Failed to generate the app registration token.');
      }
    } else {
      console.log("User Permission Denied.");
      return null;
    }
  } catch (error) {
    console.error('Service worker registration failed or token request error:', error);
  }
};
if(messaging)
onMessage(messaging, (payload) => {
  console.log("Message received while app is in the foreground:", payload);
});

requestPermission();
export default messaging;
