import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useContext,useState, useEffect} from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './pages/Auth/Login';
import Index from './pages/dashboard/Index';
import Master from './layouts/Master';
import ExamHelp from './pages/dashboard/ExamHelp';
import EditRequest from './pages/screens/EditRequest';
import Subjects from './pages/dashboard/Subjects';
import Students from './pages/dashboard/Students';
import Tutors from './pages/dashboard/Tutors';
import HomeWorkHelp from './pages/dashboard/HomeWorkHelp';
import ProjectHelp from './pages/dashboard/ProjectHelp';
import ThesisWritingHelp from './pages/dashboard/ThesisWriting';
import OnlineTutoringHelp from './pages/dashboard/OnlineTutoringHelp';
import SOSHelp from './pages/dashboard/SOSHelp';
import BoradingTips from './pages/dashboard/BoradingTips';
import Faqs from './pages/dashboard/Faqs';
import SocialMedia from './pages/dashboard/SocialMedia';
import NewsUpdates from './pages/dashboard/NewsUpdates';
import TermsConditions from './pages/dashboard/TermsConditions';
import Notifications from './pages/dashboard/Notifications';
import Users from './pages/dashboard/Users';
import Roles from './pages/dashboard/Roles';
import NotFound from './errors/NotFound';
import EditOnline from './pages/screens/EditOnline';
import EditSOS from './pages/screens/EditSOS';
import Edit from './pages/dashboard/Edit';
import "normalize.css";
import Store from './redux/store';
import { LoadUser } from './redux/actions/UserActions';
import { useSelector } from 'react-redux';
import Classrooms from './pages/dashboard/Classrooms';
import Courses from './pages/dashboard/Courses';
import Lectures from './pages/dashboard/Lectures';
import {onMessageListener, requestPermission} from "./Firebase";
import CustomerSupport from './pages/dashboard/CustomerSupport';
function App() {
  // async function getFunction()
  // {
  //   console.log(await requestPermission());
  // }
  // useEffect(()=>{
  //   getFunction()
  // },[]);
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<Login />} />
      <Route exact path='/login' element={<Login />} />
      <Route element={<Master />}>
      <Route exact path='/dashboard' element={<Index />} />
      <Route exact path='/exam-help' element={<ExamHelp />} />
      <Route exact path='/request/edit' element={<Edit />} />
      <Route exact path='/homework-help' element={<HomeWorkHelp />} />
      <Route exact path='/project-help' element={<ProjectHelp />} />
      <Route exact path='/thesiswriting-help' element={<ThesisWritingHelp />} />
      <Route exact path='/onlinetutoring-help' element={<OnlineTutoringHelp />} />
      <Route exact path='/sos-help' element={<SOSHelp />} />
      <Route exact path='/classrooms' element={<Classrooms />} />
      <Route exact path='/courses' element={<Courses />} />
      <Route exact path='/lectures' element={<Lectures />} />
      <Route exact path='/onboarding-tips' element={<BoradingTips />} />
      <Route exact path='/faqs' element={<Faqs />} />
      <Route exact path='/social-media' element={<SocialMedia />} />
      <Route exact path='/news-updates' element={<NewsUpdates />} />
      <Route exact path='/terms-conditions' element={<TermsConditions />} />
      <Route exact path='/notifications' element={<Notifications />} />
      <Route exact path='/subjects' element={<Subjects />} />
      <Route exact path='/students' element={<Students />} />
      <Route exact path='/tutors' element={<Tutors />} />
      <Route exact path='/customer-support' element={<CustomerSupport />} />
      <Route exact path='/users' element={<Users />} />
      <Route exact path='/roles' element={<Roles />} />
      <Route exact path="/not-found" element={<NotFound />} />
      </Route>
      <Route exact path="/*" element={<NotFound />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
