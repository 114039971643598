import React, { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Modal } from "react-bootstrap";
import { SiEclipseide } from "react-icons/si";
function ChatFile({ file }) {
  const [type, setType] = useState("");
  useEffect(() => {
    const ext = file.split(".").pop();
    if (ext === "m4a") setType("voice");
    else if (ext === "pdf") setType("pdf");
    else if (
      ext === "ppt" ||
      ext === "pptx" ||
      ext === "doc" ||
      ext === "docx" ||
      ext === "xlsx"
    )
      setType("office");
    else if (ext === "jpg" || ext === "png" || ext === "jpeg") setType("image");
    else setType("file");
  }, [file]);
  if (type === "voice")
    return (
      <div className="w-72 p-1">
        <ReactAudioPlayer
          src={process.env.REACT_APP_MEDIAURL + file}
          autoPlay={false}
          controls
        />
      </div>
    );
  else if (type === "office")
    return (
      <div className="w-72 p-1">
        <a
          className="underline text-blue-500"
          target="_blank"
          href={`${process.env.REACT_APP_MEDIAURL + file}`}
        >
          Office File
        </a>
      </div>
    );
  else if (type === "pdf")
    return (
      <div className="w-72 p-1">
        <a
          className="underline text-blue-500"
          target="_blank"
          href={`${process.env.REACT_APP_MEDIAURL + file}`}
        >
          PDF File
        </a>
      </div>
    );

  else if(type==="image") return <ShowPreview image={process.env.REACT_APP_MEDIAURL + file} />;
  SiEclipseide
    return (
      <div className="w-72 p-1">
        <a
          className="underline text-blue-500"
          target="_blank"
          href={`${process.env.REACT_APP_MEDIAURL + file}`}
        >
          File
        </a>
      </div>
    );
}

export default ChatFile;

function ShowPreview({ image }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <img src={image} className="w-72" onClick={() => setShow(true)} />
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <img src={image} />
        </Modal.Body>
      </Modal>
    </>
  );
}
