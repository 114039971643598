import React from 'react'
import { useLocation } from 'react-router-dom'
import EditRequest from '../screens/EditRequest';
import EditSOS from '../screens/EditSOS';
import EditOnline from '../screens/EditOnline';

function Edit() {
    let location=useLocation();
    let type=location.state.type
  return (
    <div>{type==="requests"&&(<EditRequest />)}{type==="online"&&(<EditRequest />)}{type==="sos"&&(<EditSOS />)}</div>
  )
}

export default Edit
