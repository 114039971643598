import React, { useEffect, useState } from 'react'
import {Button,Modal, Table} from "react-bootstrap";
function ClassRoomStudents({students}) {
    const [show,setShow]=useState(false);
    const [data,setData]=useState([]);
    useEffect(()=>{
        setData(students);
    },[])
  return [
    <Button variant="primary" onClick={()=>setShow(true)}>Registered Students</Button>,
    <Modal show={show} centered onHide={()=>setShow(false)} className='text-white' size='lg'>
        <Modal.Header className='bg-secondary2'>Registered Students</Modal.Header>
        <Modal.Body className='bg-secondary2'>
        <Table striped>
            <thead>
                <tr>
                    <th>Nickname</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                {data.map((student)=>{
                    return <tr>
                        <td>{student.student.nickname}</td>
                        <td>{student.student.email}</td>
                        <td>{student.student.phone_number}</td>
                    </tr>
                })}
            </tbody>
        </Table>
        </Modal.Body>
        <Modal.Footer className='bg-secondary2'>

        </Modal.Footer>
    </Modal>
  ]
}

export default ClassRoomStudents
