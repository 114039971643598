import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlus } from 'react-icons/ai';
import { CKEditor } from 'ckeditor4-react';
function AddOnBoardingTipsModel({AddFunction}) {
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [state, setState] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleImage = (e) => {
    const file = e.target.files[0];
    var output = document.getElementById('img');
    output.src = URL.createObjectURL(file);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
    setState((prevState)=>({ ...prevState, image: file }));
  };


const handleEditorChange = (event) => {
    const content = event.editor.getData();
    setState((prevState) => ({ ...prevState, details: content }));
  };

    async function addNew(e)
    {
        e.preventDefault();
    if (e.currentTarget.checkValidity()) {
        AddFunction(state);
        handleClose();
    }
    else setValid(true);
    }

  return (
    <>
      <Button variant='primary' onClick={handleShow} className='flex items-center m-1'><AiOutlinePlus /> Add New Record</Button>

      <Modal show={show} onHide={handleClose} centered size='lg' className='text-white'>
        <Modal.Header className='bg-secondary2'>
          <Modal.Title>Add New Record</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={valid} id='frm2' onSubmit={addNew}>
        <Modal.Body className='bg-secondary2'>
        <div className="mb-3">
        <label>Image</label>
        <br />
        <img id="img" width={"50%"} />
                <input
                  required
                  type="file"
                  name="image"
                  onChange={handleImage}
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose an image.
                </Form.Control.Feedback>
        </div>
        <div className="mb-3">
        <label>Title</label>
                <input
                  required
                  type="text"
                  name="title"
                  onChange={(e)=>setState((prevState) => ({...prevState,title:e.target.value}))}
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a title.
                </Form.Control.Feedback>
        </div>
        <div className="mb-3">
        <label>Details</label>
        <CKEditor required onChange={handleEditorChange} />
        <Form.Control.Feedback type="invalid">
                  Please enter the details.
                </Form.Control.Feedback>
        </div>
        </Modal.Body>
        <Modal.Footer className='bg-secondary2'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type='submit' variant="primary">
            Save
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddOnBoardingTipsModel;
