import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import Logo from "../assets/logo-new.jpg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  FaChartBar,
  FaRegBell,
  FaRegFileAlt,
  FaUserGraduate,
  FaUsers,
} from "react-icons/fa";
import { BsFillPersonFill, BsNewspaper, BsPersonCircle, BsShare } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import { RxDashboard } from "react-icons/rx";
import { GoBook, GoSignOut } from "react-icons/go";
import { GiTeacher } from "react-icons/gi";
import {
  AiOutlineFileDone,
  AiOutlineFileProtect,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import {TbBooks} from "react-icons/tb"
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsPhoneVibrate } from "react-icons/bs";
import { MdLiveTv, MdOutlineSupportAgent } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { RiUserSettingsLine } from "react-icons/ri";
import Avatar from "@mui/material/Avatar";
import Axios from "../Utils/Axios";
import DropDownLinks from "../components/DropDownLinks";
import { onMessage } from "firebase/messaging";
import messaging from "../Firebase";
import { enqueueSnackbar } from "notistack";
function Header({ user }) {
  let location = useLocation();
  const [filter, setFilter] = useState(null);
  const [modal, setModal] = useState(false);
  const [prof, setProf] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  const [state, setState] = useState(null);
  const [counts, setCounts] = useState(null);
  async function getCounts()
  {
    await Axios.get("/requests/counts").then((response)=>{
      setCounts(response.data.counts);
    }).catch((error)=>{})
  }
  useEffect(()=>{
    user&&setState({
      'username':user&&user.name,
      'email':user&&user.email
    });
    getCounts();
    onMessage(messaging, (payload) => {
      enqueueSnackbar(payload.notification.title, { variant: "info", anchorOrigin: { horizontal: "right", vertical: "top" } });
      getCounts();
    });
  },[]);



  async function updateInfo(e) {

    e.preventDefault();
    await Axios.post(`/users/update/${user.id}`,state).then((response)=>{
        window.location.reload();
        handleClose();
    }).catch((error)=>{});
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    var output = document.getElementById('img');
    output.innerHTML="";
    output.innerHTML=`<img src=${URL.createObjectURL(file)} width='120px' height='120px' />`
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    setState((prevState)=>({ ...prevState, image: file }));
  };


  return [
    <nav className="main-header navbar navbar-expand text-white">
      <ul className="navbar-nav">
        <li className="nav-item">
          <span
            className="nav-link `si`debar-toggle-btn"
            data-widget="pushmenu"
            role="button"
          >
            <i className="fas fa-bars"></i>
          </span>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <DropdownButton
            id={`dropdown-variants`}
            variant="secondary"
            title={
              <Row
                align="center"
                className="justify-content-between align-items-center"
              >
                <Col md={3}>
                  <Avatar src={process.env.REACT_APP_MEDIAURL_SECOND+""+user.picture} sx={{ width: 30, height: 30 }} />
                </Col>
                <Col md={8} align="left" className="ml-1">
                  <div>
                    <span>{user.name} </span>
                    <span style={{ fontSize: "12px" }}>({user.role})</span>
                  </div>
                </Col>
              </Row>
            }
          >
            <Dropdown.Item eventKey="1" onClick={() => setProf(true)}>Profile</Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => setModal(true)}>Logout</Dropdown.Item>
          </DropdownButton>
          {/* <span className="nav-link" title="Logout" onClick={() => setModal(true)}>
            <GoSignOut className="icon" />
          </span> */}
        </li>
      </ul>
    </nav>,
    <Modal
      centered
      show={modal}
      onHide={() => {
        setModal(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Alert!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to logout?</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          No
        </button>
        <button className="btn btn-danger" onClick={handleLogout}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>,
        <Modal
        className="text-white"
        centered
        size="lg"
        show={prof}
        onHide={() => {
          setProf(false);
        }}
      >
        <Modal.Header className="bg-secondary2">
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={updateInfo} autoComplete="off">
        <Modal.Body className="bg-secondary2">
            <div className="m-3">
          <Row>
            <Col md={4}>
            <h5 className="text-white mb-3">Profile Picture</h5>
            <div className="image-chooser">
            <center>
              <div id="img">
              {user.picture===""?
              <>
               <BsFillPersonFill className="icon icon-chooser" />
              <br />
              <span className="text-white">Drag & drop image</span>
              <br />
              <span className="text-white">Or</span>
              </>
              :
              <>
               <img src={process.env.REACT_APP_MEDIAURL_SECOND+""+user.picture} width={"120px"} height={"120px"}  />
              </>
              }
                </div>
              <br />
              <label for="customFile" class="custom-file-button bg-primary">
                Upload Image
                <input type="file" id="customFile" required onChange={handleImage} />
              </label>
            </center>
          </div>
            </Col>
            <Col md={8}>
            <label>User Name</label>
            <input type="text" name="" id="" className="form-control" required onChange={(e)=>setState({...state,username:e.target.value})} value={state&&state.username} />
            <br />
            <label>Email</label>
            <input type="text" name="" id="" className="form-control" required onChange={(e)=>setState({...state,email:e.target.value})} value={state&&state.email} />
            </Col>
          </Row>
          <br />
          <Row className="justify-content-around">
            <Col md={4}>
            <label>Password</label>
            <input type="password" name="" required id="" autoComplete="new-password" onChange={(e)=>setState({...state,password:e.target.value})} className="form-control" />
            <br />
            </Col>
            <Col md={4}>
            <label>Confirm Password</label>
            <input type="password" name="" required id="" onChange={(e)=>setState({...state,confirm_password:e.target.value})} className="form-control" />
            <br />

            </Col>
          </Row>
            </div>
        </Modal.Body>
        <Modal.Footer className="bg-secondary2">
          <Button type="submit" variant="primary">
            Update Profile
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>,
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div className="sidebar mt-3">
        <div className="form-inline d-flex align-items-center justify-content-center text-white">
          <img src={Logo} alt="" width={"50%"} height={"50%"} />
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="true"
          >
            {user.permissions.includes("/dashboard") && (
              <li className="nav-item">
                <NavLink
                  to={"/dashboard"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <RxDashboard className="icon" />
                  <p>Dashboard</p>
                </NavLink>
              </li>
            )}
                        {user.permissions.includes("/customer-support") && (
              <li className="nav-item">
                <NavLink
                  to={"/customer-support"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <MdOutlineSupportAgent className="icon" />
                  <p>Customer Support</p>
                </NavLink>
              </li>
            )}
            {(user.permissions.includes("/exam-help") ||
              user.permissions.includes("/homework-help") ||
              user.permissions.includes("/project-help") ||
              user.permissions.includes("/thesiswriting-help") ||
              user.permissions.includes("/onlinetutoring-help") ||
              user.permissions.includes("/sos-help")) && (
              <li class="nav-header">Requests</li>
            )}
            {user.permissions.includes("/exam-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/exam-help"}
                  className={
                    location.state &&
                    location.state.type === "requests" &&
                    location.state.assistance_type === "exam"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  <FaTasks className="icon" />
                  <p>Exam Help</p>
                  <span class="badge bg-primary right">{counts&&counts.exam}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/homework-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/homework-help"}
                  className={
                    location.state &&
                    location.state.type === "requests" &&
                    location.state.assistance_type === "homework"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  <GoBook className="icon" />
                  <p>H.W Help</p>
                  <span class="badge bg-primary right">{counts&&counts.homework}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/project-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/project-help"}
                  className={
                    location.state &&
                    location.state.type === "requests" &&
                    location.state.assistance_type === "project"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  <FaLaptopCode className="icon" />
                  <p>Project Help</p>
                  <span class="badge bg-primary right">{counts&&counts.project}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/thesiswriting-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/thesiswriting-help"}
                  className={
                    location.state &&
                    location.state.type === "requests" &&
                    location.state.assistance_type === "thesis"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  <AiOutlineFileSearch className="icon" />
                  <p>Thesis Writing Help</p>
                  <span class="badge bg-primary right">{counts&&counts.thesis}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/onlinetutoring-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/onlinetutoring-help"}
                  className={
                    location.state && location.state.type === "online"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  {/* <div className="flex justify-center items-center"> */}
                  <MdLiveTv className="icon" />
                  <p>Online Tutoring</p>
                  {/* </div> */}
                  <span class="badge bg-primary right">{counts&&counts.online}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/sos-help") && (
              <li className="nav-item">
                <NavLink
                  to={"/sos-help"}
                  className={
                    location.state && location.state.type === "sos"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  activeClassName="active"
                >
                  <BsPhoneVibrate className="icon" />
                  <p>S.O.S</p>
                  <span class="badge bg-primary right">{counts&&counts.sos}</span>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/classrooms") && (
              <li className="nav-item">
                <NavLink
                  to={"/classrooms"}
                  className={"nav-link"}
                  activeClassName="active"
                >
                  <SiGoogleclassroom className="icon" />
                  <p>Classrooms</p>
                </NavLink>
              </li>
            )}
            {(user.permissions.includes("/courses") || user.permissions.includes("/lectures")) && (
              <li className="nav-item">
              <DropDownLinks active={(location.pathname==="/courses" || location.pathname==="/lectures")?true:false} title={<div className="flex"><TbBooks className="icon" /><p>Courses & Lectures</p></div>}>
              <li className="nav-item">
                  <NavLink
                    to={"/courses"}
                    className={"nav-link"}
                    activeClassName="active"
                  >
                    <TbBooks className="icon" />
                    <p>Courses</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={"/lectures"}
                    className={"nav-link"}
                    activeClassName="active"
                  >
                    <TbBooks className="icon" />
                    <p>Lectures</p>
                  </NavLink>
                </li>
              </DropDownLinks>
              </li>
            )}
            {(user.permissions.includes("/onboarding-tips") ||
              user.permissions.includes("/faqs") ||
              user.permissions.includes("/social-media") ||
              user.permissions.includes("/news-updates") ||
              user.permissions.includes("/terms-conditions") ||
              user.permissions.includes("/notifications")) && (
              <li class="nav-header">In App Settings</li>
            )}
            {user.permissions.includes("/onboarding-tips") && (
              <li className="nav-item">
                <NavLink
                  to={"/onboarding-tips"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <HiOutlineLightBulb className="icon" />
                  <p>OnBoarding Tips</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/faqs") && (
              <li className="nav-item">
                <NavLink
                  to={"/faqs"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <AiOutlineQuestionCircle className="icon" />
                  <p>FAQs</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/social-media") && (
              <li className="nav-item">
                <NavLink
                  to={"/social-media"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <BsShare className="icon" />
                  <p>Social Media</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/news-updates") && (
              <li className="nav-item">
                <NavLink
                  to={"/news-updates"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <BsNewspaper className="icon" />
                  <p>News & Updates</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/terms-conditions") && (
              <li className="nav-item">
                <NavLink
                  to={"/terms-conditions"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <FaRegFileAlt className="icon" />
                  <p>Terms & Conditions</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/notifications") && (
              <li className="nav-item">
                <NavLink
                  to={"/notifications"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <FaRegBell className="icon" />
                  <p>Notifications</p>
                </NavLink>
              </li>
            )}
            {(user.permissions.includes("/subjects") ||
              user.permissions.includes("/students") ||
              user.permissions.includes("/tutors")) && (
              <li class="nav-header">Data Sheets</li>
            )}
            {user.permissions.includes("/subjects") && (
              <li className="nav-item">
                <NavLink
                  to={"/subjects"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <GoBook className="icon" />
                  <p>Subjects List</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/students") && (
              <li className="nav-item">
                <NavLink
                  to={"/students"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <FaUserGraduate className="icon" />
                  <p>Students List</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/tutors") && (
              <li className="nav-item">
                <NavLink
                  to={"/tutors"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <GiTeacher className="icon" />
                  <p>Tutors List</p>
                </NavLink>
              </li>
            )}
            {(user.permissions.includes("/users") ||
              user.permissions.includes("/roles")) && (
              <li class="nav-header">Dashboard Settings</li>
            )}
            {user.permissions.includes("/users") && (
              <li className="nav-item">
                <NavLink
                  to={"/users"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <FaUsers className="icon" />
                  <p>Dashboard Users</p>
                </NavLink>
              </li>
            )}
            {user.permissions.includes("/roles") && (
              <li className="nav-item">
                <NavLink
                  to={"/roles"}
                  className="nav-link"
                  activeClassName="active"
                >
                  <RiUserSettingsLine className="icon" />
                  <p>Roles</p>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>,
  ];
}

export default Header;
