import React, { useEffect, useState } from 'react'
import AddFAQSModal from '../../modals/AddFAQSModal'
import Axios from '../../Utils/Axios';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { SlOptionsVertical } from 'react-icons/sl';
import EditFAQSModal from '../../modals/EditFAQSModal';
import DeleteFAQModal from '../../modals/DeleteFAQModal';

function Faqs() {
    const [faqs,setFaqs]=useState([]);
    async function getData() {
        await Axios.get('/faqs').then((response)=>{
            setFaqs(response.data.faqs)
        }).catch((error)=>{});
    }
    async function create(state)
    {
        await Axios.post('faqs/add',state).then((response)=>{
            const newData = [...faqs, response.data.faq];
            setFaqs(newData);
        }).catch((error)=>{});
    }
    async function update(state,id) {
        await Axios.post(`faqs/update/${id}`,state).then((response)=>{
            getData();
        }).catch(error=>{});
    }
    async function deletee(id)
    {
        await Axios.delete(`/faqs/${id}`).then((response)=>{
            getData();
        }).catch((error)=>{});
    }
    useEffect(()=>{
        getData();
    },[]);
  return (
    <div className='m-3'>
    <div className='d-flex justify-content-between'>
    <h3>FAQs</h3>
    <div>
        <AddFAQSModal AddFunction={create} />
    </div>
    </div>
    <Row>
    {
        faqs.map((faq)=>{
            return <Col md={4}>
                <Card className='bg-secondary p-3'>
                    <Card.Title>
                        <div className='m-1 d-flex justify-content-between align-items-center'><span className='text-primary'>{faq.question}</span>
                        <Dropdown drop="start" className="bg-secondary">
                        <Dropdown.Toggle variant="secondary" className="dropdown-actions-button">
                            <SlOptionsVertical size={"25px"} className="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-secondary p-3">
                            <EditFAQSModal data={faq} id={faq.id} UpdateFunction={update} />
                            <br />
                            <DeleteFAQModal DeleteFunction={deletee} id={faq.id} />
                            {/* <EditOnBoardingTipsModal data={item} id={item.id} UpdateFunction={update} />
                            <br />
                            <DeleteOnBoardingTipsModal id={item.id} DeleteFunction={deletee} /> */}
                        </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </Card.Title>
                    <Card.Text>
                        <div style={{ height:"150px",overflow:"scroll" }}>
                        <span className='text-white' dangerouslySetInnerHTML={{ __html:faq.answer }} />
                        </div>
                    </Card.Text>
                </Card>
            </Col>
        })
    }
    </Row>
    </div>
  )
}

export default Faqs
